import { Dropdown, Icon, Text, VerticalNav } from '@innovaccer/design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { CoverselfLogoWithText } from 'src/components/icons';
import {
  POST_SUBMISSION_ROUTE,
  PRE_SUBMISSION_ROUTE,
  ROUTES,
} from 'src/router/route-names';
import { getSideLinks } from './constants';
import styles from './styles.module.scss';

export const SideNavigation = ({ inPatientModule }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const submissionSolution = pathname.includes(PRE_SUBMISSION_ROUTE)
    ? PRE_SUBMISSION_ROUTE
    : POST_SUBMISSION_ROUTE;

  const onDropdownChangeHandler = (value) => {
    switch (value) {
      case PRE_SUBMISSION_ROUTE:
        navigate(ROUTES.denialsPrediction);
        break;
      case POST_SUBMISSION_ROUTE:
        inPatientModule
          ? navigate(ROUTES.denialsManagementIN)
          : navigate(ROUTES.denialsManagement);
        break;
      default:
        break;
    }
  };

  const handleLinkChange = (value) => {
    value.link && navigate(value.link);
  };

  const dropdownOptions = [
    {
      label: 'Pre-submission Solution',
      value: PRE_SUBMISSION_ROUTE,
      selected: submissionSolution === PRE_SUBMISSION_ROUTE,
    },
    {
      label: 'Post-submission Solution',
      value: POST_SUBMISSION_ROUTE,
      selected: submissionSolution === POST_SUBMISSION_ROUTE,
    },
  ];

  return (
    <div>
      <div className="py-5" style={{ maxWidth: 'var(--spacing-9)' }}>
        <Dropdown
          align="bottom"
          className="px-5 w-auto"
          placeholder="Pre-submission Solution"
          onChange={onDropdownChangeHandler}
          options={dropdownOptions}
        />
      </div>
      <VerticalNav
        menus={getSideLinks(submissionSolution, inPatientModule)}
        expanded
        active={{ link: pathname }}
        autoCollapse={false}
        onClick={handleLinkChange}
        className={styles.fontWeight}
      />
      <div className={styles.footerLogo}>
        <Text size="small">Powered by</Text>
        <Icon size={45} className="py-2">
          <CoverselfLogoWithText />
        </Icon>
      </div>
    </div>
  );
};
