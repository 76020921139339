import { Card, Heading, Row, Label, Column } from '@innovaccer/design-system';
import {
  FormDatePicker,
  FormDropdown,
  FormInput,
  FormContext,
  HTMLForm,
} from 'src/components/form';
import { useUpdateMetadataMutation } from 'src/features/claims/store/api';
import { DATE_MASK } from 'src/services/constant';
import { useClaim } from '../hooks/useClaimDetails';
import { useLocalNavigation } from '../hooks/useLocalNavigation';
import { NavigationButtons } from './NavigationButtons';

import './styles.scss';

export const OtherDetails = () => {
  const { claim } = useClaim();
  const { goToNextStep } = useLocalNavigation();
  const [updateMetadata] = useUpdateMetadataMutation();

  const idType =
    claim?.metadata?.taxIdType === 'AIN'
      ? [
          {
            label: 'SSN',
            value: 'AIN',
          },
          {
            label: 'EIN',
            value: 'EIN',
          },
        ]
      : [
          {
            label: 'SSN',
            value: 'SSN',
          },
          {
            label: 'EIN',
            value: 'EIN',
          },
        ];

  const onSubmitHandler = async (values) => {
    try {
      await updateMetadata({
        claimId: claim.claimId,
        metadata: values.metadata,
      });
      goToNextStep();
    } catch (error) {
      // TODO: Implement error handling
      console.error(error);
    }
  };

  return (
    <FormContext
      initialValues={{
        metadata: {
          ...claim?.metadata,
          hospitalizationEndDate: null,
          hospitalizationStartDate: null,
          lmpDate: claim?.metadata?.lmpDate
            ? new Date(claim?.metadata?.lmpDate).toLocaleDateString('en-US')
            : null,
        },
      }}
      onSubmit={onSubmitHandler}
    >
      <HTMLForm>
        <Card className="my-7 mx-6 px-6 py-5">
          <div className="mb-6 d-flex align-items-center">
            <Heading className="mr-5">Other Details</Heading>
          </div>
          <Row className="mb-9">
            <Column>
              <div className="mb-4 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Current Illness, Injury or pregnancy (LMP)
                </Heading>
              </div>
              <Row>
                <Column className="mr-6 mb-6">
                  <Label withInput>Date</Label>
                  <FormDatePicker
                    name="metadata.lmpDate"
                    withInput
                    inputOptions={{
                      placeholder: 'MM/DD/YYYY',
                      icon: 'event',
                      mask: DATE_MASK,
                      minWidth: '0px',
                    }}
                  />
                </Column>
                <Column className="mr-6 mb-6">
                  <Label withInput>Qualifier</Label>
                  <FormInput
                    name="metadata.lmpQualifier"
                    type="text"
                    placeholder="Qualifier"
                    minWidth="0px"
                  />
                </Column>
              </Row>
            </Column>
            <Column>
              <div className="mb-4 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Hospitalization Dates
                </Heading>
              </div>
              <Row>
                <Column className="mr-6 mb-6">
                  <Label withInput>From</Label>
                  <FormDatePicker
                    name="metadata.hospitalizationStartDate"
                    withInput
                    inputOptions={{
                      placeholder: 'MM/DD/YYYY',
                      icon: 'event',
                      mask: DATE_MASK,
                      minWidth: '0px',
                      name: 'metadata.hospitalizationStartDate',
                    }}
                  />
                </Column>
                <Column className="mr-6 mb-6">
                  <Label withInput>To</Label>
                  <FormDatePicker
                    name="metadata.hospitalizationEndDate"
                    withInput
                    inputOptions={{
                      placeholder: 'MM/DD/YYYY',
                      icon: 'event',
                      mask: DATE_MASK,
                      minWidth: '0px',
                      name: 'metadata.hospitalizationEndDate',
                    }}
                  />
                </Column>
              </Row>
            </Column>
            <Column className="mr-6 mb-6">
              <div className="mb-4 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Additional Claim Information
                </Heading>
              </div>
              <Label withInput>*</Label>
              <FormInput
                name="metadata.additionalInformation"
                type="text"
                placeholder="Something"
              />
            </Column>
          </Row>
          <Row className="mb-6">
            <Column>
              <div className="mb-4 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Outside Lab
                </Heading>
              </div>
              <Row>
                <Column className="mr-6 mb-6">
                  <Label withInput>*</Label>
                  <FormDropdown
                    name="metadata.isOutsideLab"
                    placeholder=""
                    options={[
                      { label: 'True', value: 'true' },
                      { label: 'False', value: 'false' },
                    ]}
                  />
                </Column>
                <Column className="mr-6 mb-6">
                  <Label withInput>Charges</Label>
                  <FormInput
                    name="metadata.outsideLabCharges"
                    type="text"
                    placeholder="Charges"
                    inlineLabel="$"
                    minWidth="0px"
                  />
                </Column>
              </Row>
            </Column>
            <Column>
              <div className="mb-4 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Medicaid Resubmission Code
                </Heading>
              </div>
              <Row>
                <div className="mr-6 mb-6 other-detail-resubmission">
                  <Label withInput>Resubmission Code</Label>
                  <FormInput
                    name="metadata.resubmissionCode"
                    type="text"
                    placeholder=""
                    minWidth="0px"
                  />
                </div>
                <Column className="mr-6 mb-6">
                  <Label withInput>Original ICN No.</Label>
                  <FormInput
                    name="metadata.originalICN"
                    type="text"
                    placeholder=""
                    minWidth="0px"
                  />
                </Column>
              </Row>
            </Column>
            <Column>
              <div className="mb-4 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Prior Authorization No.
                </Heading>
              </div>
              <Column className="mr-6 mb-6">
                <Label withInput>*</Label>
                <FormInput
                  name="metadata.priorAuthorization"
                  type="text"
                  placeholder="Something"
                  maxwidth="0px"
                />
              </Column>
            </Column>
            <Column>
              <div className="mb-4 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Federal Tax ID
                </Heading>
              </div>
              <Row>
                <Column className="mr-6 mb-6">
                  <Label withInput>Tax ID Number</Label>
                  <FormInput
                    name="metadata.taxId"
                    type="text"
                    placeholder="Tax ID Number"
                    minWidth="0px"
                  />
                </Column>
                <Column className="mr-6 mb-6">
                  <Label withInput>ID Type</Label>
                  <FormDropdown
                    name="metadata.taxIdType"
                    placeholder="ID Type"
                    options={idType}
                    maxwidth="0px"
                  />
                </Column>
              </Row>
            </Column>
          </Row>
          <Row className="mb-9">
            <div className="mr-6 mb-6">
              <Label withInput>Insured’s Policy group/FECA number</Label>
              <FormInput
                name="metadata.fecaNumber"
                type="text"
                placeholder="Insured’s Policy group/FECA number"
                minWidth="280px"
              />
            </div>
            <div className="ml-6 mb-6">
              <Label withInput>Insurance Plan Name</Label>
              <FormInput
                name="metadata.planName"
                type="text"
                placeholder="Insurance Plan Name"
                minWidth="280px"
              />
            </div>
          </Row>
          {/* 
            The form bellow duplicates fields from Provider Details.
            This will stay commented until the data issue is resolved.
          */}

          <div className="mb-4 d-flex align-items-center">
            <Heading className="mr-5 mb-5" size="s">
              Service Facility Location
            </Heading>
          </div>
          <Row className="mb-5">
            <Column className="mr-6 mb-6">
              <Label withInput>Provider Name</Label>
              <FormInput
                name="provider.performing.name"
                type="text"
                placeholder="Provider Name"
              />
            </Column>
            <Column className="mr-6 mb-6">
              <Label withInput>NPI</Label>
              <FormInput
                name="provider.performing.npi"
                type="text"
                placeholder="NPI"
              />
            </Column>
          </Row>
          <Row>
            <Column className="mr-6 mb-6">
              <Label withInput>Street</Label>
              <FormInput
                name="provider.addressLine"
                type="text"
                placeholder="Street"
              />
            </Column>
            <div className="mr-6 mb-6 provider-detail-city">
              <Label withInput>City</Label>
              <FormInput
                name="provider.city"
                type="text"
                placeholder="City"
                minWidth="0px"
              />
            </div>
            <div className="mr-6 mb-6 provider-detail-state">
              <Label withInput>State</Label>
              <FormDropdown
                name="provider.state"
                placeholder="State"
                options={[]}
              />
            </div>
            <div className="mr-6 mb-6 provider-detail-zip">
              <Label withInput>ZIP Code</Label>
              <FormInput
                name="provider.zipcode"
                type="text"
                placeholder="ZIP Code"
                minWidth="0px"
              />
            </div>
          </Row>
        </Card>
        <NavigationButtons showPreviousBtn />
      </HTMLForm>
    </FormContext>
  );
};
