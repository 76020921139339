import { Button, Text } from '@innovaccer/design-system';
import { useState } from 'react';
import styles from './chart-tabs.module.scss';

export const ChartTabs = ({ title, tabs }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].name);
  const tab = tabs.find((t) => t.name === selectedTab);

  return (
    <>
      <div className={styles.tabsRow}>
        <Text className="mr-5" weight="strong">
          {title}
        </Text>
        <div className={styles.tab}>
          {tabs.map((tab) => (
            <Button
              selected={selectedTab === tab.name}
              onClick={() => setSelectedTab(tab.name)}
            >
              {tab.label}
            </Button>
          ))}
        </div>
      </div>
      {tab && <tab.Component />}
    </>
  );
};
