import React, { Fragment } from 'react';
import { useTable, useExpanded } from 'react-table';
import styles from '../styles.module.scss';

export const ClaimTable = ({
  columns: userColumns,
  data,
  renderRowSubComponent,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns: userColumns,
      data,
      autoResetExpanded: false,
    },
    useExpanded
  );

  return (
    <table {...getTableProps()} className={styles.tableBorderTop}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className={styles.tableHeader}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Fragment {...row.getRowProps()}>
              <tr className="tableRow">
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
              {row.isExpanded ? (
                <tr>
                  <td
                    colSpan={visibleColumns.length}
                    className={styles.tableRow}
                  >
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
