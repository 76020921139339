/* eslint no-underscore-dangle: 0 */
import React from 'react';
import { Button, Card, Heading, Row } from '@innovaccer/design-system';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import {
  useUpdateClaimItemsMutation,
  useDeleteEditMutation,
} from 'src/features/claims/store/api';
import classNames from 'classnames';
import { Table } from '../table';
import { EditDetails } from './EditDetails';
import styles from '../styles.module.scss';

export const BillDetails = ({
  correction,
  claim,
  setCorrection,
  claimId,
  updateItem,
  setDisable,
  disabled,
}) => {
  const [nestedData, setNestedData] = React.useState(claim?.items);
  const [dirtyRows, setDirtyRows] = React.useState([]);
  const [updateClaimItems] = useUpdateClaimItemsMutation();
  const [deleteEdit] = useDeleteEditMutation();

  const handleOnSubmit = async () => {
    await updateClaimItems({
      id: claim?._id,
      items: nestedData,
    });
    const claimEditsId = claim?.edits.filter((items) =>
      dirtyRows.includes(items.claimLineId)
    );

    const handleDelete = async (values) => {
      try {
        await deleteEdit({
          id: values,
        });
      } catch (error) {
        // TODO: Implement error handling
        console.error(error);
      }
    };

    claimEditsId.forEach((item) => {
      handleDelete(item._id);
    });
    setDisable(true);
  };

  const handleIsDirtyState = (claimLine) => {
    if (!dirtyRows.includes(claimLine.claimLineId)) {
      setDirtyRows(dirtyRows.concat(claimLine.claimLineId));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <Heading className="w-100">Bill Details</Heading>
        <Row className="justify-content-end">
          {!disabled && (
            <Button className="mr-6" onClick={() => handleOnSubmit()}>
              Save
            </Button>
          )}
          <Button
            appearance="primary"
            type="button"
            onClick={() => setCorrection(!correction)}
          >
            Correction
          </Button>
        </Row>
      </div>
      <Card className={classNames('mt-5', styles.billDetailsCard)}>
        <Allotment vertical>
          <Allotment.Pane>
            <Table
              claimId={claimId}
              updateItem={updateItem}
              billItem={claim?.items}
              correction={correction}
              disabled={disabled}
              setDisable={setDisable}
              data={claim}
              documents={claim?.documents}
              comments={claim?.comments}
              billItems={claim?.items}
              nestedData={nestedData}
              setNestedData={setNestedData}
              handleIsDirtyState={handleIsDirtyState}
              handleOnSubmit={handleOnSubmit}
            />
          </Allotment.Pane>
          <Allotment.Pane preferredSize={45} minSize={45}>
            <EditDetails correction={correction} height="55vh" />
          </Allotment.Pane>
        </Allotment>
      </Card>
    </>
  );
};
