export const BASE_URL = '/';
export const BASE_TIMEOUT = 6000; // 6s
export const DATE_MASK = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
