import { Row, Button } from '@innovaccer/design-system';
import { useLocalNavigation } from '../hooks/useLocalNavigation';

export const NavigationButtons = ({
  showPreviousBtn,
  onPreviousClick,
  onNextClick,
}) => {
  const { goToPrevStep } = useLocalNavigation();

  const onPreviousButtonClickHandler = () => {
    onPreviousClick && onPreviousClick();
    goToPrevStep();
  };

  return (
    <Row className="d-flex align-items-center justify-content-end mx-11 mb-7">
      {showPreviousBtn && (
        <Button
          className="mr-4"
          size="regular"
          onClick={onPreviousButtonClickHandler}
        >
          Previous
        </Button>
      )}
      <Button
        type="submit"
        appearance="primary"
        size="regular"
        icon="arrow_forward"
        iconAlign="right"
        onClick={onNextClick}
      >
        Next
      </Button>
    </Row>
  );
};
