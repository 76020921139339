import { Card, Checkbox } from '@innovaccer/design-system';
import styles from './chart-container.module.scss';

export const ChartContainer = ({
  title,
  subtitle = null,
  children,
  hideLegend = false,
  legend,
  showTypeSelection = false,
  hideSeparator = false,
  headerActions = null,
  typeOfEdits,
  setTypeOfEdits,
}) => (
  <Card className={styles.container}>
    <div className={styles.left} grow={1} direction="column">
      <div className={styles.header}>
        <div>
          <p className={styles.title}>{title}</p>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        {headerActions}
      </div>
      <div className={styles.canvas}>{children}</div>
    </div>
    {!hideLegend && (
      <div
        className={`${styles.side} ${hideSeparator ? '' : styles.separator}`}
      >
        {legend && <div className={styles.legend}>{legend}</div>}
        {legend && showTypeSelection && (
          <div className={styles.legendSeparator} />
        )}
        {showTypeSelection && (
          <div>
            <Checkbox
              defaultChecked
              label="Count"
              name="selection"
              size="tiny"
              value="Count"
              className="mb-4"
              checked={typeOfEdits === 'Count'}
              onChange={(e) => setTypeOfEdits(e.target.value)}
            />
            <Checkbox
              label="Amount"
              name="selection"
              size="tiny"
              value="Amount"
              checked={typeOfEdits === 'Amount'}
              onChange={(e) => setTypeOfEdits(e.target.value)}
            />
          </div>
        )}
        <div className={styles.select}>
          <Checkbox label="Select" />
        </div>
      </div>
    )}
  </Card>
);
