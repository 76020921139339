import { Card, Text } from '@innovaccer/design-system';
import { SummaryItem } from '../summary-item';

const summarySet1 = [
  { label: 'Total', subValue: '100.5K', value: '$10.96M' },
  { label: 'Clean Claim', subValue: '82.4K (82%)', value: '$8.23M' },
  { label: 'Total Edits', subValue: '18.1K (18%)', value: '$2.73M' },
];
const summarySet2 = [
  { label: 'Pre-Bill edits', subValue: '18.1K (18%)', value: '$2.73M' },
  { label: 'Missed Charges', subValue: '0 (0%)', value: '$0' },
];
const summarySet3 = [
  { label: 'Denials', subValue: '0 (0%)', value: '$0' },
  { label: 'Under-Payments', subValue: '0 (0%)', value: '$0' },
];
const summarySet4 = [
  { label: 'Potential Savings', value: '$2.73M' },
  { label: 'Actual Savings', value: '$2.73M' },
];

export const DashboardSummary = () => (
  <Card className="m-6 d-flex  py-5">
    <div className="d-flex flex-column flex-fill border-right">
      <div className="d-flex mt-6">
        {summarySet1.map((item) => (
          <SummaryItem key={item.label} {...item} />
        ))}
      </div>
    </div>
    <div className="d-flex flex-column flex-fill align-items-center border-right">
      <Text appearance="subtle" size="small">
        Pre-Submission
      </Text>
      <div className="d-flex w-100">
        {summarySet2.map((item) => (
          <SummaryItem key={item.label} {...item} />
        ))}
      </div>
    </div>
    <div className="d-flex flex-column flex-fill align-items-center border-right">
      <Text appearance="subtle" size="small">
        Post-Submission
      </Text>
      <div className="d-flex w-100">
        {summarySet3.map((item) => (
          <SummaryItem key={item.label} {...item} />
        ))}
      </div>
    </div>
    <div className="d-flex flex-column flex-fill">
      <div className="d-flex mt-6">
        {summarySet4.map((item) => (
          <SummaryItem key={item.label} {...item} />
        ))}
      </div>
    </div>
  </Card>
);
