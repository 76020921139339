import {
  Badge,
  Card,
  Column,
  Heading,
  Label,
  Row,
} from '@innovaccer/design-system';
import React from 'react';
import {
  FormDatePicker,
  FormDropdown,
  FormInput,
  FormContext,
  HTMLForm,
} from 'src/components/form';
import { useUpdatePatientMutation } from 'src/features/claims/store/api';
import { DATE_MASK } from 'src/services/constant';
import { useClaim } from '../hooks/useClaimDetails';
import { useLocalNavigation } from '../hooks/useLocalNavigation';
import { NavigationButtons } from './NavigationButtons';
import './styles.scss';

const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Other', value: 'O' },
];
const patientRelationship = [{ label: 'Spouse', value: 'spouse' }];

export const PatientDetails = () => {
  const { claim } = useClaim();
  const [updatePatient] = useUpdatePatientMutation();
  const { goToNextStep } = useLocalNavigation();

  const onSubmitHandler = async (values) => {
    try {
      await updatePatient({ claimId: claim.claimId, patient: values });
      goToNextStep();
    } catch (error) {
      // TODO: Implement error handling
      console.error(error);
    }
  };

  return (
    <FormContext
      initialValues={{
        ...claim?.patient,
        dateOfBirth: new Date(claim?.patient?.dateOfBirth).toLocaleDateString(
          'en-US'
        ),
      }}
      onSubmit={onSubmitHandler}
    >
      <HTMLForm>
        <Card className="my-7 mx-11 px-6 py-5">
          <div className="mb-6 d-flex align-items-center">
            <Heading className="mr-5">Patient details</Heading>
            <Badge appearance="accent2" subtle>
              OUT-PATIENT
            </Badge>
          </div>
          <Row className="mb-7">
            <Column className="mr-6 mb-6">
              <Label withInput>Patient’s First Name</Label>
              <FormInput
                name="firstName"
                type="text"
                placeholder="E.g. Doe, Smith, etc."
              />
            </Column>
            <Column className="mr-6 mb-6">
              <Label withInput>Patient’s Last Name</Label>
              <FormInput name="lastName" type="text" />
            </Column>
            <div className="mr-6 mb-6 patient-detail-dob">
              <Label withInput>Date of Birth</Label>
              <FormDatePicker
                name="dateOfBirth"
                withInput
                inputOptions={{
                  placeholder: 'MM/DD/YYYY',
                  icon: 'event',
                  mask: DATE_MASK,
                  minWidth: '0px',
                  name: 'dateOfBirth',
                }}
              />
            </div>
            <div className="mr-6 mb-6 patient-detail-gender">
              <Label withInput>Sex</Label>
              <FormDropdown
                name="gender"
                placeholder="Gender"
                options={genderOptions}
              />
            </div>
            <div className="mr-6 mb-6">
              <Label withInput>Insures’s ID</Label>
              <FormInput
                name="issuerId"
                type="text"
                minWidth={0}
                placeholder="Insures’s ID"
              />
            </div>
          </Row>
          <div className="mb-4 d-flex align-items-center">
            <Heading className="mr-5" size="s">
              Patient Address
            </Heading>
          </div>
          <Row>
            <Column className="mr-6 mb-6">
              <Label withInput>Street</Label>
              <FormInput name="addressLine" type="text" />
            </Column>
            <Column className="mr-6 mb-6">
              <Label withInput>City</Label>
              <FormInput name="city" type="text" />
            </Column>
            <div className="mr-6 mb-6 patient-detail-state">
              <Label withInput>State</Label>
              <FormDropdown name="state" placeholder="State" options={[]} />
            </div>
            <Column className="mr-6 mb-6">
              <Label withInput>ZIP Code</Label>
              <FormInput name="zipcode" type="text" />
            </Column>
          </Row>
          <Row className="mt-9">
            <Column className="mr-6 mb-6">
              <Label withInput>Patient relationship to the insured</Label>
              <FormDropdown
                name="patientRelationship"
                placeholder="Patient relationship to the insure"
                options={patientRelationship}
              />
            </Column>
            <Column className="mr-6 mb-6">
              <Label withInput>Patient Condition Related to</Label>
              <FormDropdown
                name="patientCondition"
                placeholder="Patient Condition Related to"
                options={patientRelationship}
              />
            </Column>
            <Column className="mr-6 mb-6">
              <Label withInput>Place (State)</Label>
              <FormDropdown
                name="place"
                placeholder="Place (State)"
                options={patientRelationship}
              />
            </Column>
          </Row>
        </Card>
        <NavigationButtons />
      </HTMLForm>
    </FormContext>
  );
};
