import * as Realm from 'realm-web';

const app = new Realm.App(process.env.REACT_APP_REALM_APP_ID);

/**
 * Get a access token from Realm SDK using a Auth Provider's JWT Token
 *
 * @param {string} jwtToken - JWT token provided by Auth Service
 * @returns {Promise<string>} An Access token
 */
export const getValidRealmAccessToken = async (jwtToken) => {
  if (!app.currentUser) {
    await app.logIn(Realm.Credentials.jwt(jwtToken));
  } else {
    await app.currentUser.refreshCustomData();
  }

  return app.currentUser.accessToken;
};
