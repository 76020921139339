import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useRef, useState } from 'react';
import { Button, Text } from '@innovaccer/design-system';
import styles from './pdf-view.module.scss';

const FALLBACK_WIDTH = 300;
const HORIZONTAL_OFFSET = 86;

export const PDFView = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(FALLBACK_WIDTH);
  const container = useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setWidth(container.current.offsetWidth - HORIZONTAL_OFFSET);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const loadingView = () => <Text className={styles.loading}>Loading..</Text>;

  return (
    <div className="position-relative" ref={container}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={loadingView}
      >
        <Page
          className={styles.page}
          pageNumber={pageNumber}
          width={width}
          renderTextLayer={false}
        />
      </Document>
      <Button
        type="button"
        appearance="basic"
        icon="keyboard_arrow_left"
        className={`${styles.previous} position-absolute`}
        disabled={pageNumber <= 1}
        onClick={previousPage}
      />
      <Button
        type="button"
        appearance="basic"
        icon="keyboard_arrow_right"
        className={`${styles.next} position-absolute`}
        disabled={pageNumber >= numPages}
        onClick={nextPage}
      />
      <Text className={`${styles.pagination} mt-4`} size="small">
        Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
      </Text>
    </div>
  );
};
