import {
  Text,
  Icon,
  Input,
  Dropdown,
  DateRangePicker,
} from '@innovaccer/design-system';
import React from 'react';
import { AdvancedComponent } from './components/AdvancedComponent';
import { TableHeaderCard } from './components/TableHeaderCard';

export const TableHeader = () => {
  const [hideAdvance, setHideAdvance] = React.useState(true);
  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-4 align-items-center">
        <div className="d-flex flex-row justify-content-between px-4 py-2 align-items-center">
          <DateRangePicker
            inputFormat="mm-dd-yyyy"
            monthsInView={2}
            onRangeChange={() => null}
            outputFormat="mm-dd-yyyy"
            singleInput
            withInput
            inputOptions={{
              label: null,
              minWidth: 248,
            }}
          >
            <React.Fragment key=".0" />
          </DateRangePicker>
        </div>
        <div className="mx-2">
          <Input
            icon="search"
            name="input"
            placeholder="Search Claim ID, M"
            minWidth={0}
          />
        </div>
        <div className="mx-2">
          <Dropdown inlineLabel="Specialty" options={[]} minWidth={0} />
        </div>
        <div className="mx-2">
          <Dropdown inlineLabel="Payor" options={[]} minWidth={0} />
        </div>
        <div className="mx-2">
          <Dropdown inlineLabel="Team" options={[]} minWidth={0} />
        </div>
        <div className="mx-2">
          <Dropdown inlineLabel="Provider" options={[]} minWidth={0} />
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center px-4 table-header-date">
          <Text>Advanced</Text>
          <Icon
            name={hideAdvance ? 'expand_more' : ' expand_less'}
            className="cursor-pointer"
            onClick={() => setHideAdvance(!hideAdvance)}
          />
        </div>
      </div>
      {!hideAdvance && <AdvancedComponent />}

      <TableHeaderCard />
    </>
  );
};
