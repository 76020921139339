import { MissedChargesIN, MissedChargesINDetail } from 'src/pages';
import { ROUTES } from '../route-names';

export const MISSED_CHARGES_IN = [
  {
    path: ROUTES.missedChargesIN,
    element: MissedChargesIN,
  },
  {
    path: `${ROUTES.missedChargesIN}/:id/*`,
    element: MissedChargesINDetail,
  },
];
