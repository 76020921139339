import {
  Breadcrumbs,
  Dropdown,
  HorizontalNav,
  MetaList,
  PageHeader,
  StatusHint,
} from '@innovaccer/design-system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useClaim } from '../hooks/useClaimDetails';
import { useLocalNavigation } from '../hooks/useLocalNavigation';
import {
  BILL_ITEMS_PATH,
  INSURANCE_DETAILS_PATH,
  OTHER_DETAILS_PATH,
  PATIENT_DETAILS_PATH,
  PROVIDER_DETAILS_PATH,
} from '../constants';

const options = [
  {
    label: 'Option 1',
    value: 'Option 1',
  },
  {
    label: 'Option 2',
    value: 'Option 2',
  },
  {
    label: 'Option 3',
    value: 'Option 3',
  },
];

export const Header = () => {
  const navigate = useNavigate();
  const { currentSubRoute, changeStep } = useLocalNavigation();
  const {
    editsCount: {
      billItems: billItemsEditsCount,
      patient: patientEditsCount,
      provider: providerEditsCount,
      otherDetails: otherEditsCount,
    },
  } = useClaim();

  const activeTab = { name: currentSubRoute };

  const claimDetailSteps = [
    {
      name: INSURANCE_DETAILS_PATH,
      label: 'Insurance Detail',
    },
    {
      name: PATIENT_DETAILS_PATH,
      label: 'Patient Detail',
      count: patientEditsCount,
    },
    {
      name: PROVIDER_DETAILS_PATH,
      label: 'Provider Detail',
      count: providerEditsCount,
    },
    {
      name: OTHER_DETAILS_PATH,
      label: 'Other Details',
      count: otherEditsCount,
    },
    {
      name: BILL_ITEMS_PATH,
      label: 'Bill Items',
      count: billItemsEditsCount,
    },
  ];

  const onChangeStepHandler = ({ name }) => {
    changeStep(name);
  };

  const breadcrumbs = (
    <Breadcrumbs
      list={[
        {
          label: 'Worklist',
          link: '/claims',
        },
      ]}
      onClick={(link) => navigate(link)}
    />
  );
  const meta = <MetaList list={[{ label: '1500 CMS' }]} seperator />;
  const status = <StatusHint appearance="info">Under review</StatusHint>;
  const actions = (
    <div className="d-flex justify-content-end align-items-center">
      <div className="mr-4">
        <Dropdown menu icon="more_horiz" options={options} />
      </div>
    </div>
  );
  const navigation = (
    <HorizontalNav
      active={activeTab}
      menus={claimDetailSteps}
      onClick={onChangeStepHandler}
      className="justify-content-center"
    />
  );

  return (
    <div className="page-header">
      <PageHeader
        title="Review Claim Details"
        separator
        navigationPosition="center"
        navigation={navigation}
        actions={actions}
        breadcrumbs={breadcrumbs}
        meta={meta}
        status={status}
      />
    </div>
  );
};
