import { Dashboard } from 'src/pages';
import { ROUTES } from '../route-names';

export const DASHBOARD_ROUTES = [
  {
    path: ROUTES.dashboard,
    element: Dashboard,
  },
  {
    path: ROUTES.operation,
    element: Dashboard,
  },
  {
    path: ROUTES.revenueIntelligence,
    element: Dashboard,
  },
];
