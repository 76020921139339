import { BillAreaChart, BySpecialtyChart, ClaimOutcomeChart } from '../charts';
import { ClaimOutcomeChart as PostSubmissionClaimChart } from './components/claim-outcome-chart/ClaimOutcome';
import styles from './operation.module.scss';

export const OperationDashboard = ({ preSubmission }) => (
  <div className={styles.container}>
    {preSubmission ? <ClaimOutcomeChart /> : <PostSubmissionClaimChart />}
    <BillAreaChart />
    <BySpecialtyChart />
  </div>
);
