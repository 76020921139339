import { ResponsivePie } from '@nivo/pie';
import { ChartContainer } from '../../chart-container';
import { ChartFilters } from '../../chart-filters';
import { Legend } from '../../legend';
import { data } from './data';

export const ClaimOutcomeChart = () => (
  <ChartContainer
    title="Claim Outcome"
    legend={<Legend items={data} />}
    headerActions={<ChartFilters />}
  >
    <ResponsivePie
      data={data}
      value="value"
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      innerRadius={0.5}
      colors={{ datum: 'data.color' }}
      enableArcLabels={false}
      arcLinkLabel={(item) => item.formattedValue}
      arcLinkLabelsColor="#706969"
      arcLinkLabelsStraightLength={32}
      // valueFormat=" >-.2%"
    />
  </ChartContainer>
);
