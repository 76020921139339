/* eslint no-underscore-dangle: 0 */
import React from 'react';
import {
  Heading,
  Icon,
  Card,
  Avatar,
  Text,
  Divider,
  FileList,
  Button,
} from '@innovaccer/design-system';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { EditDetails } from './EditDetails';
import styles from '../styles.module.scss';

const fileList = [
  {
    file: {
      name: 'IMG_55459450.jpeg',
      size: '3 MB',
      type: 'image',
    },
    status: 'completed',
    id: 1,
  },
];

export const Activities = ({ setActivities, correction }) => {
  const ActivityCard = ({
    message,
    time,
    firstName,
    lastName,
    showFileList = false,
  }) => (
    <>
      <div className="d-flex flex-row align-items-center py-5 pl-6">
        <Avatar className="mr-5" firstName={firstName} lastName={lastName} />
        <div className="d-flex flex-column">
          <Text weight="medium" className="mb-2">
            {message}
          </Text>
          <Text appearance="subtle">{time}</Text>
        </div>
      </div>
      {showFileList && (
        <FileList
          fileList={fileList}
          className={styles.activitiesFileList}
          actionRenderer={(fileItem) => {
            if (fileItem.id) {
              return (
                <Button
                  appearance="transparent"
                  icon="close"
                  size="regular"
                  onClick={() => null}
                  className="cursor-pointer ml-2"
                />
              );
            }
            return (
              <Button
                appearance="transparent"
                icon="close"
                size="regular"
                onClick={() => null}
                className="cursor-pointer"
              />
            );
          }}
        />
      )}
    </>
  );
  return (
    <div className="w-100">
      <div className="d-flex align-items-center mx-6">
        <Icon
          name="arrow_back_ios"
          className="cursor-pointer mr-4"
          onClick={() => setActivities(false)}
        />
        <Heading>Activities</Heading>
      </div>
      <Card
        className="mt-5"
        style={{ height: '95vh', backgroundColor: '#F4F4F4' }}
      >
        <Allotment vertical>
          <Allotment.Pane minSize={150}>
            <div className="d-flex flex-column align-items-center py-10 h-auto">
              <Text
                className="align-self-start ml-9 mt-4 mb-5"
                appearance="subtle"
                weight="medium"
              >
                30 Sep, 2020.
              </Text>
              <Card className={styles.activitiesCard}>
                <ActivityCard
                  message={`Nick deployed the rule - 'pushed to production enviroment'`}
                  time="12:30 PM"
                  firstName="Nick"
                />
                <Divider />
                <ActivityCard
                  message={`Eric updated the rule - 'assigned a new commercial plan'`}
                  time="07: 26 AM"
                  firstName="Eric"
                />
                <Divider />
                <ActivityCard
                  message={`Raj M's Merge branch 'feature/alerts-table-improvement' into 'master'`}
                  time="08: 26 AM"
                  firstName="Raj"
                  lastName="M"
                  showFileList
                />
                <Divider />
                <ActivityCard
                  message={`Raj M's Merge branch 'feature/alerts-table-improvement' into 'master'`}
                  time="08: 26 AM"
                  firstName="Raj"
                  lastName="M"
                  showFileList
                />
              </Card>
            </div>
          </Allotment.Pane>
          <Allotment.Pane preferredSize={45} minSize={45}>
            <EditDetails height="68vh" correction={correction} />
          </Allotment.Pane>
        </Allotment>
      </Card>
    </div>
  );
};
