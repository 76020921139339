import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ChartContainer } from '../../chart-container';
import { ChartFilters } from '../../chart-filters';
import { VerticalAxisTick } from '../verticle-tick';
import { CountData, AmountData } from './data';

const color = ['#FEAC5F', '#FFE2C8'];

export const TopEditsChart = () => {
  const [typeOfEdits, setTypeOfEdits] = React.useState('Count');
  return (
    <ChartContainer
      title="Top Edits"
      headerActions={<ChartFilters />}
      showTypeSelection
      typeOfEdits={typeOfEdits}
      setTypeOfEdits={setTypeOfEdits}
    >
      <ResponsiveBar
        data={typeOfEdits === 'Count' ? CountData : AmountData}
        indexBy="label"
        keys={['value']}
        layout="horizontal"
        groupMode="grouped"
        enableGridY={false}
        margin={{ top: 20, right: 10, bottom: 50, left: 220 }}
        padding={0.2}
        colors={color}
        axisBottom={{
          tickSize: 0,
        }}
        axisLeft={{
          tickSize: 0,
          renderTick: VerticalAxisTick,
        }}
        enableLabel={false}
      />
    </ChartContainer>
  );
};
