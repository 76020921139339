import React from 'react';
import {
  Dropdown,
  Row,
  Text,
  Icon,
  Button,
  DateRangePicker,
} from '@innovaccer/design-system';
import classNames from 'classnames';
import styles from '../styles.module.scss';

export const TableFilter = () => {
  const DropdownComponent = (props) => (
    <div
      className={classNames(
        'ml-6 d-flex align-items-center',
        styles.tableFilterDropdown
      )}
    >
      <Dropdown {...props} placeholder="Select" />
    </div>
  );
  return (
    <div className="d-flex w-100">
      <div
        className={classNames(
          'ml-4 d-flex align-items-center',
          styles.tableFilter
        )}
      >
        <Text
          className={classNames(
            'ml-4 pr-4 d-flex align-items-center',
            styles.tableFilterCPT
          )}
        >
          CPT
        </Text>
        <Row>
          <Text className="mx-4">12500</Text>
          <Icon name="arrow_right_alt" />
          <Text className="mx-4">13000</Text>
        </Row>
      </div>
      <DropdownComponent inlineLabel="Specialty" options={[]} />
      <div className="ml-6 d-flex align-items-center table-filter">
        <DateRangePicker
          inputFormat="mm-dd-yyyy"
          monthsInView={2}
          onRangeChange={() => null}
          outputFormat="mm-dd-yyyy"
          singleInput
          withInput
          inputOptions={{
            label: null,
            minWidth: 248,
          }}
        >
          <React.Fragment key=".0" />
        </DateRangePicker>
      </div>
      <DropdownComponent inlineLabel="Provider" options={[]} />
      <DropdownComponent inlineLabel="Payor" options={[]} />
      <Button
        className="ml-6 d-flex align-items-center px-7"
        icon="cached"
        largeIcon
      />
    </div>
  );
};
