export const VerticalAxisTick = ({ value, x, y, rotate, textAnchor }) => {
  const MAX_CHARS = 100;
  const truncatedValue =
    value.length > MAX_CHARS
      ? `${value.substring(0, MAX_CHARS).trimEnd()}...`
      : value;

  return (
    <g transform={`translate(${x}, ${y}) rotate(${rotate})`} width={160}>
      <text
        textAnchor={textAnchor}
        fontSize="11px"
        transform="translate(-5,0) rotate(0)"
        dominantBaseline="central"
      >
        {truncatedValue}
      </text>
    </g>
  );
};
