import { Button, Dropdown } from '@innovaccer/design-system';
import React from 'react';
import './action-cell.scss';

export const ActionCell = ({ options, onClick }) => {
  const handleChange = (e) => onClick(e);

  return (
    <Dropdown
      className="custom-dropdown"
      triggerOptions={{
        customTrigger: () => (
          <Button icon="more_horiz" appearance="transparent" type="button" />
        ),
      }}
      popoverOptions={{
        appendToBody: false,
      }}
      menu
      align="left"
      options={options}
      onChange={handleChange}
    />
  );
};
