import {
  ActionsTable,
  TopEditsByCodeChart,
  TopEditsBySectionChart,
  TopEditsChart,
} from '../charts';

export const DenialPrediction = () => (
  <>
    <TopEditsChart />
    <TopEditsBySectionChart />
    <TopEditsByCodeChart />
    <ActionsTable />
  </>
);
