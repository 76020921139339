import { Dropdown } from '@innovaccer/design-system';
import {
  departmentOptions,
  providerOptions,
  teamOptions,
} from './filter-options';
import './chart-filters.scss';

export const ChartFilters = () => (
  <div className="ChartFilters">
    <Dropdown disabled className="mr-5" options={departmentOptions} />
    <Dropdown
      placeholder="Team"
      optionType="WITH_META"
      className="mr-5"
      options={teamOptions}
    />
    <Dropdown placeholder="Provider" options={providerOptions} />
  </div>
);
