export const CountData = [
  {
    label: '99213',
    value: 1322,
  },
  {
    label: '99214',
    value: 1114,
  },
  {
    label: '99391',
    value: 485,
  },
  {
    label: '99395',
    value: 438,
  },
  {
    label: '99392',
    value: 416,
  },
  {
    label: '99204',
    value: 338,
  },
  {
    label: '99396',
    value: 322,
  },
  {
    label: '99203',
    value: 300,
  },
  {
    label: '99232',
    value: 295,
  },
  {
    label: '90460',
    value: 284,
  },
].reverse();

export const AmountData = [
  {
    label: 'J9299',
    value: 353894,
  },
  {
    label: '59400',
    value: 170072.13,
  },
  {
    label: 'J9312',
    value: 125129.48,
  },
  {
    label: '99214',
    value: 116884.16,
  },
  {
    label: '99213',
    value: 95283.9,
  },
  {
    label: 'J9271',
    value: 88922.2,
  },
  {
    label: 'J1569',
    value: 67382.82,
  },
  {
    label: 'J1930',
    value: 63138.06,
  },
  {
    label: 'J2350',
    value: 58425.51,
  },
  {
    label: '99204',
    value: 50862.74,
  },
].reverse();
