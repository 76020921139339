import { useCallback } from 'react';
import { ActionCell, MetaListCell } from 'src/pages/common';
import { getObjectComparator } from 'src/services/utils';
import { PlaceholderParagraph } from '@innovaccer/design-system';
import { format } from 'date-fns';
import { ServiceCodePopover } from './cells';

export const tableschema = ({ handleEditOpen, correction }) => [
  {
    name: 'claimId',
    displayName: 'Claim No.#',
  },
  {
    // name: 'claimId',
    displayName: 'Claim line#',
  },
  {
    // name: 'serviceStartDate',
    displayName: 'Service start date',
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      // FIXME: BAD CODE - Remove this after schema integration

      if (value) return format(new Date(value), 'MM/dd/yyyy');

      return null;
    },
  },
  {
    // name: 'serviceEndDate',
    displayName: 'Service end date',
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      // FIXME: BAD CODE - Remove this after schema integration

      if (value) return format(new Date(value), 'MM/dd/yyyy');

      return null;
    },
  },
  {
    // name: 'cptCode',
    displayName: 'CPT/HCPCS Codes',
    width: 200,
    sorting: true,
    comparator: getObjectComparator('code'),
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name] ?? '';

      const code = value?.split(' ')[0];
      const description = value?.split(' ').slice(1).join(' ');
      const edits = props?.data?.edits;
      const suggestion = props?.data?.suggestion;

      const hasError = edits && suggestion;

      const openEditDrawer = useCallback(() => {
        handleEditOpen(props.data);
      }, [value]);

      return (
        <MetaListCell
          title={code}
          meta={description}
          icon={
            hasError && (
              <ServiceCodePopover
                handleOpen={openEditDrawer}
                code={code}
                description={description}
                edits={edits}
                suggestion={suggestion}
              />
            )
          }
        />
      );
    },
  },
  {
    // name: 'modifier',
    displayName: 'Modifier',
    // FIXME: BAD CODE - Remove this after schema integration
    cellRenderer: (props) => props.data[props.schema.name]?.join(', '),
  },
  {
    displayName: 'Diagnosis Pointer',
    width: 100,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      return <div>K03.2</div>;
    },
  },
  {
    // name: 'units',
    displayName: 'Units',
    width: 100,
  },
  {
    // name: 'placeOfService',
    displayName: 'POS',
    width: 100,
    sorting: true,
    comparator: getObjectComparator('id'),
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const { id = props?.data?.placeOfService } =
        props.data[props.schema.name] ?? {};
      return <MetaListCell title={id} />;
    },
  },
  {
    // name: 'billingSpeciality',
    displayName: 'Billing Speciality',
    width: 200,
  },
  {
    // name: 'billingProviderName',
    displayName: 'Billing Provider name',
    width: 200,
  },
  {
    // name: 'charge',
    displayName: 'Charges',
  },
  {
    displayName: 'Emergency Indicator',
    width: 100,
  },
  {
    displayName: 'EPSDT Family Plan',
    width: 100,
  },
  {
    // name: 'ndc',
    displayName: 'NDC',
    width: 200,
  },
  !correction && {
    name: 'action',
    displayName: '',
    width: 70,
    minWidth: 70,
    sorting: false,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const lineItem = props.data;

      const openEditDrawer = useCallback(() => {
        handleEditOpen(lineItem);
      }, [lineItem]);

      return (
        <ActionCell
          onClick={(action) => {
            if (action === 'Edit') openEditDrawer();
          }}
          options={[
            { label: 'Edit', value: 'Edit' },
            { label: 'Delete', value: 'Delete' },
          ]}
        />
      );
    },
  },
];
