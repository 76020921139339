import { ResponsivePie } from '@nivo/pie';
import { Radio } from '@innovaccer/design-system';
import { Legend } from './legend';
import { data } from './data';
import { ChartContainer } from '../../../chart-container';

export const ClaimOutcomeChart = () => (
  <ChartContainer
    title="Claim Outcome"
    legend={
      <div>
        <Legend items={data} />
        <div className="mt-6">
          <Radio
            defaultChecked
            label="Count"
            name="type"
            size="tiny"
            value="Count"
            className="mb-4"
          />
          <Radio label="Amount" name="type" size="tiny" value="Amount" />
        </div>
      </div>
    }
  >
    <ResponsivePie
      data={data}
      value="value"
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      innerRadius={0.5}
      colors={{ datum: 'data.color' }}
      enableArcLabels={false}
      arcLinkLabel={(item) => item.formattedValue}
      arcLinkLabelsColor="#706969"
      arcLinkLabelsStraightLength={32}
      valueFormat=" >-.2%"
    />
  </ChartContainer>
);
