export const departmentOptions = [
  { label: 'All', value: 'All', selected: true },
  { label: 'Inpatient', value: 'Inpatient' },
  { label: 'Outpatient', value: 'Outpatient' },
  { label: 'ER', value: 'ER' },
  { label: 'Observation', value: 'Observation' },
];

export const teamOptions = [
  { label: 'Orthopaedic', value: 'Orthopaedic', subInfo: '6 users' },
  { label: 'Neuropathy', value: 'Neuropathy', subInfo: '5 users' },
  {
    label: 'Adam Bryans',
    value: 'Adam Bryans',
    subInfo: 'Orthopaedic In-patient ',
    group: 'Team Users',
  },
  {
    label: 'John Woods',
    value: 'John Woods',
    subInfo: 'Orthopaedic In-patient ',
    group: 'Team Users',
  },
];

export const providerOptions = [
  { label: 'Dr. Oliver O. Aalami MD', value: 'Dr. Oliver O. Aalami MD' },
  { label: 'Dr. Alistair Aaronson MD', value: 'Dr. Alistair Aaronson MD' },
  { label: 'Dr. Sumaira Z. Aasi MD', value: 'Dr. Sumaira Z. Aasi MD' },
  { label: 'Dr. Brian T. Abe MD', value: 'Dr. Brian T. Abe MD' },
  { label: 'Dr. Ranjana H. Advani MD', value: 'Dr. Ranjana H. Advani MD' },
  { label: 'Dr. Auriel T. August MD', value: 'Dr. Auriel T. August MD' },
];
