import { gql } from 'graphql-request';

export const getClaimsQuery = gql`
  query GetPaginatedClaims($paginatedClaimsInput: PaginatedClaimsInput) {
    paginatedClaims(input: $paginatedClaimsInput) {
      count
      claims {
        _id
        assignedTo
        assignmentDate
        claimId
        claimType
        department
        financialClass
        potentialDenialCarcCode
        providerSpecialty
        providerType
        receivedDate
        totalAmount
        serviceStartDate
        serviceEndDate
        provider {
          billing {
            name
            npi
          }
        }
        patient {
          firstName
          lastName
          gender
          dateOfBirth
        }
        insurance {
          name
        }
      }
    }
  }
`;

export const getClaimQuery = gql`
  query GetClaimns($claimId: String!) {
    claim(query: { claimId: $claimId }) {
      _id
      assignedTo
      edits {
        _id
        claimId
        claimLineId
        description
        level
        potentialDenialReason
        references
        ruleRationale
        suggestions
        typeOfEdit
        affectedColumns
      }
      assignmentDate
      claimId
      claimType
      department
      financialClass
      insurance {
        effectiveDate
        groupId
        memberId
        name
        plan
        terminationDate
      }
      items {
        billingProviderName
        billingSpeciality
        charge
        claimId
        claimLineId
        cptCode
        modifier
        performingProviderName
        placeOfService
        primaryDiagnosis
        serviceEndDate
        serviceStartDate
        units
      }
      metadata {
        hospitalizationEndDate
        hospitalizationStartDate
        isOutsideLab

        lmpQualifier
        originalICN

        taxId
        taxIdType
      }
      patient {
        addressLine
        city
        condition
        dateOfBirth
        firstName
        gender
        lastName
        place
        relationship
        state
        zipcode
      }
      potentialDenialCarcCode
      provider {
        addressLine
        billing {
          name
          npi
        }
        city
        date
        phone
        signature
        state
        zipcode
        performing {
          name
          npi
        }
        referring {
          name
          npi

          qualifier
        }
      }
      providerSpecialty
      providerType
      receivedDate
      serviceEndDate
      serviceStartDate
      totalAmount
    }
  }
`;

export const updatePatient = gql`
  mutation UpdatePatient(
    $patientInput: ClaimPatientUpdateInput
    $claimId: String
  ) {
    updateOneClaim(
      query: { claimId: $claimId }
      set: { patient: $patientInput }
    ) {
      patient {
        addressLine
        city
        condition
        dateOfBirth
        firstName
        gender
        lastName
        place
        relationship
        state
        zipcode
      }
    }
  }
`;

export const updateProvider = gql`
  mutation UpdateProvider(
    $providerInput: ClaimProviderUpdateInput
    $claimId: String
  ) {
    updateOneClaim(
      query: { claimId: $claimId }
      set: { provider: $providerInput }
    ) {
      provider {
        addressLine
        city
        date
        phone
        signature
        state
        zipcode
      }
    }
  }
`;

export const updateMetadata = gql`
  mutation UpdateMetadata(
    $metadataInput: ClaimMetadatumUpdateInput
    $claimId: String
  ) {
    updateOneClaim(
      query: { claimId: $claimId }
      set: { metadata: $metadataInput }
    ) {
      metadata {
        additionalInformation
        hospitalizationEndDate
        hospitalizationStartDate
        isOutsideLab
        lmpDate
        lmpQualifier
        originalICN
        outsideLabCharges
        priorAuthorization
        resubmissionCode
        taxId
        taxIdType
      }
    }
  }
`;

export const deleteEdit = gql`
  mutation DeleteEdit($id: ObjectId!) {
    deleteOneEdit(query: { _id: $id }) {
      _id
    }
  }
`;

export const updateClaimItems = gql`
  mutation UpdateClaimItems($items: [ClaimItemUpdateInput], $id: ObjectId!) {
    updateOneClaim(query: { _id: $id }, set: { items: $items }) {
      items {
        billingProviderName
        billingSpeciality
        charge
        claimId
        claimLineId
        cptCode
        modifier
        performingProviderName
        placeOfService
        primaryDiagnosis
        serviceEndDate
        serviceStartDate
        units
      }
    }
  }
`;
