import { ROUTES } from 'src/router/route-names';

export const topLinks = [
  {
    id: 1,
    label: 'Worklist',
    href: ROUTES.denialsPrediction,
  },
  {
    id: 2,
    label: 'Revenue Intelligence',
    href: '/dashboard',
  },
];

export const logo =
  'https://innovaccer.com/static/image/site-logo/innovaccer-logo-black.svg';
