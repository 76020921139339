import { Icon, PlaceholderParagraph } from '@innovaccer/design-system';
import { BoldCell, MetaListCell } from 'src/pages/common';
import { getObjectComparator } from 'src/services/utils';

export const missedChargesINTableSchema = [
  {
    name: 'p',
    displayName: 'P',
    width: 50,
    minWidth: 50,
    cellType: 'ICON',
    sorting: false,
    cellRenderer: ({ loading }) => {
      if (loading) return <PlaceholderParagraph />;

      return <Icon size={20} name="flag" appearance="alert" />;
    },
  },
  {
    name: 'claimId',
    displayName: 'Claim ID',
    sorting: true,
    comparator: getObjectComparator('claimId'),
    width: 100,
    cellRenderer: BoldCell,
  },
  {
    name: 'dateOfService',
    displayName: 'Statement from Date',
    sorting: true,
    comparator: getObjectComparator('serviceStartDate'),
    cellRenderer: ({ data, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      return data?.serviceStartDate && data?.serviceEndDate
        ? `${new Date(data.serviceStartDate).toLocaleDateString()} - ${new Date(
            data.serviceEndDate
          ).toLocaleDateString()}`
        : null;
    },
  },
  {
    name: 'dateOfService',
    displayName: 'Statement through Date',
    sorting: true,
    comparator: getObjectComparator('serviceStartDate'),
    cellRenderer: ({ data, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      return data?.serviceStartDate && data?.serviceEndDate
        ? `${new Date(data.serviceStartDate).toLocaleDateString()} - ${new Date(
            data.serviceEndDate
          ).toLocaleDateString()}`
        : null;
    },
  },
  {
    name: 'patient',
    displayName: 'Patient Name',

    sorting: true,
    comparator: (a, b) => {
      const leftValue = a.patient.firstName;
      const rightValue = b.patient.firstName;
      if (leftValue < rightValue) return -1;
      if (leftValue > rightValue) return 1;
      return 0;
    },
    cellRenderer: ({ loading, data, schema }) => {
      if (loading) return <PlaceholderParagraph />;

      const { firstName, lastName, dateOfBirth, gender } =
        data[schema.name] ?? {};
      return (
        <MetaListCell
          title={`${firstName} ${lastName}`}
          meta={`${
            dateOfBirth && `${new Date(dateOfBirth).toLocaleDateString()}, `
          } ${gender}`}
        />
      );
    },
  },
  {
    name: 'numberOfMissedCharges',
    displayName: 'Number of Missed charges',
    sorting: true,
    comparator: getObjectComparator('insurance'),
  },
  {
    name: 'typeOfMissedCharges',
    displayName: 'Type of Charges Missed',
    sorting: true,
    comparator: getObjectComparator('insurance'),
  },
  {
    name: 'principalDiagnosis',
    displayName: 'Principal Diagnosis',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'drgCode',
    displayName: 'DRG Code',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'drgWeight',
    displayName: 'DRG Weight',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'revenueCode',
    displayName: 'Revenue Code',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'LOS',
    displayName: 'LOS',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'HCPCS Code',
    displayName: 'HCPCS Code',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'insurance',
    displayName: 'Insurance',
    sorting: true,
    comparator: getObjectComparator('insurance'),
    cellRenderer: ({ data, schema, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      const cellValue = data[schema.name] ?? '';

      return cellValue ? cellValue[0]?.name : null;
    },
  },
  {
    name: 'Financial Class',
    displayName: 'Financial Class',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'totalClaimAmount',
    displayName: 'Total claim Amount',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'Department',
    displayName: 'Department',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'bilingStatus',
    displayName: 'Billing Status',
    sorting: true,
    comparator: getObjectComparator('claimType'),
  },
  {
    name: 'assignmentDate',
    displayName: 'Assignment assignedToDate',
    sorting: true,
    comparator: getObjectComparator('assignmentDate'),
    cellRenderer: ({ data, schema, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      const cellDate = data[schema.name] ?? '';

      return cellDate ? new Date(cellDate).toLocaleDateString() : null;
    },
  },
  {
    name: 'receivedDate',
    displayName: 'Received Date',
    sorting: true,
    comparator: getObjectComparator('receivedDate'),
    cellRenderer: ({ data, schema, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      const cellDate = data[schema.name] ?? '';

      return (
        <MetaListCell
          title="Overdue"
          meta={cellDate && new Date(cellDate).toLocaleDateString()}
          appearance="destructive"
        />
      );
    },
  },
];
