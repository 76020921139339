import { Text } from '@innovaccer/design-system';
import { FiUpload } from 'react-icons/fi';
import { ChartContainer } from '../../chart-container';
import { data } from './data';
import styles from '../tableStyles.module.scss';

export const ActionsTable = () => (
  <ChartContainer
    title="Actions"
    headerActions={
      <div className="d-flex align-items-center">
        <FiUpload size="16" className="mr-4" />
        <Text>Export</Text>
      </div>
    }
    hideLegend
  >
    <div className={styles.scrollableArea}>
      <table className={styles.customTable}>
        <tbody>
          <tr>
            <td>Claim Id</td>
            <td>CPT/HCPCS Code</td>
            <td>Username</td>
            <td>Specialty</td>
            <td>Actions</td>
            <td>Comments</td>
          </tr>
          {data.map((item) => (
            <tr>
              <td>
                <Text>{item.claimId}</Text>
              </td>
              <td>
                <Text>{item.cptCode}</Text>
              </td>
              <td>
                <Text>{item.username}</Text>
              </td>
              <td>
                <Text>{item.specialty}</Text>
              </td>
              <td>
                <Text>{item.actions}</Text>
              </td>
              <td>
                <Text>{item.comments}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </ChartContainer>
);
