import styles from './legend.module.scss';

const LegendItem = ({ color, label }) => (
  <div className={styles.item}>
    <div className={styles.circle} style={{ backgroundColor: color }} />

    <p className={styles.label}>{label}</p>
  </div>
);

export const Legend = () => (
  <div direction="column">
    <LegendItem key="Clean Claim" label="Clean Claim" color="#26ED22" />
    <p className={styles.groupLabel}>Denials</p>
    <LegendItem key="Hard Denials" label="Hard Denials" color="#FEAC5F" />
    <LegendItem
      key="Preventable Denials"
      label="Preventable Denials"
      color="#D93737"
    />
    <p className={styles.groupLabel}>Payment Varience</p>
    <LegendItem key="Under Payment" label="Under Payment" color="#9D7EC6" />
    <LegendItem key="Over Payment" label="Over Payment" color="#FEAC5F" />
    <LegendItem key="False Positive" label="False Positive" color="#FF7070" />
  </div>
);
