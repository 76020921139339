import {
  Divider,
  Dropdown,
  Heading,
  Icon,
  Input,
  Text,
  Card,
  Tooltip,
} from '@innovaccer/design-system';
import React, { useState } from 'react';
import classNames from 'classnames';
import { PDFView } from './pdf-view';
import styles from './document.module.scss';

const FileImage = ({ name }) => (
  <Tooltip tooltip={name} position="top-start">
    <div className={classNames(styles.truncate, 'mb-4 cursor-pointer ')}>
      <div className={styles.fileImageContainer}>
        <Text size="small" appearance="subtle" className={styles.fileImageText}>
          File
        </Text>
      </div>
      <Text size="small">{name}</Text>
    </div>
  </Tooltip>
);

export const CorrectionDocuments = ({ documents = [] }) => {
  const defaultDoc = documents.length > 0 ? documents[0] : null;

  const [selectedDoc, setSelectedDoc] = useState(defaultDoc);

  const documentOptions = documents.map((d) => ({
    label: d.name,
    value: d.documentId,
  }));

  const handleOnChange = (_type, selected) => {
    setSelectedDoc(documents.find((d) => d.documentId === selected.value));
  };

  const documentSelector = () => (
    <div className={styles.selector}>
      <Heading size="s" className="mr-3">
        {selectedDoc?.name}
      </Heading>
      <Icon name="keyboard_arrow_down_round" />
    </div>
  );

  return (
    <div className="py-4 px-7">
      <div className="d-flex mb-6 align-items-center">
        <Input
          className="w-75 mr-5"
          icon="search"
          name="input"
          placeholder="Search"
        />
        <Dropdown className="w-25" placeholder="Advanced" />
        <Icon name="open_in_new" size={22} className="ml-6" />
      </div>
      <div className={classNames(styles.spaceBetween, 'd-flex')}>
        <FileImage name="Encounter reports" />
        <FileImage name="Surgery reports" />
        <FileImage name="Imaging reports" />
        <FileImage name="Labs" />
        <FileImage name="Pathology reports" />
        <FileImage name="Forms" />
        <FileImage name="Others" />
      </div>
      <Card className="h-100">
        <div className="py-5 px-7">
          <Dropdown
            options={documentOptions}
            customTrigger={documentSelector}
            selected={[selectedDoc]}
            onUpdate={handleOnChange}
          />
          <Text appearance="subtle">Last updated 2 days ago</Text>
        </div>
        <Divider />
        <div className="py-5 px-7">
          {selectedDoc && <PDFView file={selectedDoc.url} />}
        </div>
      </Card>
    </div>
  );
};
