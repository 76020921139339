/**
 * This class should be used for accessing the Auth0 client instance outside of the React DOM Tree,
 * when this is needed. Avoid using this class inside reducers, slices and other Redux logic.
 */
class Auth0 {
  static client;

  static setClient(clientInstance) {
    this.client = clientInstance;
  }
}

export default Auth0;
