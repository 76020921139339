/* eslint no-underscore-dangle: 0 */
import React from 'react';
import {
  Tabs,
  Tab,
  Icon,
  Column,
  Pagination,
  Card,
  Row,
  Text,
  Button,
  Table,
} from '@innovaccer/design-system';
import classNames from 'classnames';
import { useClaim } from 'src/pages/ClaimDetails/hooks/useClaimDetails';
import { useDeleteEditMutation } from 'src/features/claims/store/api';
import { tableSchema } from './schema';
import styles from '../styles.module.scss';

export const EditDetails = ({ height, correction }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [deleteEdit] = useDeleteEditMutation();

  const { claim } = useClaim();

  const onTabChangeHandler = (tabIndex) => {
    setActiveIndex(tabIndex);
  };

  const handleDelete = async (values) => {
    try {
      await deleteEdit({
        id: values,
      });
    } catch (error) {
      // TODO: Implement error handling
      console.error(error);
    }
  };

  const EditDetailsList = ({ title, description }) => (
    <div className="d-flex">
      <div
        style={{
          width: correction ? '70%' : '30%',
        }}
        className={classNames('py-6 pr-8', styles.editDetailsList)}
      >
        <Text
          size="large"
          appearance="subtle"
          className={correction ? 'ml-6' : 'ml-10'}
        >
          {title}
        </Text>
      </div>
      <div className="w-50  mx-10 py-6">
        <Text>{description}</Text>
      </div>
    </div>
  );
  return (
    <div className="bg-light">
      {claim?.edits.length > 0 ? (
        <Tabs
          activeIndex={activeIndex}
          onTabChange={onTabChangeHandler}
          className="ml-4"
        >
          <Tab label="Edit Details" count={claim?.edits.length}>
            <Card
              style={{
                height,
              }}
              className={styles.editDetailsCard}
              shadow="none"
            >
              <div className="d-flex align-items-center">
                <Text
                  size="large"
                  weight="strong"
                  className={classNames(
                    'mx-10 d-flex align-items-center w-100',
                    styles.editClaimLine
                  )}
                >
                  Edit claim Line : #{currentPage + 1}
                </Text>
                <Row className="justify-content-end">
                  <Pagination
                    type="basic"
                    page={1}
                    totalPages={claim?.edits.length}
                    onPageChange={(pageNo) => setCurrentPage(pageNo - 1)}
                  />
                </Row>
              </div>
              <Card className={classNames('d-flex ', styles.editDetailsUlData)}>
                <ul className={styles.editDeailsUl}>
                  <div
                    className="d-flex"
                    style={{
                      width: !correction && '92vw',
                    }}
                  >
                    <Column>
                      <EditDetailsList
                        title="Edit No:"
                        description={currentPage + 1}
                      />
                      <EditDetailsList
                        title="Edit Type:"
                        description={claim?.edits[currentPage]?.typeOfEdit}
                      />
                      <EditDetailsList
                        title="Edit Description:"
                        description={claim?.edits[currentPage]?.description}
                      />
                      <EditDetailsList
                        title="Potential Denial Reason:"
                        description={
                          claim?.edits[currentPage]?.potentialDenialReason
                        }
                      />
                      <EditDetailsList
                        title="Rule Rationale:"
                        description={claim?.edits[currentPage]?.ruleRationale}
                      />
                      <EditDetailsList
                        title="Suggestion:"
                        description={claim?.edits[currentPage]?.suggestions}
                      />
                      <div className="d-flex">
                        <div
                          className={classNames(
                            'py-6 pr-8',
                            styles.editDetailsReferences
                          )}
                          style={{
                            width: correction ? '70%' : '30%',
                          }}
                        >
                          <Text
                            size="large"
                            appearance="subtle"
                            className={correction ? 'ml-6' : 'ml-10'}
                          >
                            References:
                          </Text>
                        </div>
                        <div className="w-50 mx-10 py-6">
                          <Text
                            appearance="link"
                            className={classNames(
                              'd-flex align-items-center ',
                              styles.editDetailsReferencesIcon
                            )}
                          >
                            <Icon name="link" className="mr-4" />
                            {claim?.edits[currentPage]?.references}
                          </Text>
                        </div>
                      </div>
                    </Column>
                    <div className="justify-content-end mt-4 mr-2">
                      <Text
                        appearance="link"
                        className="mr-6 cursor-pointer"
                        weight="medium"
                        onClick={() =>
                          handleDelete(claim?.edits[currentPage]?._id)
                        }
                      >
                        Fix
                      </Text>
                      <Text appearance="link" weight="medium">
                        Ignore
                      </Text>
                    </div>
                  </div>
                </ul>
              </Card>
            </Card>
          </Tab>
          <Tab label="History Claims">
            <div className="my-3 w-100">
              <Table
                data={[]}
                schema={tableSchema}
                showMenu={false}
                withHeader
                withPagination
                pageSize={10}
              />
            </div>
          </Tab>
          <Tab label="Payer Trends">
            <div className="my-3 w-100">
              <Table
                data={[]}
                schema={tableSchema}
                showMenu={false}
                withHeader
                withPagination
                pageSize={10}
              />
            </div>
          </Tab>
        </Tabs>
      ) : (
        <div className="d-flex justify-content-between align-items-center mx-4 py-4">
          <Text className="ml-4" weight="medium">
            No edits found
          </Text>
          <Button icon="done" iconAlign="left" appearance="primary">
            Mark as complete
          </Button>
        </div>
      )}
    </div>
  );
};
