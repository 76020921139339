export const data = [
  {
    label: 'Chronic kidney disease, unspecified (N18.9)',
    value: 3456,
  },
  {
    label: 'Anaemia, unspecified (D64.9)',
    value: 2764,
  },
  {
    label: 'Congestive heart failure (CHF) (I50.9)',
    value: 2534,
  },
  {
    label: 'Depression (F32.9)',
    value: 2304,
  },
  {
    label: 'Diabetes mellitus (E11.9)',
    value: 2073,
  },
  {
    label: 'Traumatic fractures of unspecified laterality (S72.019X)',
    value: 1843,
  },
  {
    label: 'Pressure ulcer of unspecified site, unspecified stage (L89.90)',
    value: 1612,
  },
  {
    label: 'Hemiplegia, unspecified (G81.90)',
    value: 1152,
  },
  {
    label: 'Glaucoma, Unspecified (H40.9)',
    value: 691,
  },
  {
    label: 'Malignant (primary) neoplasm, unspecified (C80.1)',
    value: 345,
  },
].reverse();
