import { DenialsManagement, DenialsManagementDetail } from 'src/pages';
import { ROUTES } from '../route-names';

export const DENIALS_MANAGEMENT = [
  {
    path: ROUTES.denialsManagement,
    element: DenialsManagement,
  },
  {
    path: `${ROUTES.denialsManagement}/:id/*`,
    element: DenialsManagementDetail,
  },
];
