export const data = [
  {
    date: '03 Mar 22 at 7:30 PM',
    claimId: 'IC3780088',
    username: '@tinjo',
    actions: {
      action: 'add',
      meta: 'Added: 99203-25',
    },
    comments: 'Documentation supports significant E/M service',
  },
  {
    date: '02 Mar 22 at 6 PM',
    claimId: 'IC3780092',
    username: '@Raghav',
    actions: {
      action: 'remove',
      meta: 'Removed: 99203',
    },
    comments: "Documentation doesn't supports",
  },
  {
    date: '02 Mar 22 at 4:16 PM',
    claimId: 'IC3780065',
    username: '@tinjo',
    actions: {
      action: 'add',
      meta: 'Added: 99203-25',
    },
    comments: 'Documentation supports significant E/M service',
  },
  {
    date: '01 Mar 22 at 5:30 PM',
    claimId: 'IC3780054',
    username: '@Raghav',
    actions: {
      action: 'remove',
      meta: 'Removed: 99203',
    },
    comments: "Documentation doesn't supports",
  },
  {
    date: '28 Feb 22 at 3 PM',
    claimId: 'IC3780076',
    username: '@tinjo',
    actions: {
      action: 'add',
      meta: 'Added: 99203-25',
    },
    comments: 'Documentation supports significant E/M service',
  },
];
