import { Icon, Input, DatePicker, Dropdown } from '@innovaccer/design-system';
import React from 'react';

export const AdvancedComponent = () => {
  const [dropdownValue, setDropdownValue] = React.useState('single');

  return (
    <div className="d-flex flex-row justify-content-between my-6">
      <div className="mx-2">
        <Input name="firstName" placeholder="First Name" minWidth={0} />
      </div>
      <div className="mx-2">
        <Input name="lastName" placeholder="last Name" minWidth={0} />
      </div>
      <div className="mx-2">
        <DatePicker
          inputOptions={{
            required: true,
            minWidth: 0,
          }}
          onDateChange={() => null}
          outputFormat="yyyy/mm/dd"
          withInput
          closeOnSelect
        />
      </div>
      <div className="mx-2">
        <Input
          icon="search"
          name="memberId"
          placeholder="Member ID"
          minWidth={0}
        />
      </div>
      <div className="mx-2">
        <Input icon="search" name="mrn" placeholder="MRN" minWidth={0} />
      </div>
      <div
        className="d-flex flex-row justify-content-between px-4 py-2 align-items-center"
        style={{
          borderRadius: '4px',
          height: '28px',
        }}
      >
        <Dropdown
          inlineLabel="CPT"
          options={[
            {
              label: 'Single',
              value: 'single',
            },
            {
              label: 'Range',
              value: 'range',
            },
          ]}
          onChange={(e) => setDropdownValue(e)}
        />
        {dropdownValue === 'range' ? (
          <>
            <Input
              className="mr-4 ml-4"
              minWidth={0}
              name="multiple"
              placeholder="Multiple"
            />
            <Icon name="arrow_right_alt" size="18" />
            <Input
              className="mx-4"
              minWidth={0}
              name="multiple"
              placeholder="Multiple"
            />
          </>
        ) : (
          <Input
            className="mr-4 ml-4"
            minWidth={0}
            name="single"
            placeholder="Single"
          />
        )}
      </div>
    </div>
  );
};
