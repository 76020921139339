import { Card, Column, Text } from '@innovaccer/design-system';
import classNames from 'classnames';
import styles from '../styles.module.scss';

const TableCard = ({ title, firstValue, secondValue }) => (
  <Column className={classNames('ml-4 mr-6', styles.tableHeaderCard)}>
    <Text weight="medium">{title}</Text>
    <div className="d-flex flex-row my-4">
      <Text className="mr-6">{firstValue}</Text>
      <Text weight="strong">{secondValue}</Text>
    </div>
  </Column>
);

export const TableHeaderCard = () => (
  <Card className="d-flex flex-row justify-content-between">
    <TableCard title="Received" firstValue="100.5K" secondValue="$10.96M" />
    <TableCard title="Completed" firstValue="100.5K" secondValue="$10.96M" />
    <TableCard title="Pending" firstValue="0" secondValue="$0" />
    <TableCard title="Assigned" firstValue="100.5K" secondValue="$10.96M" />
    <Column className={classNames(' ml-4 mr-6', styles.tableHeaderUnassigned)}>
      <Text weight="medium">Unassigned</Text>
      <div className="d-flex flex-row my-4">
        <Text className="mr-6">0 </Text>
        <Text weight="strong">$0</Text>
      </div>
    </Column>
  </Card>
);
