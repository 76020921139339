import React from 'react';

export const CoverselfLogo = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00258 13.2543C9.27915 13.2709 9.53264 13.0903 9.76884 12.7638L14.5203 4.01693C15.8743 3.22072 16.0297 2.03349 15.9818 1.32651L15.9951 1.30209C16.3746 0.64758 16.7796 0.211036 18 0.0735612L16.8986 8.25936C16.6843 9.96664 15.8549 11.0722 15.0191 12.011L9.83836 16.9453C9.56634 17.1772 9.28826 17.3059 9.00258 17.3077H8.92456C8.63888 17.3059 8.43366 17.1772 8.16164 16.9453L2.98092 12.011C2.14515 11.0722 1.31575 9.96664 1.10172 8.25936L0 0.0735612C1.22042 0.211036 1.62541 0.64758 2.00519 1.30209L2.01825 1.32651C1.97028 2.03349 2.12572 3.22072 3.47972 4.01693L8.23116 12.7638C8.46736 13.0903 8.72085 13.2709 8.99742 13.2543H9.00258ZM9.00258 7.59158L12.3232 2.01419C12.5488 1.69311 12.7334 1.39857 12.9173 1.13628C13.5846 0.365696 14.727 0.0798923 15.7887 0L15.282 0.909265C14.3287 1.29215 13.9529 2.03921 13.8257 2.73744C13.7686 3.05189 13.7616 3.35638 13.7753 3.61355C12.4097 6.06428 10.9322 8.71671 9.69172 10.9422C9.51655 11.2567 9.26761 11.4816 9.00258 11.4912H8.99742C8.73239 11.4816 8.48345 11.2567 8.30828 10.9422C7.06782 8.71671 5.59026 6.06428 4.22472 3.61355C4.23838 3.35638 4.2314 3.05189 4.17433 2.73744C4.04712 2.03921 3.67128 1.29215 2.71802 0.909265L2.21133 0C3.27328 0.0798923 4.41537 0.365696 5.08296 1.13628C5.26694 1.39857 5.45121 1.69311 5.67678 2.01419L8.99742 7.59158V7.58706L9.00015 7.59158L9.00258 7.58706V7.59158Z"
      fill="#374151"
    />
  </svg>
);
