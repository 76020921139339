export const CountData = [
  {
    label: 'Duplicate record',
    value: 5594,
  },
  {
    label: 'NCCI PTP Edit',
    value: 3642,
  },
  {
    label: 'Claim Lacks Info',
    value: 2963,
  },
  {
    label: 'Patient age and Procedure mismatch',
    value: 2930,
  },
  {
    label: 'Gender and Procedure mismatch',
    value: 1699,
  },
  {
    label: 'Modifier Inconsistant with Procedure',
    value: 1227,
  },
  {
    label: 'Invalid HCPCS code',
    value: 539,
  },
  {
    label: 'Invalid ICD code',
    value: 462,
  },
  {
    label: 'Invalid Modifier code',
    value: 107,
  },
  {
    label: 'Bundled into the Physician Services',
    value: 41,
  },
].reverse();

export const AmountData = [
  {
    label: 'Edit_Type',
    value: null,
  },
  {
    label: 'Duplicate record',
    value: 1804799.33,
  },
  {
    label: 'Gender and Procedure mismatch',
    value: 332728.73,
  },
  {
    label: 'Claim Lacks Info',
    value: 317913.53,
  },
  {
    label: 'NCCI PTP Edit',
    value: 198638.73,
  },
  {
    label: 'Modifier Inconsistant with Procedure',
    value: 109541.68,
  },
  {
    label: 'Invalid ICD code',
    value: 47292.3,
  },
  {
    label: 'Invalid Modifier code',
    value: 9932.02,
  },
  {
    label: 'Invalid HCPCS code',
    value: 8829.11,
  },
  {
    label: 'Bundled into the Physician Services',
    value: 316.41,
  },
  {
    label: 'Patient age and Procedure mismatch',
    value: 148.35,
  },
].reverse();
