import { DenialsManagementDetailsIN, DenialsManagementIN } from 'src/pages';
import { ROUTES } from '../route-names';

export const DENIALS_MANAGEMENT_IN = [
  {
    path: ROUTES.denialsManagementIN,
    element: DenialsManagementIN,
  },
  {
    path: `${ROUTES.denialsManagementIN}/:id/*`,
    element: DenialsManagementDetailsIN,
  },
];
