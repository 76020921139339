// Convert number to shortend number format with currency prefix
export const toShortenedDollarFormat = (value) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value);

export const lineDataFilterFn = (data, selected) =>
  data.filter(
    (d) => selected.findIndex((k) => k.id === d.id && k.checked) > -1
  );

export const columnDataFilterFn = (data, selected, columnKey) =>
  data.map((d) => {
    const updated = {
      [columnKey]: d[columnKey],
    };
    selected
      .filter((i) => i.checked)
      .forEach((k) => {
        updated[k.id] = d[k.id];
        updated[`${k.id}Color`] = d[`${k.id}Color`];
      });
    return updated;
  });
