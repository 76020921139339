import { DenialsPrediction, ClaimDetail } from 'src/pages';
import { ROUTES } from '../route-names';

export const DENIALS_PREDICTION = [
  {
    path: ROUTES.denialsPrediction,
    element: DenialsPrediction,
  },
  {
    path: `${ROUTES.denialsPrediction}/:id/*`,
    element: ClaimDetail,
  },
];
