import { ResponsiveBar } from '@nivo/bar';
import { ChartContainer } from '../../chart-container';
import { Legend } from '../../legend';
import { useLegendFilter } from '../use-legend-filter';
import { columnDataFilterFn } from '../util';
import { data } from './data';

const DATA_KEYS = ['CleanClaims', 'EditClaims'];

export const BySpecialtyChart = () => {
  const legendItems = DATA_KEYS.map((k) => ({
    id: k,
    label: k,
    color: data[0][`${k}Color`],
  }));

  const { selected, filteredData, handleFilter } = useLegendFilter(
    legendItems,
    data,
    columnDataFilterFn
  );

  return (
    <ChartContainer
      title="By Specialty"
      legend={<Legend items={selected} showCheck onCheck={handleFilter} />}
    >
      <ResponsiveBar
        data={filteredData}
        indexBy="label"
        keys={DATA_KEYS}
        layout="vertical"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: false }}
        enableGridY={false}
        margin={{ top: 20, right: 10, bottom: 50, left: 40 }}
        padding={0.8}
        enableLabel={false}
        colors={(d) => d.data[`${d.id}Color`]}
      />
    </ChartContainer>
  );
};
