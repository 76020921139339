import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Layout } from 'src/components';
import { ROUTES } from 'src/router/route-names';
import {
  DashboardFilters,
  DashboardSummary,
  OperationDashboard,
  RevenueIntelligenceDashboard,
} from './blocks';

export const Dashboard = () => {
  const [preSubmission, setPreSubmission] = React.useState(true);
  const location = useLocation();
  const isOperationPage = location.pathname === ROUTES.operation;

  if (location.pathname === ROUTES.dashboard) {
    return <Navigate to={ROUTES.operation} replace />;
  }
  return (
    <Layout showSideBar={false}>
      <DashboardFilters
        isOperationPage={isOperationPage}
        setPreSubmission={setPreSubmission}
      />
      <DashboardSummary />
      {isOperationPage ? (
        <OperationDashboard preSubmission={preSubmission} />
      ) : (
        <RevenueIntelligenceDashboard />
      )}
    </Layout>
  );
};
