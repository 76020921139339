import {
  Breadcrumbs,
  Button,
  Dropdown,
  Label,
} from '@innovaccer/design-system';
import { Link } from 'react-router-dom';
import { CustomPageHeader } from 'src/components';
import { ROUTES } from 'src/router/route-names';
import {
  departmentOptions,
  payorOptions,
  specialtyOptions,
} from './filter-options';
import './filters.scss';

const buttonClasses =
  'Button Button--regular Button--basic Button--iconAlign-left';
const breadcrumbs = [
  {
    label: 'Dashboard',
    link: ROUTES.dashboard,
  },
];

const operationOptions = [
  {
    label: 'Pre submission',
    value: 'preSubmission',
    selected: true,
  },
  {
    label: 'Post submission',
    value: 'postSubmission',
  },
];

export const DashboardFilters = ({ isOperationPage, setPreSubmission }) => (
  <CustomPageHeader
    className="PageHeader--custom"
    title={isOperationPage ? 'Operation' : 'Revenue Intelligence'}
    breadcrumbs={<Breadcrumbs list={breadcrumbs} />}
    navigation={
      <div className="d-flex">
        <div className="mr-5 w-50">
          <Label className="mb-4" withInput>
            View
          </Label>
          <div className="SplitButtonsWrapper">
            <Dropdown
              options={operationOptions}
              minWidth={0}
              className="mr-4"
              onChange={(e) =>
                e === 'postSubmission'
                  ? setPreSubmission(false)
                  : setPreSubmission(true)
              }
            />

            <Link
              to={ROUTES.operation}
              className={`${buttonClasses} ${
                isOperationPage ? 'Button--selected' : ''
              }`}
            >
              Operation
            </Link>
            <Link
              to={ROUTES.revenueIntelligence}
              className={`${buttonClasses} ${
                !isOperationPage ? 'Button--selected' : ''
              } ml-4`}
            >
              Revenue Intelligence
            </Link>
          </div>
        </div>
        <div className="mr-5 w-25">
          <Label className="mb-4" withInput>
            Department
          </Label>
          <Dropdown className="Dropdown--custom" options={departmentOptions} />
        </div>
        <div className="mr-5 w-25">
          <Label className="mb-4" withInput>
            Specialty
          </Label>
          <Dropdown className="Dropdown--custom" options={specialtyOptions} />
        </div>
        <div className="mr-5">
          <Label className="mb-4" withInput>
            Payor
          </Label>
          <Dropdown className="Dropdown--custom" options={payorOptions} />
        </div>
      </div>
    }
    actions={
      <div className="d-flex justify-content-end">
        <div>
          <Label className="mb-4" withInput>
            Date Range
          </Label>
          <div className="d-flex DateRange">
            <Button className="mr-4">Today</Button>
            <Button className="mr-4">Last 7 Days</Button>
            <Button className="mr-4">Custom Range</Button>
          </div>
        </div>
      </div>
    }
  />
);
