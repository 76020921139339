import { Column, Heading, Row } from '@innovaccer/design-system';
import classNames from 'classnames';

export const CustomPageHeader = (props) => {
  const {
    title,
    navigation,
    stepper,
    actions,
    tabs,
    breadcrumbs,
    badge,
    separator,
    className,
  } = props;

  const wrapperClasses = classNames(
    {
      'PageHeader-wrapper': true,
      'PageHeader-wrapper--separator': separator,
      'PageHeader-wrapper--withTabs': tabs,
    },
    className
  );

  const classes = classNames({
    PageHeader: true,
  });

  return (
    <div className={wrapperClasses}>
      <div className={classes}>
        <Row className="align-items-center">
          <Column size="2" sizeXL="2" sizeM="2">
            {breadcrumbs}
            <div className="PageHeader-titleWrapper">
              <Heading className="PageHeader-title">{title}</Heading>
              {badge}
            </div>
          </Column>
          <Column size="6" sizeXL="6" sizeM="6">
            <div className="PageHeader-navigationWrapper">
              {navigation || stepper}
            </div>
          </Column>
          <Column size="4" sizeXL="4" sizeM="4">
            {actions}
          </Column>
        </Row>
      </div>
    </div>
  );
};

CustomPageHeader.defaultProps = {
  separator: true,
};
