import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'src/components';
import { Table } from 'src/components/Table';
import { useLazyGetClaimsQuery } from 'src/features/claims/store/api';
import { denialsManagementTableSchema } from './tableSchema';

const pageSize = 10;

const translateData = (schema, data) => {
  let newData = data;

  if (schema.translate) {
    const translatedData = schema.translate(data);
    newData = {
      ...newData,
      [schema.name]:
        typeof translatedData === 'object'
          ? {
              ...newData[schema.name],
              ...translatedData,
            }
          : translatedData,
    };
  }
  if (typeof newData[schema.name] !== 'object')
    newData[schema.name] = { title: newData[schema.name] };

  return newData;
};

const sortData = (schema, data, sortingList) => {
  const sortedData = [...data];
  sortingList.forEach((l) => {
    const sIndex = schema.findIndex((s) => s.name === l.name);
    if (sIndex !== -1) {
      const defaultComparator = (a, b) => {
        const aData = translateData(schema[sIndex], a);
        const bData = translateData(schema[sIndex], b);
        return aData[l.name].title.localeCompare(bData[l.name].title);
      };

      const { comparator = defaultComparator } = schema[sIndex];

      sortedData.sort(comparator);
      if (l.type === 'desc') sortedData.reverse();
    }
  });

  return sortedData;
};

export const DenialsManagement = () => {
  const navigate = useNavigate();
  const [getClaimsQuery] = useLazyGetClaimsQuery();

  const handleRowClick = ({ claimId }) => {
    navigate(`${claimId}/patient-details`);
  };

  const fetchData = async ({ page, sortingList }) => {
    const skip = (page - 1) * pageSize;
    const claimData = await getClaimsQuery({ skip, limit: pageSize }).unwrap();
    const sortedData = sortData(
      denialsManagementTableSchema,
      claimData.paginatedClaims.claims,
      sortingList
    );
    return {
      count: claimData.paginatedClaims.count,
      data: sortedData,
      schema: denialsManagementTableSchema,
    };
  };

  return (
    <Layout>
      <div className="p-5">
        <Table
          schema={denialsManagementTableSchema}
          pageSize={pageSize}
          fetchData={fetchData}
          onRowClick={handleRowClick}
        />
      </div>
    </Layout>
  );
};
