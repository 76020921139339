import { Card, Table as TableComponent } from '@innovaccer/design-system';
import { TableHeader } from './TableHeader';

export const Table = ({ schema, data, pageSize, fetchData, onRowClick }) => (
  <>
    <TableHeader />
    <Card className="mt-5">
      <TableComponent
        data={data}
        schema={schema}
        showMenu={false}
        withCheckbox
        withPagination
        pageSize={pageSize}
        type="resource"
        onRowClick={onRowClick}
        {...(fetchData ? { fetchData } : {})}
      />
    </Card>
  </>
);
