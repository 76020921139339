import React from 'react';
import { Correction } from './components/Correction';
import { BillDetails } from './components/BillDetails';
import { useClaim } from '../../hooks/useClaimDetails';

export const BillItems = () => {
  const { claim, claimId, updateItem } = useClaim();
  const [correction, setCorrection] = React.useState(false);
  const [activities, setActivities] = React.useState(false);
  const [disabled, setDisable] = React.useState(true);
  const [disableCharges, setDisableCharges] = React.useState(true);

  return (
    <div className="px-6 py-5">
      {correction ? (
        <Correction
          claim={claim}
          claimId={claimId}
          correction={correction}
          setCorrection={setCorrection}
          disabled={disabled}
          setDisable={setDisable}
          updateItem={updateItem}
          activities={activities}
          setActivities={setActivities}
          disableCharges={disableCharges}
          setDisableCharges={setDisableCharges}
        />
      ) : (
        <BillDetails
          claim={claim}
          claimId={claimId}
          correction={correction}
          setCorrection={setCorrection}
          setDisable={setDisable}
          updateItem={updateItem}
          disabled={disabled}
        />
      )}
    </div>
  );
};
