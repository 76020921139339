import React from 'react';
import {
  FormDropdown,
  FormInput,
  FormContext,
  HTMLForm,
  FormDatePicker,
} from 'src/components/form';
import {
  Card,
  Column,
  Heading,
  Label,
  Row,
  Caption,
  Text,
} from '@innovaccer/design-system';
import { useDebouncedCallback } from 'use-debounce';
import { useUpdateProviderMutation } from 'src/features/claims/store/api';
import { DATE_MASK } from 'src/services/constant';
import { useClaim } from '../hooks/useClaimDetails';
import { useLocalNavigation } from '../hooks/useLocalNavigation';
import { NavigationButtons } from './NavigationButtons';
import { CommentModal } from './BillItems/components/Comment';
import './styles.scss';

export const ProviderDetails = () => {
  const [commentModal, setCommentModal] = React.useState(true);
  const [ignoreError, setIgnoreError] = React.useState(false);
  const [formValue, setFormValue] = React.useState();

  const { claim } = useClaim();
  const [updateProvider] = useUpdateProviderMutation();
  const { goToNextStep } = useLocalNavigation();

  const onSubmitHandler = async (values) => {
    try {
      await updateProvider({ claimId: claim.claimId, provider: values });
      goToNextStep();
    } catch (error) {
      console.error(error);
    }
  };

  const onNPIValueChange = async (values) => {
    try {
      await updateProvider({ claimId: claim.claimId, provider: values });
      setCommentModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNPI = (value) => {
    onNPIValueChange({
      ...formValue,
      billing: {
        ...formValue?.billing,
        npi: value,
      },
    });
  };

  const debounce = useDebouncedCallback(
    (newValue) => handleNPI(newValue),
    1000
  );

  return (
    <>
      <FormContext
        initialValues={{
          ...claim?.provider,
          date: new Date(claim?.provider?.date).toLocaleDateString('en-US'),
        }}
        onSubmit={onSubmitHandler}
      >
        {({ values, handleChange }) => {
          setFormValue(values);
          return (
            <HTMLForm>
              <Card className="my-7 mx-11 px-6 py-5">
                <div className="mb-6 d-flex align-items-center">
                  <Heading className="mr-5">Provider details</Heading>
                </div>
                <div className="mb-4 d-flex align-items-center">
                  <Heading className="mr-5" size="s">
                    Referring
                  </Heading>
                </div>
                <Row>
                  <div className="mr-6 mb-6 provider-detail-qualifier">
                    <Label withInput>Qualifier</Label>
                    <FormInput
                      name="referring.qualifier"
                      type="text"
                      placeholder="Qualifier"
                      minWidth="0px"
                    />
                  </div>
                  <Column className="mr-6 mb-6">
                    <Label withInput>Provider Name</Label>
                    <FormInput
                      name="referring.name"
                      type="text"
                      placeholder="Provider Name"
                    />
                  </Column>
                  <Column className="mr-6 mb-6">
                    <Label withInput>NPI</Label>
                    <FormInput
                      name="referring.npi"
                      type="text"
                      placeholder="NPI"
                    />
                  </Column>
                </Row>
                <Row className="mb-5">
                  <Column className="mr-6 mb-6">
                    <Label withInput>NPI Type</Label>
                    <FormInput
                      name="referring.npiType"
                      type="text"
                      placeholder="NPI Type"
                      disabled
                    />
                  </Column>
                  <Column className="mr-6 mb-6">
                    <Label withInput>Specialty Code</Label>
                    <FormInput
                      name="referring.specialtyCode"
                      type="text"
                      placeholder="Specialty Code"
                      disabled
                    />
                  </Column>
                </Row>
                <div className="mb-4 d-flex align-items-center">
                  <Heading className="mr-5" size="s">
                    Performing
                  </Heading>
                </div>
                <Row>
                  <Column className="mr-6 mb-6">
                    <Label withInput>Provider Name</Label>
                    <FormInput
                      name="performing.name"
                      type="text"
                      placeholder="Provider Name"
                    />
                  </Column>
                  <Column className="mr-6 mb-6">
                    <Label withInput>NPI</Label>
                    <FormInput
                      name="performing.npi"
                      type="text"
                      placeholder="NPI"
                    />
                  </Column>
                </Row>
                <Row className="mb-5">
                  <Column className="mr-6 mb-6">
                    <Label withInput>NPI Type</Label>
                    <FormInput
                      name="performing.npiType"
                      type="text"
                      placeholder="NPI Type"
                      disabled
                    />
                  </Column>
                  <Column className="mr-6 mb-6">
                    <Label withInput>Specialty Code</Label>
                    <FormInput
                      name="performing.specialtyCode"
                      type="text"
                      placeholder="Specialty Code"
                      disabled
                    />
                  </Column>
                </Row>
                <div className="mb-4 d-flex align-items-center">
                  <Heading className="mr-5" size="s">
                    Billing
                  </Heading>
                </div>
                <Row className="mb-5">
                  <Column className="mr-6 mb-6">
                    <Label withInput>Provider Name</Label>
                    <FormInput
                      name="billing.name"
                      type="text"
                      placeholder="Provider Name"
                    />
                  </Column>
                  <Column className="mr-6 mb-6">
                    <Label withInput>NPI</Label>
                    <FormInput
                      name="billing.npi"
                      type="text"
                      placeholder="NPI"
                      onChange={(e) => {
                        handleChange(e);
                        debounce(e.target.value);
                      }}
                    />
                    {!values.billing.npi && !ignoreError && (
                      <Row className="justify-content-between mt-3">
                        <Caption error={!values.billing.npi}>
                          NPI# missing
                        </Caption>
                        <Text
                          size="small"
                          appearance="link"
                          className="cursor-pointer"
                          onClick={() => setCommentModal(false)}
                        >
                          Ignore
                        </Text>
                      </Row>
                    )}
                  </Column>
                </Row>
                <div className="mb-4 d-flex align-items-center">
                  <Heading className="mr-5" size="s">
                    Address
                  </Heading>
                </div>
                <Row>
                  <Column className="mr-6 mb-6">
                    <Label withInput>Street</Label>
                    <FormInput
                      name="addressLine"
                      type="text"
                      placeholder="Street"
                    />
                  </Column>
                  <div className="mr-6 mb-6 provider-detail-city">
                    <Label withInput>City</Label>
                    <FormInput
                      name="city"
                      type="text"
                      placeholder="City"
                      minWidth="0px"
                    />
                  </div>
                  <div className="mr-6 mb-6 provider-detail-state">
                    <Label withInput>State</Label>
                    <FormDropdown
                      name="state"
                      placeholder="State"
                      options={[]}
                    />
                  </div>
                  <div className="mr-6 mb-6 provider-detail-zip">
                    <Label withInput>ZIP Code</Label>
                    <FormInput
                      name="zipcode"
                      type="text"
                      placeholder="ZIP Code"
                      minWidth="0px"
                    />
                  </div>
                </Row>
                <Row>
                  <Column className="mr-6 mb-6">
                    <Label withInput>Phone</Label>
                    <FormInput name="phone" type="tel" placeholder="Phone" />
                  </Column>
                  <Column className="mr-6 mb-6">
                    <Label withInput>Signature</Label>
                    <FormInput
                      name="signature"
                      type="text"
                      placeholder="Signature"
                    />
                  </Column>
                  <div className="mr-6 mb-6 city">
                    <Label withInput>Date</Label>
                    <FormDatePicker
                      name="date"
                      withInput
                      inputOptions={{
                        placeholder: 'MM/DD/YYYY',
                        icon: 'event',
                        mask: DATE_MASK,
                        minWidth: '0px',
                        name: 'date',
                      }}
                    />
                  </div>
                </Row>
              </Card>
              <NavigationButtons showPreviousBtn />
            </HTMLForm>
          );
        }}
      </FormContext>
      <CommentModal
        commentModal={commentModal}
        setCommentModal={setCommentModal}
        onClick={() => {
          setCommentModal(true);
          setIgnoreError(!ignoreError);
        }}
      />
    </>
  );
};
