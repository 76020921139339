/* eslint no-underscore-dangle: 0 */
import React from 'react';
import {
  Button,
  Card,
  Heading,
  Text,
  Icon,
  Row,
  Input,
  Sidesheet,
} from '@innovaccer/design-system';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import {
  useUpdateClaimItemsMutation,
  useDeleteEditMutation,
} from 'src/features/claims/store/api';
import classNames from 'classnames';
import { EditDetails } from './EditDetails';
import { Modifier } from './Modifier';
import { NDC } from './NDC';
import { Table } from '../table';
import styles from '../styles.module.scss';

const GridData = ({ alpha }) => (
  <div className="d-flex w-50 align-items-center m-4">
    <Text className="pr-4" weight="medium">
      {alpha}
    </Text>
    <Input
      className="w-auto pl-4 grid-data-input"
      minWidth={70}
      value="K03.2"
    />
  </div>
);

export const CorrectionDetails = ({
  setActivities,
  setCorrection,
  setOpenModal,
  setHide,
  hide,
  updateItem,
  correction,
  claim,
  claimId,
  setCommentModal,
  disabled,
  setDisable,
  disableCharges,
  setDisableCharges,
}) => {
  const [nestedData, setNestedData] = React.useState(claim?.items);
  const [dirtyRows, setDirtyRows] = React.useState([]);
  const [isModifierOpen, setIsModifierOpen] = React.useState(false);
  const [isNDCOpen, setIsNDCOpen] = React.useState(false);

  const [updateClaimItems] = useUpdateClaimItemsMutation();
  const [deleteEdit] = useDeleteEditMutation();

  const handleOnSubmit = async () => {
    await updateClaimItems({
      id: claim?._id,
      items: nestedData,
    });
    const claimEditsId = claim?.edits.filter((items) =>
      dirtyRows.includes(items.claimLineId)
    );

    const handleDelete = async (values) => {
      try {
        await deleteEdit({
          id: values,
        });
      } catch (error) {
        // TODO: Implement error handling
        console.error(error);
      }
    };

    claimEditsId.forEach((item) => {
      handleDelete(item._id);
    });
    setDisable(true);
    setCommentModal(false);
  };

  const handleIsDirtyState = (claimLine) => {
    if (!dirtyRows.includes(claimLine.claimLineId)) {
      setDirtyRows(dirtyRows.concat(claimLine.claimLineId));
    }
  };

  const addNewClaim = () => {
    const currentDate = new Date().toLocaleDateString('en-US');
    setNestedData([
      ...nestedData,
      {
        billingProviderName: nestedData[0]?.billingProviderName,
        billingSpeciality: nestedData[0]?.billingSpeciality,
        charge: ' ',
        claimId: nestedData[0]?.claimId,
        claimLineId: nestedData[nestedData.length - 1].claimLineId + 1,
        cptCode: ' ',
        modifier: ' ',
        placeOfService: nestedData[0]?.placeOfService,
        serviceEndDate: currentDate,
        serviceStartDate: currentDate,
        units: ' ',
        diagnosisPointer: ' ',
        emergencyIndicator: ' ',
        epsdt: ' ',
        ndc: ' ',
      },
    ]);
    setDisable(!disabled);
    setDisableCharges(!disableCharges);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mx-4">
        <Heading>Corrections</Heading>
        <Row className="justify-content-end">
          <Text
            appearance="link"
            className={classNames(
              'cursor-pointer',
              styles.correctionActivities
            )}
            onClick={() => setActivities(true)}
          >
            Activities
          </Text>

          <Button
            appearance="primary"
            type="button"
            className="ml-6"
            onClick={() => setCorrection(!correction)}
          >
            Correction
          </Button>
        </Row>
      </div>
      <Card className="my-6">
        <div
          className={classNames(
            'd-flex mx-6 mt-4 mb-2',
            styles.correctionDiagnosisText
          )}
        >
          <div>
            <Text size="large" weight="strong" className="mr-6">
              Diagnosis or nature of illness or injury
            </Text>

            <Text>Relate A-L to service line below </Text>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Text
              appearance="link"
              onClick={() => setOpenModal(true)}
              className="cursor-pointer"
            >
              Reorder
            </Text>
            <Icon
              name={hide ? 'expand_more' : 'expand_less'}
              size={24}
              className="ml-6 cursor-pointer"
              onClick={() => setHide(!hide)}
            />
          </div>
        </div>
        {!hide && (
          <div className={classNames('w-auto', styles.gridData)}>
            <GridData alpha="A" />
            <GridData alpha="B" />
            <GridData alpha="C" />
            <GridData alpha="D" />
            <GridData alpha="E" />
            <GridData alpha="F" />
            <GridData alpha="G" />
            <GridData alpha="H" />
            <GridData alpha="I" />
            <GridData alpha="J" />
            <GridData alpha="K" />
            <GridData alpha="L" />
          </div>
        )}
      </Card>
      <Card className="mt-5" style={{ height: hide ? '88vh' : '72vh' }}>
        <Allotment vertical>
          <Allotment.Pane minSize={150}>
            <div
              style={{
                height: claim?.items.length > 6 ? '90%' : 'auto',
              }}
            >
              <Table
                claimId={claimId}
                updateItem={updateItem}
                billItem={claim?.items}
                correction={correction}
                disabled={disabled}
                setDisable={setDisable}
                data={claim}
                documents={claim?.documents}
                comments={claim?.comments}
                billItems={claim?.items}
                nestedData={nestedData}
                setNestedData={setNestedData}
                setIsModifierOpen={setIsModifierOpen}
                setIsNDCOpen={setIsNDCOpen}
                handleOnSubmit={handleOnSubmit}
                handleIsDirtyState={handleIsDirtyState}
                disableCharges={disableCharges}
                setDisableCharges={setDisableCharges}
                setCommentModal={setCommentModal}
              />
              <Row>
                {(!disabled || !disableCharges) && (
                  <Button className="ml-6" onClick={() => handleOnSubmit()}>
                    Save
                  </Button>
                )}
                <Button className="ml-6" onClick={() => addNewClaim()}>
                  Add new claim line
                </Button>
              </Row>
            </div>
            <Sidesheet
              open={isModifierOpen}
              backdropClose={false}
              onClose={() => setIsModifierOpen(false)}
              className="w-25"
            >
              <Modifier claim={claim} />
            </Sidesheet>
            <Sidesheet
              open={isNDCOpen}
              backdropClose
              onClose={() => setIsNDCOpen(false)}
              className="w-25 ndc-sidesheet"
              footer={
                <>
                  <Button
                    appearance="basic"
                    onClick={() => setIsNDCOpen(!isNDCOpen)}
                  >
                    Discard
                  </Button>
                  <Button
                    appearance="primary"
                    className="ml-4 mr-8"
                    onClick={() => {
                      setIsNDCOpen(!isNDCOpen);
                      setCommentModal(false);
                    }}
                  >
                    Save
                  </Button>
                </>
              }
            >
              <NDC claim={claim} />
            </Sidesheet>
          </Allotment.Pane>
          <Allotment.Pane preferredSize={45} minSize={45}>
            <EditDetails
              height={!hide ? '48vh' : '64vh'}
              correction={correction}
            />
          </Allotment.Pane>
        </Allotment>
      </Card>
    </div>
  );
};
