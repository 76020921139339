import { Checkbox } from '@innovaccer/design-system';
import styles from './legend.module.scss';

const LegendItem = ({ item, showCheck, onCheck }) => (
  <div className={styles.item}>
    <div className={styles.circle} style={{ backgroundColor: item.color }} />
    {showCheck ? (
      <Checkbox
        className={styles.checkbox}
        onChange={() => onCheck && onCheck(item.id)}
        checked={item.checked}
        bordercolor="gray.300"
        label={item.label}
      />
    ) : (
      <p className={styles.label}>{item.label}</p>
    )}
  </div>
);

export const Legend = ({ items, showCheck = false, onCheck = null }) => (
  <div direction="column">
    {items.map(({ id, label, color }) => (
      <LegendItem
        key={id}
        item={{ id, label, color }}
        showCheck={showCheck}
        onCheck={onCheck}
      />
    ))}
  </div>
);
