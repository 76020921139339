import React from 'react';
import { Text, Column, Message, Divider } from '@innovaccer/design-system';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import classNames from 'classnames';
import { CommentModal } from './Comment';
import { CorrectionDetails } from './CorrectionDetails';
import { Reorder } from './Reorder';
import { Activities } from './Activities';
import { CorrectionDocuments } from '../../documents/CorrectionDocuments';
import styles from '../styles.module.scss';

export const Correction = ({
  correction,
  claim,
  setCorrection,
  disabled,
  claimId,
  updateItem,
  setDisable,
  activities,
  setActivities,
  disableCharges,
  setDisableCharges,
}) => {
  const [hide, setHide] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [commentModal, setCommentModal] = React.useState(true);
  const [anchorPoint, setAnchorPoint] = React.useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = React.useState(false);

  const handleContextMenu = React.useCallback(
    (event) => {
      event.preventDefault();
      setAnchorPoint({ x: event.pageX, y: event.pageY });
      setShowMenu(true);
    },
    [setAnchorPoint]
  );

  const handleClick = React.useCallback(
    () => (showMenu ? setShowMenu(false) : null),
    [showMenu]
  );

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  });

  return (
    <div className="d-flex vh-100">
      <Allotment>
        <Allotment.Pane>
          <div className="w-100 h-100">
            <Column className="flex-auto h-100">
              {claim?.documents ? (
                <CorrectionDocuments documents={claim?.documents} />
              ) : (
                <Message
                  className="mt-5"
                  appearance="warning"
                  description="No documents found"
                />
              )}
            </Column>
          </div>
        </Allotment.Pane>
        <Allotment.Pane>
          {activities ? (
            <Activities
              claim={claim}
              setActivities={setActivities}
              correction={correction}
            />
          ) : (
            <CorrectionDetails
              hide={hide}
              setActivities={setActivities}
              setCorrection={setCorrection}
              setHide={setHide}
              setOpenModal={setOpenModal}
              updateItem={updateItem}
              correction={correction}
              claim={claim}
              claimId={claimId}
              setCommentModal={setCommentModal}
              disabled={disabled}
              setDisable={setDisable}
              disableCharges={disableCharges}
              setDisableCharges={setDisableCharges}
            />
          )}
        </Allotment.Pane>
      </Allotment>
      <Reorder openModal={openModal} setOpenModal={setOpenModal} />
      <CommentModal
        commentModal={commentModal}
        setCommentModal={setCommentModal}
        modalClassName="mr-13"
        onClick={() => setCommentModal(true)}
      />
      {showMenu && (
        <div
          className={classNames(
            'd-flex flex-column py-4 px-4',
            styles.contextMenu
          )}
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x,
          }}
        >
          <Text
            className="my-2 py-4 cursor-pointer"
            weight="strong"
            size="16"
            onClick={() => {
              setDisable(!disabled);
              setDisableCharges(!disableCharges);
            }}
          >
            Correct
          </Text>
          <Divider />
          <Text
            className="my-2 py-4 cursor-pointer"
            weight="strong"
            size="16"
            onClick={() => setDisableCharges(!disableCharges)}
          >
            Adjust $
          </Text>
          <Divider />
          <Text className="my-2 py-4 cursor-pointer" weight="strong" size="16">
            Void
          </Text>
          <Divider />
          <Text weight="strong" size="16" className="mt-4 py-2 cursor-pointer">
            Pend
          </Text>
        </div>
      )}
    </div>
  );
};
