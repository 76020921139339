import { PaymentVarianceIN, PaymentVarianceINDetail } from 'src/pages';
import { ROUTES } from '../route-names';

export const PAYMENT_VARIANCE_IN = [
  {
    path: ROUTES.paymentVarianceIN,
    element: PaymentVarianceIN,
  },
  {
    path: `${ROUTES.paymentVarianceIN}/:id/*`,
    element: PaymentVarianceINDetail,
  },
];
