/* eslint-disable import/no-import-module-exports */
import { configureStore } from '@reduxjs/toolkit';
import { claimsApi } from 'src/features/claims/store/api';
import { ROOT_REDUCER } from './reducers';

export const configureAppStore = (preloadedState) => {
  const store = configureStore({
    reducer: ROOT_REDUCER,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        claimsApi.middleware
      ),
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(ROOT_REDUCER));
  }

  return store;
};
