export const data = [
  {
    label: 'Benign lesion excision (11400-11446)',
    value: 34,
  },
  {
    label: 'Total knee replacement (27447) ',
    value: 27,
  },
  {
    label: 'Breast reduction (19318)',
    value: 25,
  },
  {
    label: 'EGD with biopsy (43239)',
    value: 23,
  },
  {
    label: 'Gastric restrictive procedure (43846)',
    value: 20,
  },
  {
    label: 'Spine surgery grafts (20930,20931, 20936-20939)',
    value: 18,
  },
  {
    label: 'Arthroscopic femoroplasty (29914)',
    value: 16,
  },
  {
    label: 'Arthroscopic SAD (29826)',
    value: 11,
  },
  {
    label: 'Stereotactic CA navigation (61782)',
    value: 6,
  },
  {
    label: 'Vaginal hysterectomy (58260)',
    value: 3,
  },
].reverse();
