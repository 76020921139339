import React from 'react';
import { Heading, Text, Icon, Input } from '@innovaccer/design-system';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CommentModal } from './Comment';
import styles from '../styles.module.scss';

const mockData = [
  {
    id: 1,
    title: '',
  },
  {
    id: 2,
    title: '',
  },
  {
    id: 3,
    title: '',
  },
  {
    id: 4,
    title: '',
  },
];

export const Modifier = () => {
  const [modifierItems, setModifierItems] = React.useState(mockData);
  const [showCommentModal, setShowCommentModal] = React.useState(true);

  const handleDeleteItems = (item) => {
    const filteredValue = modifierItems.filter((value) => value.id !== item.id);
    setModifierItems(filteredValue);
    setShowCommentModal(false);
  };

  const onDragEnd = (result) => {
    const newModifiedItems = modifierItems;
    const [removed] = newModifiedItems.splice(result.source.index, 1);
    newModifiedItems.splice(result.destination.index, 0, removed);
    setModifierItems(newModifiedItems);
    setShowCommentModal(false);
  };
  const ModifierList = ({ item, handleDeleteItems, provided, snapshot }) => (
    <div
      ref={provided.innerRef}
      snapshot={snapshot}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div
        className={classNames(
          'd-flex flex-row align-items-center mb-6',
          styles.modifierList
        )}
      >
        <Icon
          name="drag_indicator"
          appearance="subtle"
          className="mx-2 my-4"
          size="22"
        />
        <Text className="mr-4" weight="medium">
          {/* {item.id} */}
        </Text>
        <Text
          appearance="subtle"
          className={classNames('px-3', styles.modifierListText)}
          size="18"
        >
          {item.title.substring(0, 28)}
          {item.title.length >= 28 && '...'}
        </Text>
        <Icon
          name="delete"
          type="outline"
          className={classNames('px-4 cursor-pointer', styles.deleteIcon)}
          size={22}
          appearance="subtle"
          onClick={() => handleDeleteItems(item)}
        />
      </div>
    </div>
  );
  return (
    <div>
      <Heading className="mb-6">Modifier</Heading>
      <div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className={classNames('vh-50 mb-5', styles.modifierDroppable)}
                  style={{
                    height: mockData.length > 4 && '350px',
                  }}
                >
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {modifierItems.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={String(item.id)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <ModifierList
                            item={item}
                            handleDeleteItems={handleDeleteItems}
                            provided={provided}
                            snapshot={snapshot}
                          />
                        )}
                      </Draggable>
                    ))}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Input icon="search" placeholder="Search Term" className="my-6" />
      </div>
      <CommentModal
        commentModal={showCommentModal}
        setCommentModal={setShowCommentModal}
        onClick={() => {
          setShowCommentModal(!showCommentModal);
        }}
      />
    </div>
  );
};
