import {
  Caption,
  Dropdown as DropdownComponent,
} from '@innovaccer/design-system';
import { ErrorMessage, Field } from 'formik';
import React from 'react';

/**
 *
 * @param {import('formik').FieldConfig & import("@innovaccer/design-system").DropdownProps} props
 * @returns
 */
export const Dropdown = (props) => (
  <>
    <Field name={props.name}>
      {({ field, form }) => {
        const selected = props.options.filter((i) => i.value === field.value);
        const handleOnChange = (type, option) => {
          // Handle other events if required
          if (type === 'select-option') {
            form.setFieldValue(field.name, option.value);
          }
        };

        return (
          <DropdownComponent
            {...props}
            name={field.name}
            selected={selected?.length === 1 ? selected : []}
            onUpdate={handleOnChange}
          />
        );
      }}
    </Field>
    <ErrorMessage name={props.name} component={Caption} withInput error />
  </>
);
