import { Text } from '@innovaccer/design-system';
import React from 'react';
import styles from './styles.module.scss';

export const MetaListCell = ({
  title,
  meta,
  appearance = 'subtle',
  icon = null,
}) => (
  <div className={styles.container}>
    <div className="d-flex align-items-center">
      <Text weight="medium">{title}</Text>
      {icon}
    </div>
    <Text
      weight="medium"
      appearance={appearance}
      size="small"
      className={styles.truncate}
    >
      {meta}
    </Text>
  </div>
);
