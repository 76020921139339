import {
  Caption,
  DatePicker as DatePickerComponent,
} from '@innovaccer/design-system';
import { ErrorMessage, Field } from 'formik';
import React from 'react';

/**
 *
 * @param {import("formik").FieldConfig & import("@innovaccer/design-system").DatePickerProps} props
 */
export const DatePicker = (props) => (
  <>
    <Field name={props.name}>
      {({ field, form }) => {
        const handleChange = (date) => {
          form.setFieldValue(field.name, date);
        };
        return (
          <span className={props.disabled && 'fi-disabled'}>
            <DatePickerComponent
              {...props}
              date={field.value}
              withInput
              onDateChange={handleChange}
            />
          </span>
        );
      }}
    </Field>
    <ErrorMessage name={props.name} component={Caption} withInput error />
  </>
);
