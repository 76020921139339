import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Layout } from 'src/components';
import { PatientSummaryBar, Header } from './blocks';
import { useClaim } from './hooks/useClaimDetails';
import { useLocalNavigation } from './hooks/useLocalNavigation';

export const ClaimDetail = () => {
  const { loading } = useClaim();
  const { localRoutes } = useLocalNavigation();

  return (
    <Layout showSideBar={false}>
      {/* TODO: Add a loader in future */}
      {!loading && (
        <>
          <Header />
          <PatientSummaryBar />
          <Routes>
            {localRoutes.map(({ path, element }) => (
              <Route path={path} element={element()} key={path} />
            ))}
          </Routes>
        </>
      )}
      <ToastContainer
        position="bottom-left"
        hideProgressBar
        className="toast-outline"
      />
    </Layout>
  );
};
