import { useEffect } from 'react';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
import Auth0 from '../services/auth0';

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

const AuthenticationSetup = () => {
  const auth0Client = useAuth0();

  useEffect(() => {
    Auth0.setClient(auth0Client);
  }, [auth0Client]);

  return null;
};

export const Authentication = ({ children }) => (
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    audience={REACT_APP_AUTH0_AUDIENCE}
    redirectUri={window.location.origin}
    useRefreshTokens
  >
    <AuthenticationSetup />
    {children}
  </Auth0Provider>
);
