import { MissedCharges, MissedChargesDetail } from 'src/pages';
import { ROUTES } from '../route-names';

export const MISSED_CHARGES = [
  {
    path: ROUTES.missedCharges,
    element: MissedCharges,
  },
  {
    path: `${ROUTES.missedCharges}/:id/*`,
    element: MissedChargesDetail,
  },
];
