/**
 * Creates a compare function which accepts objects and compares by
 * given property key
 * @param {string} comparatorKey Property key to be used for comparison
 * @returns Compare result
 */
export const getObjectComparator = (comparatorKey) => (a, b) => {
  const leftValue = a[comparatorKey];
  const rightValue = b[comparatorKey];
  if (leftValue < rightValue) return -1;
  if (leftValue > rightValue) return 1;
  return 0;
};
