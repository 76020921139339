import { ResponsiveBar } from '@nivo/bar';
import { ChartContainer } from '../../chart-container';
import { VerticalAxisTick } from '../verticle-tick';
import { data } from './data';

const color = '#4F9DE7';

export const TopEditsBySectionChart = () => (
  <ChartContainer title="Top 10 Edits by Section" showTypeSelection>
    <ResponsiveBar
      data={data}
      indexBy="label"
      layout="horizontal"
      innerPadding={6}
      enableGridY={false}
      margin={{ top: 20, right: 100, bottom: 50, left: 350 }}
      padding={0.5}
      colors={color}
      axisBottom={{
        tickSize: 0,
      }}
      axisLeft={{
        tickSize: 0,
        renderTick: VerticalAxisTick,
      }}
      enableLabel={false}
    />
  </ChartContainer>
);
