export const data = [
  {
    label: 'Surgery',
    value: 3456,
    valueColor: '#FE5FC8',
  },
  {
    label: 'Chemotherapy Infusions',
    value: 2764,
    valueColor: '#4F9DE7',
  },
  {
    label: 'Radiology',
    value: 2534,
    valueColor: '#4F9DE7',
  },
  {
    label: 'Labs and Pathology',
    value: 2304,
    valueColor: '#4F9DE7',
  },
  {
    label: 'Therapy',
    value: 2073,
    valueColor: '#4F9DE7',
  },
  {
    label: 'DME',
    value: 1843,
    valueColor: '#4F9DE7',
  },
  {
    label: 'Anesthesia',
    value: 1612,
    valueColor: '#4F9DE7',
  },
  {
    label: 'Pain Management',
    value: 1152,
    valueColor: '#4F9DE7',
  },
  {
    label: 'Psychotherapy',
    value: 691,
    valueColor: '#4F9DE7',
  },
  {
    label: 'Ophthalmology',
    value: 345,
    valueColor: '#4F9DE7',
  },
].reverse();
