import { Avatar, Badge, Divider, Text } from '@innovaccer/design-system';
import React, { useMemo } from 'react';
import { Capitalize } from 'src/common/utils';
import { differenceInYears } from 'date-fns';
import { useClaim } from '../hooks/useClaimDetails';
import './styles.scss';

const ValuePair = ({ label, value }) => (
  <div className="d-flex flex-column mr-9 value-pair-column-gap">
    <Text appearance="subtle">{label}</Text>
    <Text>{value}</Text>
  </div>
);

export const PatientSummaryBar = () => {
  const { claim } = useClaim();

  const {
    claimId,
    serviceStartDate,
    serviceEndDate,
    insurer,
    patient: { firstName, lastName, dateOfBirth, gender },
  } = claim;
  const detailsSummary = useMemo(
    () => [
      {
        label: 'Age',
        value:
          dateOfBirth &&
          new Date(dateOfBirth) &&
          `${differenceInYears(new Date(), new Date(dateOfBirth))} y`,
      },
      {
        label: 'Gender',
        value: gender && Capitalize(gender),
      },
      { label: 'Claim ID', value: claimId },
      { label: 'DRG', value: null },
      { label: 'Weight', value: null },
      { label: 'Discharge Disposition', value: null },
      {
        label: 'Admit Date',
        value: new Date(serviceStartDate).toLocaleDateString('en-US'),
      },
      {
        label: 'Discharge Date',
        value: new Date(serviceEndDate).toLocaleDateString('en-US'),
      },
      { label: 'Attending Provider', value: null },
      { label: 'Insurance', value: insurer },
    ],
    [claim]
  );

  return (
    <div>
      <div className="d-flex align-items-center bg-light px-6 py-4">
        <div className="d-flex align-items-center">
          <Avatar firstName={firstName} lastName={lastName} className="mr-5" />
          <div className="d-flex flex-column mr-9">
            <Text weight="strong">
              {firstName} {lastName}
            </Text>
            <Badge appearance="accent2" subtle>
              IN-PATIENT
            </Badge>
          </div>
        </div>
        {detailsSummary.map((item) => (
          <ValuePair key={item.label} {...item} />
        ))}
      </div>
      <Divider />
    </div>
  );
};
