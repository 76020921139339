import {
  Card,
  CardHeader,
  Heading,
  Label,
  Text,
  Column,
  Row,
  Icon,
  Message,
  Button,
} from '@innovaccer/design-system';
import {
  FormContext,
  HTMLForm,
  FormDropdown,
  FormInput,
} from 'src/components/form';
import { NavigationButtons } from './NavigationButtons';
import { useLocalNavigation } from '../hooks/useLocalNavigation';
import './styles.scss';

const Badges = () => (
  <Row className="mt-5">
    <Text className="mr-4 drg-details-badges-text-CC" weight="strong">
      CC
    </Text>
    <Text className="mx-4 drg-details-badges-text-MCC" weight="strong">
      MCC
    </Text>
    <Text className="mx-4 drg-details-badges-text-HAC" weight="strong">
      HAC
    </Text>
  </Row>
);

const POAIndicator = ({ name }) => (
  <div className="d-flex align-items-center justify-content-center py-2 drg-details-POA-indicator">
    <Text weight="strong" size="small">
      {name}
    </Text>
    <Icon name="expand_more" className="ml-3 cursor-pointer" />
  </div>
);

const DiagnosisCard = ({
  heading,
  subHeading,
  indicatorName,
  showPOAIndicator,
}) => (
  <div className="p-4 mt-4 drg-details-diagnosis-card">
    <Column>
      <Row className="w-100 justify-content-between mb-6 mt-3">
        <Text appearance="subtle" weight="strong" size="small">
          {heading}
        </Text>
        <Icon name="edit" className="cursor-pointer" onClick={() => null} />
      </Row>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <Text size="regular" weight="strong">
          {subHeading}
        </Text>
        {showPOAIndicator && <POAIndicator name={indicatorName} />}
      </div>

      <Badges />
    </Column>
  </div>
);

const SecondaryDiagosisCard = ({ poaName, heading, summary }) => (
  <div className="p-4 drg-details-secondary-diagnosis-card">
    <Row className="align-items-center justify-content-between mt-4">
      <Icon
        name="drag_handle"
        appearance="subtle"
        size={22}
        className="cursor-pointer"
      />
      <div className="drg-details-secondary-diagnosis-card-text">
        <Text weight="strong">{heading}</Text>
        <Text weight="strong" size="regular" className="ml-4">
          {summary}
        </Text>
      </div>

      <POAIndicator name={poaName} />
      <div className="d-flex align-items-center ">
        <Icon name="edit" className="mr-6 cursor-pointer" />
        <Icon name="delete" className="mx-4 cursor-pointer" />
      </div>
    </Row>
    <Badges />
  </div>
);

const ProcedureCard = ({
  heading,
  subHeading,
  procedureDate,
  operatingPhysician,
}) => (
  <div className="p-4 drg-details-procedure-card mb-5">
    <Row className="justify-content-between align-items-center mb-6 mt-3">
      <Text size="small" appearance="subtle" weight="strong">
        {heading}
      </Text>
      <div className="d-flex align-items-center ">
        <Icon name="edit" className="mr-6 cursor-pointer" />
        <Icon name="delete" className="mx-4 cursor-pointer" />
      </div>
    </Row>
    <Text weight="strong" size="regular">
      {subHeading}
    </Text>
    <Row className="mb-4 mt-6 justify-content-between align-items-center drg-details-procedure-date">
      <Text weight="strong" size="regular" appearance="subtle">
        Procedure Date
      </Text>
      <Text>{procedureDate}</Text>
    </Row>
    <Row className="mb-4 mt-6 justify-content-between align-items-center drg-details-operating-physician">
      <Text weight="strong" size="regular" appearance="subtle">
        Operating Physician
      </Text>
      <Text>{operatingPhysician}</Text>
    </Row>
  </div>
);

export const DRGDetails = () => {
  const { goToNextStep } = useLocalNavigation();
  return (
    <>
      <FormContext>
        <HTMLForm className="d-flex align-items-center justify-content-center">
          <div className="drg-details">
            <Card className="my-7 mx-10 px-6 py-5">
              <CardHeader>
                <Heading size="s">Diagnosis Codes</Heading>
                <div className="d-flex flex-row my-6">
                  <div className=" d-flex flex-row align-items-center justify-content-start drg-details-card-header">
                    <Label withInput className="mr-6">
                      ICD Version Indicator
                    </Label>

                    <div className="drg-details-dropdown">
                      <FormDropdown
                        name="icdVersion"
                        options={[]}
                        disabled
                        maxWidth={0}
                      />
                    </div>
                  </div>
                  <div className=" d-flex flex-row w-50 align-items-center justify-content-end">
                    <Label withInput className="mr-6">
                      DRG Code
                    </Label>

                    <div className="drg-details-dropdown">
                      <FormInput
                        name="drgCode"
                        type="text"
                        minWidth="0px"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <DiagnosisCard
                heading="ADMIT DIAGNOSIS"
                subHeading="O1002: Pre-existing essential hypertension complicating childbirth"
                indicatorName="Y: DIAGNOSIS PRESENT"
                showPOAIndicator={false}
              />
              <DiagnosisCard
                heading="PRINCIPAL DIAGNOSIS"
                subHeading="O1002: Pre-existing essential hypertension complicating childbirth"
                indicatorName="Y: DIAGNOSIS PRESENT"
                showPOAIndicator
              />
              <Column className="mt-4 drg-details-column">
                <div className="d-flex flex-column p-4">
                  <Text
                    appearance="subtle"
                    weight="strong"
                    size="small"
                    className="my-3"
                  >
                    SECONDARY DIAGNOSIS
                  </Text>
                  <Text
                    appearance="destructive"
                    weight="medium"
                    className="preserve-whitespace my-3"
                  >
                    EDIT 1: Code Z37.XX is missing
                  </Text>
                  <Message
                    appearance="warning"
                    description="Potential Denial reason code A8: Ungroupable DRG"
                    actions={
                      <Text appearance="link" className="cursor-pointer">
                        Ignore error
                      </Text>
                    }
                  />
                  <Row className="align-items-center mt-3">
                    <Text
                      appearance="subtle"
                      weight="strong"
                      size="small"
                      className="my-3"
                    >
                      SUGGESTION:{' '}
                    </Text>
                    <Text appearance="subtle" className="my-3 ml-3">
                      Review the medical record and add the outcome of delivery
                      and re-compute the DRG code
                    </Text>
                  </Row>
                </div>
                <SecondaryDiagosisCard
                  poaName="Y: DIAGNOSIS PRESENT"
                  heading="O779:"
                  summary="Labor and delivery complicated by fetal stress"
                />
                <SecondaryDiagosisCard
                  poaName="1: EXEMPT REPORTING"
                  heading="Z3A38:"
                  summary="38 weeks gestation of pregnancy"
                />
                <div className="drg-details-add-diagnosis">
                  <Button icon="add" className="my-5 ml-4" disabled>
                    Add diagnosis
                  </Button>
                </div>
              </Column>
            </Card>
            <Card className="my-7 mx-10 px-6 py-5">
              <CardHeader>
                <Heading size="s">PCS Codes</Heading>
              </CardHeader>
              <ProcedureCard
                heading="PRINCIPAL PCS CODE"
                subHeading="10E0XZZ: Delivery of products of conception, external approach"
                procedureDate="05/04/2021"
                operatingPhysician="Dr. Sharon Fuller (0987654321)"
              />
              <ProcedureCard
                heading="OTHER PROCEDURE"
                subHeading="10E0XZZ: Delivery of products of conception, external approach"
                procedureDate="05/04/2021"
                operatingPhysician="Dr. Sharon Fuller (0987654321)"
              />
              <ProcedureCard
                heading="OTHER PROCEDURE"
                subHeading="10E0XZZ: Delivery of products of conception, external approach"
                procedureDate="05/04/2021"
                operatingPhysician="Dr. Sharon Fuller (0987654321)"
              />
              <Button icon="add" disabled className="my-7">
                Add Procedure
              </Button>
              <div className="drg-details-diagnosis-card p-4 mb-4">
                <Row className="justify-content-between align-items-center mb-6 mt-3">
                  <Text size="small" appearance="subtle" weight="strong">
                    DISCHARGE DISPOSITION
                  </Text>

                  <Icon name="edit" className="mr-6 cursor-pointer" />
                </Row>
                <FormDropdown
                  name="dischargeDisposition"
                  options={[]}
                  minWidth={0}
                  className="w-75 mb-8"
                  disabled
                />
              </div>
              <div className="d-flex flex-row justify-content-end my-8">
                <Button type="button" className="drg-details-validate">
                  Validate
                  <span className="material-icons-outlined ml-3">refresh</span>
                </Button>
                <Button type="button" appearance="basic" className="mx-8">
                  Discard
                  <span className="material-icons-outlined ml-3">refresh</span>
                </Button>
                <Button
                  type="button"
                  appearance="primary"
                  className="drg-details-save"
                  onClick={() => null}
                >
                  Save
                  <span className="material-icons-outlined ml-3">refresh</span>
                </Button>
              </div>
            </Card>
          </div>
        </HTMLForm>
      </FormContext>
      <NavigationButtons showPreviousBtn onNextClick={() => goToNextStep()} />
    </>
  );
};
