import { Input, Text, Button } from '@innovaccer/design-system';
import React from 'react';
import styles from './styles.module.scss';

export const ListCell = ({
  title,
  meta,
  appearance = 'subtle',
  icon = null,
  name,
  disabled = true,
  onChange,
  hasError,
  maxLength,
}) => (
  <div className={styles.container}>
    <div
      className="d-flex align-items-center"
      style={{
        border: hasError && '1px solid #EE0E0E',
        borderRadius: hasError && '4px',
      }}
    >
      <Input
        name={name}
        defaultValue={title}
        minWidth={0}
        disabled={disabled}
        className="border-0"
        onChange={onChange}
        maxLength={maxLength}
      />
      {icon}
    </div>
    <Text
      weight="medium"
      appearance={appearance}
      size="small"
      className={styles.truncate}
    >
      {meta}
    </Text>
  </div>
);

export const InputCell = ({
  name,
  value,
  disabled,
  onChange,
  hasError,
  onClick,
  maxLength,
}) => (
  <div
    className="d-flex align-items-center"
    style={{
      border: hasError && '1px solid #EE0E0E',
      borderRadius: hasError && '4px',
    }}
  >
    <Input
      name={name}
      defaultValue={value}
      minWidth={0}
      disabled={disabled}
      className="border-0"
      onChange={onChange}
      onClick={onClick}
      maxLength={maxLength}
    />
  </div>
);

export const ButtonCell = ({
  name,
  value,
  disabled,
  onChange,
  hasError,
  onClick,
  appearance,
}) => (
  <div
    className="d-flex align-items-center"
    style={{
      border: hasError && '1px solid #EE0E0E',
      borderRadius: hasError && '4px',
    }}
  >
    <Button
      name={name}
      defaultValue={value}
      disabled={disabled}
      className="border-0"
      onChange={onChange}
      onClick={onClick}
      appearance={appearance}
      style={{
        width: '150px',
      }}
    >
      {value}
    </Button>
  </div>
);
