import { useCallback, useState } from 'react';

export const useLegendFilter = (
  legendItems,
  data,
  filterFn,
  columnKey = 'label'
) => {
  const [selected, setSelected] = useState(legendItems);

  // If none is checked use data as is
  // Otherwise filter with the selected ones
  const filteredData = selected.every((k) => !k.checked)
    ? data
    : filterFn(data, selected, columnKey);

  const handleFilter = useCallback(
    (id) => {
      const itemIndex = selected.findIndex((k) => k.id === id);
      if (itemIndex > -1) {
        const updated = [...selected];
        updated[itemIndex].checked = !updated[itemIndex].checked;
        setSelected(updated);
      }
    },
    [selected, setSelected]
  );

  return {
    selected,
    filteredData,
    handleFilter,
  };
};
