import React from 'react';

export const CoverselfLogoWithText = ({ classname }) => (
  <svg
    width="103"
    height="18"
    viewBox="0 0 162 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="#314259"
    className={classname}
  >
    <path
      d="M55.1876 19.2722C55.3008 19.5546 55.4515 19.837 55.6207 20.1199C55.7339 20.3082 55.847 20.4779 55.9602 20.6286L56.2616 20.2516L55.1876 19.2722Z"
      fill="currentColor"
    />
    <path
      d="M50.5999 22.7842C49.3567 23.3115 47.9818 23.5944 46.4557 23.5944C44.6662 23.5944 43.046 23.1988 41.5955 22.4262C41.256 22.238 40.9361 22.0497 40.6156 21.8234C39.6172 21.1264 38.8071 20.2412 38.1667 19.1863C37.3376 17.8109 36.9235 16.2472 36.9235 14.4953C36.9235 12.7434 37.3376 11.1987 38.1667 9.80433C38.8071 8.74937 39.6172 7.86414 40.6156 7.16718C40.917 6.94088 41.256 6.75261 41.5955 6.56435C43.046 5.79179 44.6847 5.39624 46.4932 5.39624C48.0193 5.39624 49.3947 5.6601 50.638 6.20636C51.8622 6.73359 52.8986 7.50615 53.7463 8.52355L51.1276 10.9349C49.941 9.55949 48.4524 8.88155 46.7005 8.88155C45.608 8.88155 44.6472 9.12639 43.7805 9.59704C42.9328 10.0682 42.2544 10.7466 41.7837 11.5943C41.3126 12.442 41.0678 13.4213 41.0678 14.5143C41.0678 15.6068 41.3126 16.5862 41.7837 17.4339C42.2544 18.282 42.9328 18.96 43.7805 19.4311C44.6282 19.9018 45.608 20.1466 46.7005 20.1466C48.4524 20.1466 49.941 19.4496 51.1276 18.0557L53.7463 20.467C52.8796 21.4654 51.8436 22.238 50.5999 22.7842Z"
      fill="currentColor"
    />
    <path
      d="M68.6164 12.92C67.976 11.8651 67.1093 11.0364 65.9787 10.4521C64.8487 9.84929 63.5864 9.56689 62.1545 9.56689C60.723 9.56689 59.4607 9.86831 58.3302 10.4521C57.2001 11.055 56.3149 11.8651 55.674 12.92C55.0336 13.975 54.7132 15.1806 54.7132 16.518C54.7132 17.5169 54.8829 18.4396 55.2409 19.2688C55.3541 19.5512 55.5048 19.8336 55.674 20.1165C55.7872 20.3047 55.9003 20.4744 56.013 20.6252C56.6158 21.4353 57.3884 22.0942 58.3302 22.5844C59.4607 23.1872 60.7415 23.4696 62.1545 23.4696C63.5864 23.4696 64.8487 23.1682 65.9787 22.5844C67.1093 21.9815 67.976 21.1714 68.6164 20.0974C69.2568 19.0425 69.5772 17.8368 69.5772 16.4995C69.5772 15.1616 69.2568 13.975 68.6164 12.92ZM64.6224 19.2307C63.982 19.9092 63.1533 20.2481 62.1359 20.2481C61.1376 20.2481 60.3084 19.9092 59.649 19.2307C58.9896 18.5528 58.6697 17.6485 58.6697 16.4995C58.6697 15.3689 58.9896 14.4461 59.649 13.7677C60.3084 13.0898 61.1376 12.7503 62.1359 12.7503C63.1343 12.7503 63.963 13.0898 64.6224 13.7677C65.2633 14.4461 65.5832 15.3504 65.5832 16.4995C65.6022 17.6485 65.2633 18.5528 64.6224 19.2307Z"
      fill="currentColor"
    />
    <path
      d="M85.1824 9.74475L79.475 23.2709H75.4245L69.7356 9.74475H73.7857L77.5344 18.957L81.4152 9.74475H85.1824Z"
      fill="currentColor"
    />
    <path
      d="M99.5756 17.6095H89.346C89.5348 18.4572 89.9679 19.1166 90.6468 19.5872C91.3247 20.0774 92.191 20.3222 93.2084 20.3222C93.9053 20.3222 94.5462 20.2091 95.0739 20.0018C95.6197 19.7945 96.1284 19.4555 96.599 19.0034L98.6899 21.264C97.4096 22.7145 95.5626 23.4496 93.1147 23.4496C91.5886 23.4496 90.2318 23.1476 89.0641 22.5638C87.896 21.961 86.9918 21.1509 86.3509 20.0774C85.7105 19.0224 85.3906 17.8168 85.3906 16.4789C85.3906 15.1601 85.7105 13.9545 86.3319 12.8995C86.9537 11.826 87.8209 10.9969 88.932 10.4131C90.0245 9.81023 91.2492 9.52734 92.6241 9.52734C93.9429 9.52734 95.1485 9.81023 96.2225 10.3755C97.296 10.9403 98.1442 11.7694 98.7475 12.8244C99.3498 13.8984 99.6693 15.1226 99.6693 16.5355C99.6508 16.6106 99.6327 16.9686 99.5756 17.6095ZM90.402 13.2575C89.7986 13.7662 89.4221 14.4446 89.2899 15.3108H95.9591C95.8265 14.4632 95.4504 13.7662 94.8471 13.2575C94.2448 12.7488 93.5098 12.4854 92.6431 12.4854C91.7579 12.5039 91.0043 12.7678 90.402 13.2575Z"
      fill="currentColor"
    />
    <path
      d="M107.961 10.036C108.753 9.69707 109.677 9.52734 110.712 9.52734V13.1443C110.279 13.1068 109.978 13.0882 109.827 13.0882C108.697 13.0882 107.83 13.4082 107.19 14.03C106.548 14.6519 106.229 15.5937 106.229 16.8559V23.2423H102.311V9.71609H106.06V11.5056C106.53 10.8652 107.152 10.3755 107.961 10.036Z"
      fill="currentColor"
    />
    <path
      d="M115.004 23.0291C113.704 22.6526 112.669 22.1624 111.877 21.5596L113.252 18.4888C114.006 19.0356 114.911 19.4877 115.947 19.8267C116.983 20.1656 118.019 20.3354 119.073 20.3354C120.223 20.3354 121.09 20.1656 121.636 19.8267C122.183 19.4877 122.465 19.0165 122.465 18.4513C122.465 18.0367 122.295 17.6792 121.974 17.4153C121.655 17.1329 121.221 16.9257 120.712 16.7559C120.204 16.5862 119.507 16.3979 118.64 16.2097C117.303 15.8892 116.21 15.5693 115.344 15.2488C114.496 14.9284 113.761 14.4197 113.139 13.7227C112.518 13.0258 112.216 12.084 112.216 10.8973C112.216 9.87993 112.499 8.95666 113.046 8.10898C113.591 7.28033 114.439 6.62093 115.551 6.13076C116.663 5.64108 118.019 5.39624 119.639 5.39624C120.77 5.39624 121.861 5.52793 122.935 5.79179C124.01 6.05565 124.951 6.4512 125.761 6.94088L124.499 10.0306C122.88 9.10737 121.24 8.65524 119.62 8.65524C118.471 8.65524 117.642 8.8435 117.096 9.2015C116.549 9.57851 116.286 10.0492 116.286 10.652C116.286 11.2548 116.605 11.7074 117.227 11.9898C117.849 12.2913 118.81 12.5737 120.11 12.8565C121.447 13.1765 122.54 13.4969 123.406 13.8169C124.255 14.1373 124.989 14.646 125.61 15.324C126.214 16.0214 126.534 16.9442 126.534 18.1123C126.534 19.1107 126.251 20.0339 125.686 20.8626C125.121 21.6917 124.273 22.3511 123.162 22.8408C122.031 23.3305 120.675 23.5753 119.055 23.5753C117.661 23.5944 116.305 23.4061 115.004 23.0291Z"
      fill="currentColor"
    />
    <path
      d="M142.191 17.6095H131.962C132.15 18.4572 132.583 19.1166 133.262 19.5872C133.94 20.0774 134.806 20.3222 135.824 20.3222C136.521 20.3222 137.162 20.2091 137.689 20.0018C138.236 19.7945 138.744 19.4555 139.215 19.0034L141.306 21.264C140.025 22.7145 138.178 23.4496 135.73 23.4496C134.204 23.4496 132.847 23.1476 131.68 22.5638C130.511 21.961 129.608 21.1509 128.967 20.0774C128.326 19.0224 128.007 17.8168 128.007 16.4789C128.007 15.1601 128.326 13.9545 128.948 12.8995C129.57 11.826 130.437 10.9969 131.548 10.4131C132.641 9.81023 133.864 9.52734 135.24 9.52734C136.559 9.52734 137.764 9.81023 138.838 10.3755C139.912 10.9403 140.76 11.7694 141.363 12.8244C141.966 13.8984 142.286 15.1226 142.286 16.5355C142.267 16.6106 142.248 16.9686 142.191 17.6095ZM133.017 13.2575C132.414 13.7662 132.038 14.4446 131.905 15.3108H138.574C138.442 14.4632 138.066 13.7662 137.463 13.2575C136.86 12.7488 136.126 12.4854 135.258 12.4854C134.355 12.5039 133.621 12.7678 133.017 13.2575Z"
      fill="currentColor"
    />
    <path
      d="M148.766 4.62537H144.848V23.2941H148.766V4.62537Z"
      fill="currentColor"
    />
    <path
      d="M156.692 10.0401H160.159V13.0543H156.787V23.2649H152.868V13.0543H150.778V10.0401H152.868V9.43729C152.868 7.89266 153.32 6.66846 154.244 5.76421C155.167 4.85996 156.447 4.40784 158.105 4.40784C158.689 4.40784 159.255 4.46393 159.782 4.5961C160.31 4.72779 160.743 4.89752 161.101 5.14236L160.064 7.98679C159.613 7.66684 159.085 7.51613 158.482 7.51613C157.296 7.51613 156.692 8.17554 156.692 9.47533V10.0401Z"
      fill="currentColor"
    />
    <path
      d="M14.4631 21.4425C14.9074 21.4693 15.3147 21.1772 15.6941 20.649L23.3276 6.49849C25.5028 5.2104 25.7525 3.28973 25.6755 2.146L25.6969 2.1065C26.3066 1.04764 26.9572 0.34141 28.9179 0.119006L27.1484 13.3618C26.8041 16.1238 25.4716 17.9123 24.1289 19.4311L15.8058 27.4137C15.3688 27.7888 14.922 27.9971 14.4631 28H14.3377C13.8788 27.9971 13.5491 27.7888 13.1121 27.4137L4.789 19.4311C3.44629 17.9123 2.11381 16.1238 1.76997 13.3618L0 0.119006C1.96067 0.34141 2.6113 1.04764 3.22145 2.1065L3.24242 2.146C3.16536 3.28973 3.41507 5.2104 5.59034 6.49849L13.2238 20.649C13.6032 21.1772 14.0105 21.4693 14.4548 21.4425H14.4631ZM14.4631 12.2815L19.7979 3.25851C20.1602 2.73908 20.4568 2.26257 20.7524 1.83825C21.8244 0.591614 23.6597 0.129248 25.3653 0L24.5513 1.47099C23.0198 2.0904 22.416 3.29899 22.2116 4.42857C22.1199 4.93727 22.1087 5.42988 22.1307 5.84591C19.9369 9.81066 17.5631 14.1017 15.5702 17.7021C15.2888 18.2108 14.8889 18.5746 14.4631 18.5903H14.4548C14.029 18.5746 13.6291 18.2108 13.3477 17.7021C11.3548 14.1017 8.98103 9.81066 6.78723 5.84591C6.80918 5.42988 6.79796 4.93727 6.70626 4.42857C6.50191 3.29899 5.8981 2.0904 4.36663 1.47099L3.55261 0C5.25869 0.129248 7.09352 0.591614 8.16603 1.83825C8.4616 2.26257 8.75765 2.73908 9.12003 3.25851L14.4548 12.2815V12.2742L14.4592 12.2815L14.4631 12.2742V12.2815Z"
      fill="currentColor"
    />
  </svg>
);
