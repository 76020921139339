export const departmentOptions = [
  { label: 'All', value: 'All', selected: true },
  { label: 'Inpatient', value: 'Inpatient' },
  { label: 'Outpatient', value: 'Outpatient' },
  { label: 'ER', value: 'ER' },
  { label: 'Observation', value: 'Observation' },
];

export const specialtyOptions = [
  { label: 'All', value: 'All', selected: true },
  { label: 'Paediatric', value: 'Paediatric' },
  { label: 'Orthopaedic', value: 'Orthopaedic' },
  { label: 'Radiology', value: 'Radiology' },
  { label: 'Labs & Pathology', value: 'Labs & Pathology' },
  { label: 'OB & GYN', value: 'OB & GYN' },
  { label: 'Oncology', value: 'Oncology' },
  { label: 'Neurology', value: 'Neurology' },
];

export const payorOptions = [
  { label: 'All', value: 'All', selected: true },
  { label: 'BCBS', value: 'BCBS' },
  { label: 'Aetna', value: 'Aetna' },
  { label: 'Cigna', value: 'Cigna' },
  { label: 'Medicare', value: 'Medicare' },
  { label: 'UHC', value: 'UHC' },
  { label: 'Humana', value: 'Humana' },
];
