/* eslint no-underscore-dangle: 0 */
import React, { useState } from 'react';
import {
  Table as UITable,
  Divider,
  List,
  Label,
  Input,
} from '@innovaccer/design-system';
import { useUpdateClaimItemsMutation } from 'src/features/claims/store/api';
import { tableschema } from './schema';
import { NestedTableSchema } from './NestedTableSchema';
import { TableFilter } from './TableFilter';
import styles from '../styles.module.scss';

const HEADER_OPTIONS = {
  children: <TableFilter />,
  withSearch: true,
  dynamicColumn: false,
};

const affectedEdits = {};

export const Table = ({
  data,
  correction,
  disabled,
  setDisable,
  nestedData,
  setNestedData,
  handleIsDirtyState,
  setIsModifierOpen,
  setIsNDCOpen,
  handleOnSubmit,
  disableCharges,
  setDisableCharges,
  setCommentModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditRemoved, setisEditRemoved] = useState(false);

  const [updateClaimItems] = useUpdateClaimItemsMutation();

  const handleRowEdit = async () => {
    setIsLoading(true);
    setDisable(false);
    setIsLoading(false);
  };

  const handleDeleteClaimItem = async (e) => {
    const filteredClaimItems = nestedData.filter(
      (value) => value.claimLineId !== e?.claimLineId
    );
    setNestedData(filteredClaimItems);
    await updateClaimItems({
      id: data?._id,
      items: filteredClaimItems,
    });
    setCommentModal(false);
    setDisable(true);
    setDisableCharges(true);
  };

  const handleOnChange = (e, claimLine) => {
    handleIsDirtyState(claimLine);
    const activeClaimLine = nestedData.map((item) => {
      const claimLineItem = { ...item };
      if (claimLineItem.claimLineId === claimLine.claimLineId) {
        claimLineItem[e.target.name] = e.target.value;
      }
      return claimLineItem;
    });
    setNestedData(activeClaimLine);
  };

  const affectedFields = () => {
    data?.edits.forEach((value) => {
      affectedEdits[value.claimLineId] = affectedEdits[value.claimLineId]
        ? affectedEdits[value.claimLineId].concat(...value.affectedColumns)
        : [...value.affectedColumns];
    });
  };

  React.useEffect(() => {
    affectedFields();
  }, []);

  React.useEffect(() => {
    setisEditRemoved(true);
    setTimeout(() => {
      setisEditRemoved(false);
    }, 1000);
  }, [data.edits]);

  const nestedRowRenderer = (props) => {
    const { loading } = props;
    const claimItemsCharge = nestedData.map((value) => Number(value?.charge));
    const TotalCharge = claimItemsCharge.reduce((a, b) => a + b);

    const tablekey = () => {
      if (!disabled || !disableCharges || !isEditRemoved) {
        return !disableCharges;
      }
      return new Date().getTime();
    };

    return (
      <div key={tablekey() || new Date()}>
        <Divider className="ml-5" />
        <List
          loading={loading}
          schema={NestedTableSchema({
            handleDelete: handleDeleteClaimItem,
            disabled,
            handleOnChange,
            affectedEdits,
            setIsModifierOpen,
            setIsNDCOpen,
            handleOnSubmit,
            disableCharges,
          })}
          data={nestedData}
          className="billItems"
          pageSize={8}
          withPagination
        />
        <div className="d-flex flex-row align-items-center justify-content-end mb-4">
          <div className={styles.tableTotalCharge}>
            <Label withInput>Total charges:</Label>
            <Input
              readOnly
              value={TotalCharge}
              name="totalCharges"
              minWidth={0}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <UITable
      schema={tableschema({
        handleEditOpen: handleRowEdit,
        disabled,
        correction,
      })}
      data={[data]}
      showMenu={false}
      withHeader
      headerOptions={!correction && HEADER_OPTIONS}
      withPagination
      pageSize={10}
      loading={isLoading}
      nestedRows={data?.items}
      nestedRowRenderer={nestedRowRenderer}
      className="mb-8 claimItems"
    />
  );
};
