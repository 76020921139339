import { getObjectComparator } from 'src/services/utils';

export const tableSchema = [
  {
    name: 'claimdId',
    displayName: 'Claim No.#',
  },
  {
    name: 'claimId',
    displayName: 'Claim line#',
  },
  {
    name: 'serviceStartDate',
    displayName: 'Service start date',
  },
  {
    name: 'serviceEndDate',
    displayName: 'Service end date',
  },
  {
    name: 'placeOfService',
    displayName: 'POS',
    width: 100,
    comparator: getObjectComparator('id'),
  },
  {
    name: 'cptCode',
    displayName: 'CPT/HCPCS Codes',
    width: 200,
    sorting: true,
    comparator: getObjectComparator('code'),
  },
  {
    name: 'modifier',
    displayName: 'Modifier',
  },
  {
    name: 'units',
    displayName: 'Units',
    width: 100,
  },
  {
    name: 'primaryCode',
    displayName: 'Primary Dx Code',
    width: 100,
  },
  {
    name: 'performingProvider',
    displayName: 'Performing Provider',
    width: 200,
  },
  {
    name: 'billingProvider',
    displayName: 'Billing Provider',
    width: 200,
  },
  {
    name: 'billingSpeciality',
    displayName: 'Billing Speciality',
    width: 200,
  },
  {
    name: 'charge',
    displayName: 'Charges',
  },
  {
    name: 'billedAmount',
    displayName: 'Billed amount',
  },
  {
    name: 'insurancePayment',
    displayName: 'Insurance payment',
  },
  {
    name: 'patientPayment',
    displayName: 'Patient payment',
  },
  {
    name: 'insuranceBalance',
    displayName: 'Insurance Balance',
  },
  {
    name: 'patientBalance',
    displayName: 'Patient Balance',
  },
  {
    name: 'adjustments',
    displayName: 'Adjustments',
  },
];
