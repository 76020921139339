import classNames from 'classnames';
import styles from './styles.module.scss';

const heading = [
  'Isurance Name',
  'Plan',
  'Member ID',
  'Group ID',
  'Effective Date',
  'Termination Date',
];
const vertical = {
  0: 'Primary',
  1: 'Secondary',
  2: 'Tertiary',
};

export const InsuranceTable = ({ insuranceData }) => {
  const tableData = insuranceData.map((value, index) => (
    <tbody key={value.name}>
      <tr className={styles.tableRow}>
        {index === 3 ? (
          <th colSpan={5} className={styles.footerHeading}>
            Self Pay
          </th>
        ) : (
          <th scope="row" className={styles.tableHeading}>
            {vertical[index]}
          </th>
        )}

        {index === 3 ? (
          <>
            <td className={styles.tableRow}>
              {new Date(value.effectiveDate).toLocaleDateString('en-US')}
            </td>
            <td>
              {new Date(value.terminationDate).toLocaleDateString('en-US')}
            </td>
          </>
        ) : (
          <>
            <td>{value.name}</td>
            <td>{value.plan}</td>
            <td>{value.memberId}</td>
            <td>{value.groupId}</td>
            <td>{new Date(value.effectiveDate).toLocaleDateString('en-US')}</td>
            <td className={styles.textAlign}>
              {new Date(value.terminationDate).toLocaleDateString('en-US')}
            </td>
          </>
        )}
      </tr>
    </tbody>
  ));
  return (
    <table className={classNames(styles.table, 'w-100 my-8')}>
      <thead>
        <tr className={styles.tableRow}>
          <td />
          {heading.map((value) => (
            <th scope="col" className={styles.tableHeading} key={value}>
              {value}
            </th>
          ))}
        </tr>
      </thead>
      {tableData}
    </table>
  );
};
