import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { getValidRealmAccessToken } from 'src/services/realm';
import Auth0 from 'src/services/auth0';
import {
  getClaimQuery,
  getClaimsQuery,
  updateMetadata,
  updatePatient,
  updateProvider,
  deleteEdit,
  updateClaimItems,
} from './queries';

export const claimsApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.REACT_APP_REALM_GRAPHQL_ENDPOINT,
    prepareHeaders: async (headers) => {
      const token = await Auth0.client.getAccessTokenSilently();

      const accessToken = await getValidRealmAccessToken(token);

      headers.set('Authorization', `Bearer ${accessToken}`);

      return headers;
    },
  }),
  tagTypes: ['Claims', 'Claim'],
  endpoints: (builder) => ({
    getClaims: builder.query({
      query: ({ skip, limit }) => ({
        document: getClaimsQuery,
        variables: {
          paginatedClaimsInput: { skip, limit },
        },
      }),
      providesTags: ['Claims'],
    }),
    getClaim: builder.query({
      query: (claimId) => ({
        document: getClaimQuery,
        variables: {
          claimId,
        },
      }),
      providesTags: ['Claim'],
    }),
    updatePatient: builder.mutation({
      query: ({ claimId, patient }) => ({
        document: updatePatient,
        variables: {
          claimId,
          patientInput: patient,
        },
      }),
      invalidatesTags: ['Claim'],
    }),
    updateProvider: builder.mutation({
      query: ({ claimId, provider }) => ({
        document: updateProvider,
        variables: {
          claimId,
          providerInput: provider,
        },
      }),
      invalidatesTags: ['Claim'],
    }),
    updateMetadata: builder.mutation({
      query: ({ claimId, metadata }) => ({
        document: updateMetadata,
        variables: {
          claimId,
          metadataInput: metadata,
        },
      }),
      invalidatesTags: ['Claim'],
    }),
    deleteEdit: builder.mutation({
      query: ({ id }) => ({
        document: deleteEdit,
        variables: {
          id,
        },
      }),
      invalidatesTags: ['Claim'],
    }),
    updateClaimItems: builder.mutation({
      query: ({ id, items }) => ({
        document: updateClaimItems,
        variables: {
          id,
          items,
        },
      }),
      invalidatesTags: ['Claim'],
    }),
  }),
});

export const {
  useGetClaimsQuery,
  useGetClaimQuery,
  useLazyGetClaimsQuery,
  useUpdatePatientMutation,
  useUpdateProviderMutation,
  useUpdateMetadataMutation,
  useDeleteEditMutation,
  useUpdateClaimItemsMutation,
} = claimsApi;

export const useEditClaimMutation = () => [];
