export const data = [
  {
    claimId: 'IC030621',
    username: '@Jessie',
    cptCode: 99213,
    specialty: 'Family Medicine',
    actions: ' ',
    comments: 'Duplicate record',
  },
  {
    claimId: '85333',
    username: '@Allan',
    cptCode: 99213,
    specialty: 'Family Medicine',
    actions: ' ',
    comments: 'NCCI PTP Edit',
  },
  {
    claimId: 'IC26590',
    username: '@Patrick',
    cptCode: 99214,
    specialty: 'Internal Medicine',
    actions: ' ',
    comments: 'Invalid ICD code',
  },
  {
    claimId: 'IC819110',
    username: '@Daniel',
    cptCode: 90785,
    specialty: 'Psychology',
    actions: ' ',
    comments: 'Duplicate record',
  },
  {
    claimId: 'IC18610',
    username: '@Sherri',
    cptCode: 99213,
    specialty: 'Podiatry',
    actions: ' ',
    comments: 'Claim Lacks Info',
  },
];
