export const data = [
  {
    label: 'Lacks Medical necessity',
    value: 1680,
    valueColor: '#4F9DE7',
    last: 1090,
    lastColor: '#D8E7F6',
  },
  {
    label: 'Incidental/inclusive',
    value: 1400,
    valueColor: '#FEAC5F',
    last: 1270,
    lastColor: '#FFE3C8',
  },
  {
    label: 'Not proven to be effective by the payer',
    value: 1230,
    valueColor: '#FEAC5F',
    last: 1170,
    lastColor: '#FFE3C8',
  },
  {
    label: 'Missing prior authorization',
    value: 1050,
    valueColor: '#FEAC5F',
    last: 750,
    lastColor: '#FFE3C8',
  },
  {
    label: 'Level of services not supported',
    value: 880,
    valueColor: '#FEAC5F',
    last: 890,
    lastColor: '#FFE3C8',
  },
  {
    label: 'Number of units exceeds  the maximum allowable',
    value: 780,
    valueColor: '#FEAC5F',
    last: 750,
    lastColor: '#FFE3C8',
  },
  {
    label: 'Invalid diagnosis',
    value: 680,
    valueColor: '#FEAC5F',
    last: 590,
    lastColor: '#FFE3C8',
  },
  {
    label: 'Lifetime benefits has been reached',
    value: 610,
    valueColor: '#FEAC5F',
    last: 590,
    lastColor: '#FFE3C8',
  },
  {
    label: 'Missing or Invalid modifier',
    value: 390,
    valueColor: '#FEAC5F',
    last: 500,
    lastColor: '#FFE3C8',
  },
  {
    label: ' Invalid procedure code',
    value: 310,
    valueColor: '#FEAC5F',
    last: 460,
    lastColor: '#FFE3C8',
  },
].reverse();
