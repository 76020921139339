import { Icon, PlaceholderParagraph } from '@innovaccer/design-system';
import { BoldCell, MetaListCell } from 'src/pages/common';
import { getObjectComparator } from 'src/services/utils';

export const denialsManagementTableSchema = [
  {
    name: 'p',
    displayName: 'P',
    width: 50,
    minWidth: 50,
    cellType: 'ICON',
    sorting: false,
    cellRenderer: ({ loading }) => {
      if (loading) return <PlaceholderParagraph />;

      return <Icon size={20} name="flag" appearance="alert" />;
    },
  },
  {
    name: 'claimId',
    displayName: 'Claim ID',
    sorting: true,
    comparator: getObjectComparator('claimId'),
    width: 100,
    cellRenderer: BoldCell,
  },
  {
    name: 'dateOfService',
    displayName: 'DOS',
    sorting: true,
    comparator: getObjectComparator('serviceStartDate'),
    cellRenderer: ({ data, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      return data?.serviceStartDate && data?.serviceEndDate
        ? `${new Date(data.serviceStartDate).toLocaleDateString()} - ${new Date(
            data.serviceEndDate
          ).toLocaleDateString()}`
        : null;
    },
  },
  {
    name: 'patient',
    displayName: 'Patient',

    sorting: true,
    comparator: (a, b) => {
      const leftValue = a.patient.firstName;
      const rightValue = b.patient.firstName;
      if (leftValue < rightValue) return -1;
      if (leftValue > rightValue) return 1;
      return 0;
    },
    cellRenderer: ({ loading, data, schema }) => {
      if (loading) return <PlaceholderParagraph />;

      const { firstName, lastName, dateOfBirth, gender } =
        data[schema.name] ?? {};
      return (
        <MetaListCell
          title={`${firstName} ${lastName}`}
          meta={`${
            dateOfBirth && `${new Date(dateOfBirth).toLocaleDateString()}, `
          } ${gender}`}
        />
      );
    },
  },
  {
    name: 'insurance',
    displayName: 'Insurance',
    sorting: true,
    comparator: getObjectComparator('insurance'),
    width: 200,
    cellRenderer: ({ data, schema, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      const cellValue = data[schema.name] ?? '';

      return cellValue ? cellValue[0]?.name : null;
    },
  },
  {
    name: 'claimType',
    displayName: 'Claim Type',
    sorting: true,
    comparator: getObjectComparator('claimType'),
    width: 120,
  },
  {
    name: 'department',
    displayName: 'Department',
    sorting: true,
    comparator: getObjectComparator('department'),
  },
  {
    name: 'financialClass',
    displayName: 'Financial Class',
    sorting: true,
    comparator: getObjectComparator('financialClass'),
  },
  {
    name: 'cptCode',
    displayName: 'CPT Code',
    sorting: true,
    comparator: getObjectComparator('cptCode'),
    width: 100,
  },
  {
    name: 'provider',
    displayName: 'Provider',
    width: 200,
    sorting: true,
    comparator: (a, b) => {
      const leftValue = a.provider.billing.name;
      const rightValue = b.provider.billing.name;
      if (leftValue < rightValue) return -1;
      if (leftValue > rightValue) return 1;
      return 0;
    },
    cellRenderer: ({ data, schema, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      const { billing } = data[schema.name] ?? {};
      return <MetaListCell title={billing?.name} meta={billing?.npi} />;
    },
  },
  {
    name: 'receivedDate',
    displayName: 'Received Date',
    sorting: true,
    comparator: getObjectComparator('receivedDate'),
    cellRenderer: ({ data, schema, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      const cellDate = data[schema.name] ?? '';

      return (
        <MetaListCell
          title="Overdue"
          meta={cellDate && new Date(cellDate).toLocaleDateString()}
          appearance="destructive"
        />
      );
    },
  },
  {
    name: 'potentialDenialCarcCode',
    displayName: 'Potential Denial CARC Code',
    sorting: true,
    comparator: getObjectComparator('potentialDenialCarcCode'),
    width: 50,
  },
  {
    name: 'potentialDenialRarcCode',
    displayName: 'Potential Denial RARC Code',
    sorting: true,
    comparator: getObjectComparator('potentialDenialRarcCode'),
    width: 50,
  },
  {
    name: 'assignmentDate',
    displayName: 'Assignment assignedToDate',
    sorting: true,
    comparator: getObjectComparator('assignmentDate'),
    cellRenderer: ({ data, schema, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      const cellDate = data[schema.name] ?? '';

      return cellDate ? new Date(cellDate).toLocaleDateString() : null;
    },
  },
  {
    name: 'assignedTo',
    displayName: 'Assigned To',
    sorting: true,
    comparator: getObjectComparator('assignedTo'),
  },
  {
    name: 'receivedDate',
    displayName: 'Received Date',
    sorting: true,
    comparator: getObjectComparator('receivedDate'),
    cellRenderer: ({ data, schema, loading }) => {
      if (loading) return <PlaceholderParagraph />;

      const cellDate = data[schema.name] ?? '';

      return cellDate ? new Date(cellDate).toLocaleDateString() : null;
    },
  },
];
