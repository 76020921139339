import { PaymentVariance, PrebillEditsDetails } from 'src/pages';
import { ROUTES } from '../route-names';

export const PREBILL_EDITS = [
  {
    path: ROUTES.prebillEdits,
    element: PaymentVariance,
  },
  {
    path: `${ROUTES.prebillEdits}/:id/*`,
    element: PrebillEditsDetails,
  },
];
