export const data = [
  {
    label: 'Inpatient',
    Completed: 0,
    CompletedColor: '#4F9DE7',
    Pending: 0,
    PendingColor: '#FEAC5F',
  },
  {
    label: 'Outpatient',
    Completed: 0,
    CompletedColor: '#4F9DE7',
    Pending: 0,
    PendingColor: '#FEAC5F',
  },
  {
    label: 'Professional',
    Completed: 100517,
    CompletedColor: '#4F9DE7',
    Pending: 0,
    PendingColor: '#FEAC5F',
  },
];
