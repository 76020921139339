import React from 'react';
import {
  ListCell,
  InputCell,
  ButtonCell,
} from 'src/pages/common/table-cells/NestedMetaListCell';
import { ActionCell } from 'src/pages/common';
import { getObjectComparator } from 'src/services/utils';
import {
  PlaceholderParagraph,
  Icon,
  Input,
  Button,
} from '@innovaccer/design-system';
import { format } from 'date-fns';
import styles from '../styles.module.scss';

export const NestedTableSchema = ({
  handleDelete,
  disabled = true,
  handleOnChange,
  affectedEdits,
  setIsModifierOpen = false,
  setIsNDCOpen = false,
  handleOnSubmit,
  disableCharges = true,
}) => [
  {
    name: '',
    displayName: '',
    cellRenderer: () => (
      <>
        <div className={styles.billItemsArrow} />
        <Icon
          name="keyboard_arrow_right"
          className={styles.billItemsArrowStyle}
        />
      </>
    ),
  },
  {
    name: 'claimLineId',
    displayName: 'Claim line#',
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];

      if (value)
        return (
          <Input
            name="claimLineId"
            defaultValue={value}
            minWidth={0}
            disabled={disabled}
            className="border-0"
          />
        );

      return null;
    },
  },
  {
    name: 'serviceStartDate',
    displayName: 'Service start date',
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`serviceStartDate`);
      if (value)
        return (
          <InputCell
            name="serviceStartDate"
            disabled={disabled}
            value={format(new Date(value), 'MM/dd/yyyy')}
            onChange={(e) => handleOnChange(e, props.data)}
            hasError={hasError}
          />
        );

      return null;
    },
  },
  {
    name: 'serviceEndDate',
    displayName: 'Service end date',
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`serviceEndDate`);

      if (value)
        return (
          <InputCell
            name="serviceEndDate"
            disabled={disabled}
            value={format(new Date(value), 'MM/dd/yyyy')}
            onChange={(e) => handleOnChange(e, props.data)}
            hasError={hasError}
          />
        );

      return null;
    },
  },
  {
    name: 'cptCode',
    displayName: 'CPT/HCPCS Codes',
    width: 200,
    sorting: true,
    comparator: getObjectComparator('code'),
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name] ?? '';

      const code = value?.split(' ')[0];
      // ? value?.split(' ').slice(1).join(' ') : '';
      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`cptCode`);
      return (
        <ListCell
          name="cptCode"
          title={code}
          disabled={disabled}
          onChange={(e) => handleOnChange(e, props.data)}
          hasError={hasError}
          maxLength={5}
        />
      );
    },
  },
  {
    name: 'modifier',
    displayName: 'Modifier',
    // FIXME: BAD CODE - Remove this after schema integration
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value =
        props.data[props.schema.name].length > 0
          ? props.data[props.schema.name]
          : ' ';
      // &&
      // props.data[props.schema.name]?.join(', ');

      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`modifier`);
      if (value)
        return (
          <ButtonCell
            name="modifier"
            hasError={hasError}
            value={value}
            onClick={() => setIsModifierOpen(true)}
            onChange={(e) => handleOnChange(e, props.data)}
            appearance={disabled ? 'basic' : 'transparent'}
            disabled={disabled}
          />
        );

      return null;
    },
  },
  {
    name: 'diagnosisPointer',
    displayName: 'Diagnosis Pointer',
    width: 100,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      // const value = props.data[props.schema.name];
      // const hasError =
      //   affectedEdits[props.data.claimLineId]?.includes(`diagnosisPointer`);
      // if (value)
      //   return (
      //     <InputCell
      //       name="diagnosisPointer"
      //       disabled={disabled}
      //       value={value}
      //       onChange={(e) => handleOnChange(e, props.data)}
      //       hasError={hasError}
      //     />
      //   );

      return <div>K03.2</div>;
    },
  },
  {
    name: 'units',
    displayName: 'Units',
    width: 100,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      const hasError = affectedEdits[props.data.claimLineId]?.includes(`units`);
      if (value)
        return (
          <InputCell
            name="units"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, props.data)}
            hasError={hasError}
          />
        );

      return null;
    },
  },
  {
    name: 'placeOfService',
    displayName: 'POS',
    width: 100,
    sorting: true,
    comparator: getObjectComparator('id'),
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const { id = props?.data?.placeOfService } =
        props.data[props.schema.name] ?? {};
      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`placeOfService`);
      return (
        <ListCell
          name="placeOfService"
          disabled={disabled}
          title={id}
          onChange={(e) => handleOnChange(e, props.data)}
          hasError={hasError}
          maxLength={2}
        />
      );
    },
  },
  {
    name: 'billingSpeciality',
    displayName: 'Billing Speciality',
    width: 200,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`billingSpeciality`);
      if (value)
        return (
          <InputCell
            name="billingSpeciality"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, props.data)}
            hasError={hasError}
          />
        );

      return null;
    },
  },
  {
    name: 'billingProviderName',
    displayName: 'Billing Provider name',
    width: 200,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`billingProviderName`);
      if (value)
        return (
          <InputCell
            name="billingProviderName"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, props.data)}
            hasError={hasError}
          />
        );

      return null;
    },
  },
  {
    name: 'charge',
    displayName: 'Charges',
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;
      const value = props.data[props.schema.name];
      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`charge`);
      if (value)
        return (
          <InputCell
            name="charge"
            disabled={disableCharges}
            value={value}
            onChange={(e) => handleOnChange(e, props.data)}
            hasError={hasError}
          />
        );

      return null;
    },
  },
  {
    name: 'emergencyIndicator',
    displayName: 'Emergency Indicator',
    width: 100,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      const hasError =
        affectedEdits[props.data.claimLineId]?.includes(`emergencyIndicator`);
      if (value)
        return (
          <InputCell
            name="emergencyIndicator"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, props.data)}
            hasError={hasError}
          />
        );

      return null;
    },
  },
  {
    name: 'epsdt',
    displayName: 'EPSDT Family Plan',
    width: 100,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name];
      const hasError = affectedEdits[props.data.claimLineId]?.includes(`epsdt`);
      if (value)
        return (
          <InputCell
            name="epsdt"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, props.data)}
            hasError={hasError}
          />
        );

      return null;
    },
  },
  {
    name: 'ndc',
    displayName: 'NDC',
    width: 200,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const value = props.data[props.schema.name]
        ? props.data[props.schema.name]
        : ' ';
      const hasError = affectedEdits[props.data.claimLineId]?.includes(`ndc`);
      if (value)
        return (
          <ButtonCell
            name="ndc"
            hasError={hasError}
            value={value}
            onClick={() => setIsNDCOpen(true)}
            onChange={(e) => handleOnChange(e, props.data)}
            appearance={disabled ? 'basic' : 'transparent'}
            disabled={disabled}
          />
        );

      return null;
    },
  },
  {
    name: 'button',
    sorting: false,
    width: '800',
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;
      return (
        <div className="d-flex flex-row mr-8">
          <Button
            type="button"
            style={{
              backgroundColor: '#10E23E',
              color: '#FFFFFF',
            }}
          >
            Validate
            <span className="material-icons-outlined ml-3">refresh</span>
          </Button>
          <Button type="button" appearance="basic" className="mx-8">
            Discard
            <span className="material-icons-outlined ml-3">refresh</span>
          </Button>
          <Button
            type="button"
            appearance="primary"
            style={{
              color: '#FFFFFF',
            }}
            onClick={() => handleOnSubmit()}
          >
            Save
            <span className="material-icons-outlined ml-3">refresh</span>
          </Button>
        </div>
      );
    },
  },
  {
    name: 'action',
    displayName: '',
    width: 70,
    minWidth: 70,
    sorting: false,
    cellRenderer: (props) => {
      if (props.loading) return <PlaceholderParagraph />;

      const lineItem = props.data;

      const deleteClaimItems = React.useCallback(() => {
        handleDelete(lineItem);
      }, [lineItem]);

      return (
        <ActionCell
          onClick={(action) => {
            if (action === 'Delete') deleteClaimItems();
          }}
          options={[
            { label: 'Edit', value: 'Edit' },
            { label: 'Delete', value: 'Delete' },
          ]}
        />
      );
    },
  },
];
