export const PRE_SUBMISSION_ROUTE = 'pre-submission';
export const POST_SUBMISSION_ROUTE = 'post-submission';

export const PROFESSIONAL_ROUTE = 'professional';
export const INPATIENT_ROUTE = 'in-patient';

export const ROUTES = {
  claims: '/claims',
  signIn: '/sign-in',
  dashboard: '/dashboard',
  operation: '/dashboard/operation',
  revenueIntelligence: '/dashboard/revenue-intelligence',
  denialsPrediction: `/worklist/${PRE_SUBMISSION_ROUTE}/${PROFESSIONAL_ROUTE}/denials-prediction`,
  missedCharges: `/worklist/${PRE_SUBMISSION_ROUTE}/${PROFESSIONAL_ROUTE}/missed-charges`,
  denialsManagement: `/worklist/${POST_SUBMISSION_ROUTE}/${PROFESSIONAL_ROUTE}/denials-management`,
  paymentVariance: `/worklist/${POST_SUBMISSION_ROUTE}/${PROFESSIONAL_ROUTE}/payment-variance`,
  due: '/worklist/due',
  completed: '/worklist/completed',
  received: '/received',
  sent: '/sent',
  inpatientClaims: '/user-groups/inpatient-claims',
  interventionalRadiology: '/user-groups/interventional-radiology',
  cardiology: '/user-groups/cardiology',
  prebillEdits: `/worklist/${PRE_SUBMISSION_ROUTE}/${INPATIENT_ROUTE}/prebill-edits`,
  missedChargesIN: `/worklist/${PRE_SUBMISSION_ROUTE}/${INPATIENT_ROUTE}/missed-charges`,
  denialsManagementIN: `/worklist/${POST_SUBMISSION_ROUTE}/${INPATIENT_ROUTE}/denials-management`,
  paymentVarianceIN: `/worklist/${POST_SUBMISSION_ROUTE}/${INPATIENT_ROUTE}/payment-variance`,
};
