import { Card, Heading } from '@innovaccer/design-system';
import { useClaim } from '../../hooks/useClaimDetails';
import { useLocalNavigation } from '../../hooks/useLocalNavigation';
import { NavigationButtons } from '../NavigationButtons';
import { InsuranceTable } from './Table/InsuranceTable';

export const InsuranceDetails = () => {
  const { claim } = useClaim();
  const { goToNextStep } = useLocalNavigation();

  const onNextButtonClickHandler = () => {
    goToNextStep();
  };

  return (
    <>
      <Card className="my-7 mx-11 px-6 py-5">
        <div className="mb-6 d-flex align-items-center">
          <Heading className="mr-5">Insurance Details</Heading>
        </div>
        <InsuranceTable insuranceData={claim?.insurance} />
      </Card>
      <NavigationButtons onNextClick={onNextButtonClickHandler} />
    </>
  );
};
