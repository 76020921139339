export const data = [
  {
    id: 'Clean Claim',
    label: 'Clean Claim',
    value: 0.8,
    color: '#26ED22',
  },
  {
    id: 'Hard Denials',
    label: 'Hard Denials',
    value: 0.05,
    color: '#FEAC5F',
  },
  {
    id: 'Preventable Denials',
    label: 'Preventable Denials',
    value: 0.0,
    color: '#D93737',
  },
  {
    id: 'Under Payment',
    label: 'Under Payment',
    value: 0.01,
    color: '#9D7EC6',
  },
  {
    id: 'Over Payment',
    label: 'Over Payment',
    value: 0.005,
    color: '#FEAC5F',
  },
  {
    id: 'False Positive',
    label: 'False Positive',
    value: 0.01,
    color: '#FF7070',
  },
].map((value, idx, array) => {
  const total = array.reduce((prev, curr) => prev + curr.value, 0);
  return {
    ...value,
    percent: value.value / total,
  };
});
