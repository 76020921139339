import React from 'react';
import { Button, Icon, Input } from '@innovaccer/design-system';
import { format } from 'date-fns';
import classNames from 'classnames';
import {
  InputCell,
  ListCell,
  ButtonCell,
} from 'src/pages/common/table-cells/NestedMetaListCell';
import { ActionCell } from 'src/pages/common';
import styles from '../styles.module.scss';

export const ClaimItemsSchema = ({
  disabled = false,
  setIsModifierOpen = false,
  setIsNDCOpen = false,
  handleOnSubmit,
  handleDelete,
  handleOnChange,
  affectedEdits,
  disableCharges = true,
}) => [
  {
    Header: () => null,
    id: 'claimLineItems',
    columns: [
      {
        accessor: '',
        id: 'ClaimLine',
        Cell: () => (
          <div
            className={classNames(
              'd-flex flex-row align-items-center',
              styles.claimItemsClaimLine
            )}
          >
            <div className={styles.claimItemsArrow} />
            <Icon
              name="keyboard_arrow_right"
              className={styles.claimItemsArrowStyle}
            />
          </div>
        ),
      },
      {
        accessor: 'claimLineId',
        Cell: ({ cell: { value } }) => (
          <Input
            name="claimLineId"
            defaultValue={value}
            minWidth={0}
            disabled
            className="border-0"
          />
        ),
      },
      {
        accessor: 'serviceStartDate',
        Cell: ({ cell: { value, row } }) => {
          const hasError =
            affectedEdits[row?.original?.claimLineId]?.includes(
              `serviceStartDate`
            );
          return (
            <InputCell
              name="serviceStartDate"
              disabled={disabled}
              value={format(new Date(value), 'MM/dd/yyyy')}
              onChange={(e) => handleOnChange(e, row?.original)}
              hasError={hasError}
            />
          );
        },
      },
      {
        accessor: 'serviceEndDate',
        Cell: ({ cell: { value, row } }) => {
          const hasError =
            affectedEdits[row?.original?.claimLineId]?.includes(
              `serviceEndDate`
            );
          return (
            <InputCell
              name="serviceEndDate"
              disabled={disabled}
              value={format(new Date(value), 'MM/dd/yyyy')}
              onChange={(e) => handleOnChange(e, row?.original)}
              hasError={hasError}
            />
          );
        },
      },
      {
        accessor: 'cptCode',
        Cell: ({ cell: { value, row } }) => {
          const hasError =
            affectedEdits[row?.original?.claimLineId]?.includes(`cptCode`);
          return (
            <ListCell
              name="cptCode"
              title={value}
              disabled={disabled}
              maxLength={5}
              onChange={(e) => handleOnChange(e, row?.original)}
              hasError={hasError}
            />
          );
        },
      },
      {
        accessor: 'modifier',
        Cell: ({ cell: { value, row } }) => {
          const hasError =
            affectedEdits[row?.original?.claimLineId]?.includes(`modifier`);
          return (
            <ButtonCell
              name="modifier"
              value={value}
              onClick={() => setIsModifierOpen(true)}
              appearance={disabled ? 'basic' : 'transparent'}
              disabled={disabled}
              onChange={(e) => handleOnChange(e, row?.original)}
              hasError={hasError}
            />
          );
        },
      },
      {
        accessor: 'diagnosisPointer',
        Cell: ({ cell: { value, row } }) => {
          const hasError =
            affectedEdits[row?.original?.claimLineId]?.includes(
              `diagnosisPointer`
            );
          return (
            <InputCell
              name="diagnosisPointer"
              disabled={disabled}
              value={value}
              onChange={(e) => handleOnChange(e, row?.original)}
              hasError={hasError}
            />
          );
        },
      },
      {
        accessor: 'units',
        Cell: ({ cell: { value, row } }) => {
          const hasError =
            affectedEdits[row?.original?.claimLineId]?.includes(
              `diagnosisPointer`
            );
          return (
            <InputCell
              name="units"
              disabled={disabled}
              value={value}
              onChange={(e) => handleOnChange(e, row?.original)}
              hasError={hasError}
            />
          );
        },
      },
      {
        accessor: 'placeOfService',

        Cell: ({ cell: { value, row } }) => (
          <InputCell
            name="placeOfService"
            disabled={disabled}
            value={value}
            maxLength={2}
            onChange={(e) => handleOnChange(e, row?.original)}
          />
        ),
      },
      {
        accessor: 'billingSpeciality',
        Cell: ({ cell: { value, row } }) => (
          <InputCell
            name="billingSpeciality"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, row?.original)}
          />
        ),
      },
      {
        accessor: 'billingProviderName',
        Cell: ({ cell: { value, row } }) => (
          <InputCell
            name="billingProviderName"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, row?.original)}
          />
        ),
      },
      {
        accessor: 'charge',
        Cell: ({ cell: { value, row } }) => {
          const hasError =
            affectedEdits[row?.original?.claimLineId]?.includes(`charge`);
          return (
            <InputCell
              name="charge"
              disabled={disableCharges}
              value={value}
              onChange={(e) => handleOnChange(e, row?.original)}
              hasError={hasError}
            />
          );
        },
      },
      {
        accessor: 'emergencyIndicator',
        Cell: ({ cell: { value, row } }) => (
          <InputCell
            name="emergencyIndicator"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, row?.original)}
          />
        ),
      },
      {
        accessor: 'epsdt',
        Cell: ({ cell: { value, row } }) => (
          <InputCell
            name="epsdt"
            disabled={disabled}
            value={value}
            onChange={(e) => handleOnChange(e, row?.original)}
          />
        ),
      },
      {
        accessor: 'ndc',
        Cell: ({ cell: { value, row } }) => (
          <ButtonCell
            name="ndc"
            value={value}
            onClick={() => setIsNDCOpen(true)}
            appearance={disabled ? 'basic' : 'transparent'}
            disabled={disabled}
            onChange={(e) => handleOnChange(e, row?.original)}
          />
        ),
      },
      {
        accessor: '',
        id: 'validateButton',
        Cell: () => (
          <div className="d-flex flex-row ml-8">
            <Button type="button" className={styles.validateButton}>
              Validate
              <span className="material-icons-outlined ml-3">refresh</span>
            </Button>
            <Button type="button" appearance="basic" className="mx-8">
              Discard
              <span className="material-icons-outlined ml-3">refresh</span>
            </Button>
            <Button
              type="button"
              appearance="primary"
              className={styles.saveButton}
              onClick={() => handleOnSubmit()}
            >
              Save
              <span className="material-icons-outlined ml-3">refresh</span>
            </Button>
          </div>
        ),
      },
      {
        accessor: '',
        id: 'action',
        Cell: ({ cell: { row } }) => {
          const lineItem = row?.original;
          const deleteClaimItems = React.useCallback(() => {
            handleDelete(lineItem);
          }, [lineItem]);
          return (
            <ActionCell
              onClick={(action) => {
                if (action === 'Delete') deleteClaimItems();
              }}
              options={[
                { label: 'Edit', value: 'Edit' },
                { label: 'Delete', value: 'Delete' },
              ]}
            />
          );
        },
      },
    ],
  },
];
