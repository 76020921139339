import { PaymentVariance, PaymentVarianceDetail } from 'src/pages';
import { ROUTES } from '../route-names';

export const PAYMENT_VARIANCE = [
  {
    path: ROUTES.paymentVariance,
    element: PaymentVariance,
  },
  {
    path: `${ROUTES.paymentVariance}/:id/*`,
    element: PaymentVarianceDetail,
  },
];
