import { useLocation } from 'react-router-dom';
import { INPATIENT_ROUTE } from 'src/router/route-names';
import { TopNavigation, SideNavigation } from './LayoutTopNavigation';
import styles from './styles.module.scss';

export const Layout = ({ children, showSideBar = true }) => {
  const { pathname } = useLocation();
  const inPatientModule = pathname.includes(INPATIENT_ROUTE);

  return (
    <div className={styles.layout}>
      <TopNavigation />
      <div className="d-flex">
        {showSideBar && <SideNavigation inPatientModule={inPatientModule} />}
        <div style={{ flex: 'auto', minWidth: 0 }}>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};
