export const data = [
  {
    label: 'Internal Medicine',
    CleanClaims: 10232,
    CleanClaimsColor: '#4F9DE7',
    EditClaims: 3146,
    EditClaimsColor: '#FEAC5F',
  },
  {
    label: 'Family Medicine',
    CleanClaims: 3711,
    CleanClaimsColor: '#4F9DE7',
    EditClaims: 1113,
    EditClaimsColor: '#FEAC5F',
  },
  {
    label: 'Radiology',
    CleanClaims: 2728,
    CleanClaimsColor: '#4F9DE7',
    EditClaims: 897,
    EditClaimsColor: '#FEAC5F',
  },
  {
    label: 'Cardiology',
    CleanClaims: 2433,
    CleanClaimsColor: '#4F9DE7',
    EditClaims: 617,
    EditClaimsColor: '#FEAC5F',
  },
  {
    label: 'Pathology',
    CleanClaims: 2094,
    CleanClaimsColor: '#4F9DE7',
    EditClaims: 685,
    EditClaimsColor: '#FEAC5F',
  },

  {
    label: 'Oncology',
    CleanClaims: 1645,
    CleanClaimsColor: '#4F9DE7',
    EditClaims: 512,
    EditClaimsColor: '#FEAC5F',
  },
];
