import {
  Button,
  Divider,
  Icon,
  Popover,
  Text,
} from '@innovaccer/design-system';

export const ServiceCodePopover = ({
  handleOpen,
  code,
  description,
  edits,
  suggestion,
}) => (
  <Popover
    position="bottom-start"
    trigger={
      <Icon
        name="info"
        appearance="alert"
        className="ml-3 py-2 popover-trigger"
      />
    }
  >
    <div
      className="mx-5 my-5"
      style={{
        width: '349px',
      }}
    >
      <div className="mb-6">
        <Text weight="strong">Service code - {code}</Text>
      </div>
      <div>
        <Text appearance="subtle" size="small">
          {description}
        </Text>
      </div>
      <div className="mb-3">
        <Text
          size="small"
          appearance="destructive"
          weight="medium"
          className="preserve-whitespace"
        >
          <b>EDIT 1: </b>
          {edits}
        </Text>
      </div>
      <div className="d-flex mb-6">
        <Button
          appearance="primary"
          size="tiny"
          className="mt-4"
          onClick={handleOpen}
        >
          Edit
        </Button>
        <Button appearance="basic" size="tiny" className="mt-4 ml-5">
          Ignore Error
        </Button>
      </div>
      <Divider className="mb-6" />
      <div>
        <Text appearance="subtle" size="small" weight="strong">
          SUGGESTION:{` `}
        </Text>
        <Text
          appearance="subtle"
          size="small"
          weight="medium"
          className="preserve-whitespace"
        >
          {suggestion}
        </Text>
        {/* <Text appearance="subtle" size="small" weight="medium">
          Modifier LT or RT would be appropriate if the procedure with column 2
          code is performed on different extremity
        </Text> */}
      </div>
    </div>
  </Popover>
);
