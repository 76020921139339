import { HashRouter } from 'react-router-dom';
import { Routes } from 'src/router/routes';
import { Provider } from 'react-redux';
import { configureAppStore } from './store/store';
import { Authentication } from './components/Authentication';
import { SplashScreen } from './components/SplashScreen';
import './overrideStyles.scss';

function App() {
  return (
    <HashRouter>
      <Provider store={configureAppStore()}>
        <Authentication>
          <SplashScreen>
            <Routes />
          </SplashScreen>
        </Authentication>
      </Provider>
    </HashRouter>
  );
}

export default App;
