import { Text } from '@innovaccer/design-system';
import { FiMinus, FiPlus, FiUpload } from 'react-icons/fi';
import { ChartContainer } from '../../chart-container';
import { data } from './data';
import styles from '../tableStyles.module.scss';

export const EditsTable = () => (
  <ChartContainer
    title="Edits"
    headerActions={
      <div className="d-flex align-items-center">
        <FiUpload size="16" className="mr-4" />
        <Text>Export</Text>
      </div>
    }
    hideLegend
  >
    <div className={styles.scrollableArea}>
      <table className={styles.customTable}>
        <tbody>
          <tr>
            <td>Date</td>
            <td>Claim Id</td>
            <td>Username</td>
            <td>Actions</td>
            <td>Comments</td>
          </tr>
          {data.map((item) => (
            <tr>
              <td>
                <Text appearance="subtle">{item.date}</Text>
              </td>
              <td>
                <Text weight="strong">{item.claimId}</Text>
              </td>
              <td>
                <Text weight="strong">{item.username}</Text>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  {item.actions.action === 'add' ? (
                    <FiPlus className="mr-3" color="#27AE60" />
                  ) : (
                    <FiMinus className="mr-3" color="#F39189" />
                  )}
                  <Text appearance="subtle">{item.actions.meta}</Text>
                </div>
              </td>
              <td>
                <Text appearance="subtle">{item.comments}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </ChartContainer>
);
