import { ChartTabs } from '../chart-tabs';
import { DenialManagement } from '../denial-management';
import { DenialPrediction } from '../denial-prediction';
import { MissedCharges } from '../missed-charges';
import { UnderpaymentAndOverpayment } from '../underpayment-and-overpayment';
import styles from './revenue-intelligence.module.scss';

const PREBILL_TABS = [
  {
    name: 'denialsPrediction',
    label: 'Denial Prediction',
    Component: DenialPrediction,
  },
  {
    name: 'missedCharges',
    label: 'Missed Charges',
    Component: MissedCharges,
  },
];

const POSTBILL_TABS = [
  {
    name: 'denialManagement',
    label: 'Denial Management',
    Component: DenialManagement,
  },
  {
    name: 'underpaymentAndOverpayment',
    label: 'Underpayment & Overpayment',
    Component: UnderpaymentAndOverpayment,
  },
];

export const RevenueIntelligenceDashboard = () => (
  <div className={styles.container}>
    <ChartTabs title="Prebill" tabs={PREBILL_TABS} />
    <ChartTabs title="Postbill" tabs={POSTBILL_TABS} />
  </div>
);
