export const data = [
  {
    id: 'Clean Claim',
    label: 'Clean Claim',
    value: 82404,
    color: '#26ED22',
  },
  {
    id: 'Pre-Edits',
    label: 'Pre-bill edits',
    value: 18113,
    color: '#4F9DE7',
  },
].map((value, idx, array) => {
  const total = array.reduce((prev, curr) => prev + curr.value, 0);
  return {
    ...value,
    percent: value.value / total,
  };
});
