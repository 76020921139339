import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ChartContainer } from '../../chart-container';
import { ChartFilters } from '../../chart-filters';
import { VerticalAxisTick } from '../verticle-tick';
import { CountData, AmountData } from './data';

const color = '#FF79D1';

export const TopEditsByCodeChart = () => {
  const [typeOfEdits, setTypeOfEdits] = React.useState('Count');
  return (
    <ChartContainer
      title="Top 10 Edits by Codes"
      headerActions={<ChartFilters />}
      showTypeSelection
      typeOfEdits={typeOfEdits}
      setTypeOfEdits={setTypeOfEdits}
    >
      <ResponsiveBar
        data={typeOfEdits === 'Count' ? CountData : AmountData}
        indexBy="label"
        keys={['value']}
        layout="horizontal"
        innerPadding={6}
        enableGridY={false}
        margin={{ top: 20, right: 80, bottom: 50, left: 10 }}
        padding={0.5}
        colors={color}
        axisBottom={{
          tickSize: 0,
        }}
        axisLeft={null}
        axisRight={{
          tickSize: 0,
          renderTick: VerticalAxisTick,
        }}
        enableLabel={false}
        reverse
      />
    </ChartContainer>
  );
};
