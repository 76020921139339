import { DASHBOARD_ROUTES } from './dashboard';
import { DENIALS_MANAGEMENT_IN } from './denialManagementIN';
import { DENIALS_MANAGEMENT } from './denialsManagement';
import { DENIALS_PREDICTION } from './denialsPrediction';
import { MISSED_CHARGES } from './missedCharges';
import { MISSED_CHARGES_IN } from './missedChargesIN';
import { PAYMENT_VARIANCE } from './paymentVariance';
import { PAYMENT_VARIANCE_IN } from './paymentVariancetIN';
import { PREBILL_EDITS } from './preBillEdits';

export const ROUTES_CONFIG = [
  ...DASHBOARD_ROUTES,
  ...DENIALS_MANAGEMENT,
  ...DENIALS_PREDICTION,
  ...MISSED_CHARGES,
  ...PAYMENT_VARIANCE,
  ...PREBILL_EDITS,
  ...MISSED_CHARGES_IN,
  ...DENIALS_MANAGEMENT_IN,
  ...PAYMENT_VARIANCE_IN,
];
