import React from 'react';
import classNames from 'classnames';
import { Icon, Text } from '@innovaccer/design-system';
import styles from '../styles.module.scss';

export const TableSchema = [
  {
    id: 'expander',

    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <Icon
            name="expand_less"
            size={20}
            className={classNames(
              'pl-4 d-flex align-items-center',
              styles.expandIcon
            )}
          />
        ) : (
          <Icon
            name="expand_more"
            size={20}
            className={classNames(
              'pl-4 d-flex align-items-center',
              styles.expandIcon
            )}
          />
        )}
      </span>
    ),
  },
  {
    Header: () => null,
    id: 'columnData',
    columns: [
      {
        accessor: 'claimId',
        Header: 'Claim No.#',
        Cell: ({ cell: { value } }) => (
          <div className={styles.claimNo}>
            <Text className="pl-5 ">{value}</Text>
          </div>
        ),
      },
      {
        Header: 'Claim line#',
        Cell: () => <div className={styles.schemaCell} />,
      },

      {
        Header: 'Service start date',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'Service end date',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'CPT/HCPCS Codes',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'Modifier',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'Diagnosis Pointer',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'Units',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'POS',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'Billing Speciality',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'Billing Provider name',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'Charges',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'Emergency Indicator',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'EPSDT Family Plan',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: 'NDC',
        Cell: () => <div className={styles.schemaCell} />,
      },
      {
        Header: () => null,
        id: 'validate',
        Cell: () => <div className={styles.schemaValidate} />,
      },
      {
        Header: () => null,
        id: 'action',
        Cell: () => <div className={styles.schemaActionCell} />,
      },
    ],
  },
];
