export const data = [
  {
    label: 'Fee schedule not updated in provider system',
    value: 50,
  },
  {
    label: 'Outdated CDM',
    value: 40,
  },
  {
    label: 'Out of network providers',
    value: 36,
  },
  {
    label: 'Duplicate services',
    value: 32,
  },
  {
    label: 'Cross check with payer fee schedules',
    value: 30,
  },
].reverse();
