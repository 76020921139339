import React from 'react';
import { Text, Icon, Input, Modal, Button } from '@innovaccer/design-system';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CommentModal } from './Comment';

const elements = [
  { id: 'A' },
  { id: 'B' },
  { id: 'C' },
  { id: 'D' },
  { id: 'E' },
  { id: 'F' },
  { id: 'G' },
  { id: 'H' },
  { id: 'I' },
  { id: 'J' },
  { id: 'K' },
  { id: 'L' },
];

export const Reorder = ({ openModal, setOpenModal }) => {
  const [items, setItems] = React.useState(elements);
  const [showCommentModal, setShowCommentModal] = React.useState(true);

  const onDragEnd = (result) => {
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
    setShowCommentModal(false);
  };

  const ListItem = ({ item, provided, snapshot }) => (
    <div
      ref={provided.innerRef}
      snapshot={snapshot}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="d-flex w-50 align-items-center m-4 my-6 py-4">
        <Text className="pr-4" weight="strong" size="18">
          {item.id}
        </Text>
        <Icon
          name="drag_indicator"
          appearance="subtle"
          className="mx-2"
          size="18"
        />
        <Input className="w-auto pl-4 grid-data-input" minWidth={150} />
      </div>
    </div>
  );

  return (
    <Modal
      open={openModal}
      dimension="small"
      backdropClose={false}
      onClose={() => setOpenModal(!openModal)}
      footer={
        <div className="d-flex justify-content-between w-75 mx-10">
          <Button
            appearance="basic"
            onClick={() => setOpenModal(!openModal)}
            className="ml-8"
          >
            Discard
          </Button>
          <Button
            appearance="primary"
            className="ml-4 mr-8"
            onClick={() => {
              setShowCommentModal(false);
              setOpenModal(!openModal);
            }}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className="d-flex flex-column justify-content-center align-items-center mt-4">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="vh-75"
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <ListItem
                        provided={provided}
                        snapshot={snapshot}
                        item={item}
                      />
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <CommentModal
        commentModal={showCommentModal}
        setCommentModal={setShowCommentModal}
        onClick={() => {
          setShowCommentModal(!showCommentModal);
        }}
      />
    </Modal>
  );
};
