import {
  Button,
  Row,
  Input,
  Modal,
  Label,
  Textarea,
} from '@innovaccer/design-system';
import classNames from 'classnames';
import styles from '../styles.module.scss';

export const CommentModal = ({
  commentModal,
  setCommentModal,
  modalClassName,
  onClick,
}) => (
  <Modal
    open={!commentModal}
    dimension="medium"
    backdropClose
    onClose={() => setCommentModal(!commentModal)}
    className={modalClassName}
  >
    <div
      className={classNames(
        'py-4 px-4 ml-8 mb-8 w-75 mt-4',
        styles.tableCommentModel
      )}
    >
      <Label htmlFor="comments" withInput>
        Comments
      </Label>
      <Textarea
        name="comments"
        placeholder="Write your comments here"
        className="w-100 mr-6"
      />

      <Row className="mt-6 w-100 mr-6">
        <Input placeholder="File.pdf" minWidth="0" type="text" />
        <Button appearance="primary" onClick={onClick} className="ml-4">
          Save
        </Button>
      </Row>
    </div>
  </Modal>
);
