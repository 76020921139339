import React from 'react';
import { FALLBACK_URL } from './constants';
import { Routes as RoutesComponent, Route, Navigate } from './router';
import { ROUTES_CONFIG } from './routes-config';

export const Routes = () => (
  <RoutesComponent>
    {ROUTES_CONFIG.map(({ path, element: Element }) => (
      <Route key={path} path={path} element={<Element />} />
    ))}
    <Route path="*" element={<Navigate to={FALLBACK_URL} replace />} />
  </RoutesComponent>
);
