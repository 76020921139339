import { Caption, Input as InputComponent } from '@innovaccer/design-system';
import { ErrorMessage, Field } from 'formik';
import React from 'react';

export const Input = (props) => (
  <>
    <Field {...props} as={InputComponent} />
    <ErrorMessage name={props.name} component={Caption} withInput error />
  </>
);
