import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useEditClaimMutation,
  useGetClaimQuery,
} from 'src/features/claims/store/api';
import { useParams } from 'src/router/hooks';

export const useClaim = () => {
  const { id } = useParams();
  const [editClaimMutation] = useEditClaimMutation();

  const { data, isLoading } = useGetClaimQuery(id);
  const [billItems, setBillItems] = useState(null);

  useEffect(() => setBillItems(data?.claim?.items), [data]);

  const billItemsLength = data?.claim?.edits.filter(
    (value) => value.level === 'LINE'
  );

  const updateItem = useCallback(
    async (editedItem) => {
      const newItems = billItems.map((item) => {
        if (item.claimLineNumber === editedItem.claimLineNumber) {
          const updatedItem = { ...editedItem };
          delete updatedItem.edits;
          delete updatedItem.suggestion;

          return updatedItem;
        }
        return item;
      });

      setBillItems(newItems);

      // Call the API with Data
      await editClaimMutation({ claimId: id, items: newItems });

      const editsLeft = newItems.reduce((prev, current) => {
        if (current.edits) return prev + 1;
        return prev;
      }, 0);

      if (editsLeft > 0) {
        toast.warn(`${editsLeft} edits left.`);
      } else {
        toast.success('No edits found.');
      }
    },
    [billItems]
  );
  return {
    loading: isLoading,
    claimId: id,
    claim: {
      ...data?.claim,
      documents: [
        {
          documentId: '0',
          name: 'Report',
          url: 'reports/william-smith.pdf',
        },
        {
          documentId: '1',
          name: 'Pre-Visit Summary',
          url: 'dummy.pdf',
        },
        {
          documentId: '2',
          name: 'Post-Visit Summary',
          url: 'dummy.pdf',
        },
      ],
    },
    updateItem,
    editsCount: {
      billItems: billItemsLength?.length,
      patient: 0,
      provider: 0,
      insurance: 0,
      otherDetails: 0,
    },
  };
};
