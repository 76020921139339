import { Button, Card, Icon, Text, Dropdown } from '@innovaccer/design-system';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  PROFESSIONAL_ROUTE,
  INPATIENT_ROUTE,
  ROUTES,
} from 'src/router/route-names';
import { NavLink, Link } from 'src/router/link';
import styles from './styles.module.scss';
import { topLinks } from './constants';
import Logo from './innovaccer-logo.svg';

const NotificationIcon = ({ iconName, count }) => (
  <div
    className={classNames(
      'd-flex cursor-pointer mx-2 align-items-center',
      styles.notificationIcon
    )}
  >
    <Icon name={iconName} className="cursor-pointer" size={20} />
    {count && <div className={styles.notificationBadge}>{count}</div>}
  </div>
);

export const TopNavigation = () => {
  const { logout } = useAuth0();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const moduleChange = pathname.includes(PROFESSIONAL_ROUTE)
    ? PROFESSIONAL_ROUTE
    : INPATIENT_ROUTE;

  const handleLogout = () => {
    logout();
  };

  const onDropdownChangeHandler = (value) => {
    switch (value) {
      case PROFESSIONAL_ROUTE:
        navigate(ROUTES.denialsPrediction);
        break;
      case INPATIENT_ROUTE:
        navigate(ROUTES.prebillEdits);
        break;
      default:
        break;
    }
  };

  const Modules = [
    {
      label: 'Professional',
      value: 'professional',
      selected: moduleChange === PROFESSIONAL_ROUTE,
    },
    {
      label: 'In Patient',
      value: 'in-patient',
      selected: moduleChange === INPATIENT_ROUTE,
    },
  ];

  return (
    <Card shadow="none" className={styles.navbar}>
      <div className={classNames(styles.navbarContainer, 'p-5')}>
        <div className={styles.linksContainer}>
          <Button
            aria-label="Navigation"
            icon="apps"
            largeIcon
            size="regular"
            appearance="transparent"
            onClick={() => localStorage.clear()}
          />
          {Logo && (
            <Link to="/">
              <Icon size={45} className="ml-5">
                <img alt="Logo" src={Logo} width="150" />
              </Icon>
            </Link>
          )}
          <nav className={styles.links}>
            {topLinks.map((link) => (
              <NavLink key={link.id} to={link.href} className={styles.link}>
                {({ isActive }) => (
                  <Text className={isActive ? styles.activeLink : undefined}>
                    {link.label}
                  </Text>
                )}
              </NavLink>
            ))}
          </nav>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex mr-4">
            <NotificationIcon iconName="textsms" count="9+" />
            <NotificationIcon iconName="notifications" count="9+" />
            <NotificationIcon iconName="settings" />
          </div>
          <Dropdown
            inlineLabel="Module"
            options={Modules}
            onChange={onDropdownChangeHandler}
            maxWidth={0}
            className={styles.dropdownContainer}
          />
          <nav className={styles.links}>
            <Button
              appearance="transparent"
              className={styles.logout}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </nav>
        </div>
      </div>
    </Card>
  );
};
