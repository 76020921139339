import { useMatch, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/router/route-names';
import {
  BillItems,
  PatientDetails,
  InsuranceDetails,
  OtherDetails,
  ProviderDetails,
} from '../blocks';
import {
  BILL_ITEMS_PATH,
  INSURANCE_DETAILS_PATH,
  OTHER_DETAILS_PATH,
  PATIENT_DETAILS_PATH,
  PROVIDER_DETAILS_PATH,
} from '../constants';

const localRoutes = [
  {
    path: INSURANCE_DETAILS_PATH,
    element: () => <InsuranceDetails />,
  },
  {
    path: PATIENT_DETAILS_PATH,
    element: () => <PatientDetails />,
  },
  {
    path: PROVIDER_DETAILS_PATH,
    element: () => <ProviderDetails />,
  },
  {
    path: OTHER_DETAILS_PATH,
    element: () => <OtherDetails />,
  },
  {
    path: BILL_ITEMS_PATH,
    element: () => <BillItems />,
  },
];

export const useLocalNavigation = () => {
  const navigate = useNavigate();

  const {
    params: { step, id },
  } = useMatch({ path: `${ROUTES.paymentVariance}/:id/:step` });

  const changeStep = (path) => {
    navigate(`${ROUTES.paymentVariance}/${id}/${path}`);
  };

  const goToNextStep = () => {
    const nextStepIndex =
      localRoutes.findIndex(({ path }) => path === step) + 1;

    if (nextStepIndex < localRoutes.length) {
      changeStep(localRoutes.at(nextStepIndex).path);
    }
  };

  const goToPrevStep = () => {
    const prevStepIndex =
      localRoutes.findIndex(({ path }) => path === step) - 1;

    if (prevStepIndex >= 0) {
      changeStep(localRoutes.at(prevStepIndex).path);
    }
  };

  return {
    currentSubRoute: step,
    localRoutes,
    changeStep,
    goToNextStep,
    goToPrevStep,
  };
};
