import React from 'react';
import { Heading, Row, Text, Input, Dropdown } from '@innovaccer/design-system';

export const NDC = () => (
  <div>
    <Heading className="mb-6">NDC</Heading>
    <Row className="mb-6">
      <Text weight="medium" className="mr-7">
        Product ID qualifier
      </Text>
      <Text appearance="subtle">N4</Text>
    </Row>
    <div className="d-flex flex-column">
      <Text weight="medium" className="mb-4">
        National Drug Code
      </Text>
      <Text appearance="subtle" size="small" className="mb-5">
        11 digit NDC billing format assigned to drug administered
      </Text>
      <Input minWidth={0} className="mb-8" />
    </div>
    <div className="d-flex flex-column">
      <Text weight="medium" className="mb-4">
        National Drug Unit Count
      </Text>
      <Text appearance="subtle" className="mb-5">
        Enter quantity(number of NDC units)
      </Text>
      <Input minWidth={0} className="mb-8" />
    </div>
    <Text weight="medium">Unit of Measurement</Text>
    <Dropdown
      minWidth={0}
      options={[]}
      placeholder="Unit of Measurement"
      className="mt-4 w-50"
    />
  </div>
);
