import {
  Card,
  Heading,
  Label,
  Column,
  Row,
  VerificationCodeInput,
} from '@innovaccer/design-system';
import React from 'react';
import {
  FormDatePicker,
  FormDropdown,
  FormInput,
  FormContext,
  HTMLForm,
} from 'src/components/form';
import { DATE_MASK } from 'src/services/constant';
import { useLocalNavigation } from '../hooks/useLocalNavigation';
import { NavigationButtons } from './NavigationButtons';
import './styles.scss';

export const ClaimDetails = () => {
  const { goToNextStep } = useLocalNavigation();
  return (
    <FormContext>
      <HTMLForm>
        <Card className="my-7 mx-10 px-6 py-5">
          <div className="mb-6 d-flex align-items-center">
            <Heading className="mr-5">Claim Details</Heading>
          </div>
          <Row className="mb-7">
            <div className="mr-6 mb-6 type-of-bill">
              <Label withInput> Type of Bill</Label>
              <FormInput
                name="billType"
                type="text"
                placeholder="Type of Bill"
                minWidth={0}
              />
            </div>
          </Row>
          <Row className="mb-7">
            <div>
              <div className="mb-6 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Federal Tax No.
                </Heading>
              </div>
              <Row>
                <div className="mr-6 mb-6 claim-details-federal-tax">
                  <Label withInput>Federal Tax No.</Label>
                  <FormInput
                    name="taxNo."
                    placeholder="Federal Tax No."
                    minWidth={0}
                  />
                </div>
              </Row>
            </div>
            <Column>
              <div className="mb-6 d-flex align-items-center">
                <Heading className="mr-5" size="s">
                  Statement covers period
                </Heading>
              </div>
              <Row>
                <div className="mr-6 mb-6 claim-details-date">
                  <Label withInput>From</Label>
                  <FormDatePicker
                    name="statementCoverStartDate"
                    withInput
                    inputOptions={{
                      placeholder: 'MM/DD/YYYY',
                      icon: 'event',
                      mask: DATE_MASK,
                      minWidth: '0px',
                      name: 'statementCoverStartDate',
                    }}
                  />
                </div>
                <div className="mr-6 mb-6 claim-details-date">
                  <Label withInput>Through</Label>
                  <FormDatePicker
                    name="statementCoverEndDate"
                    withInput
                    inputOptions={{
                      placeholder: 'MM/DD/YYYY',
                      icon: 'event',
                      mask: DATE_MASK,
                      minWidth: '0px',
                      name: 'statementCoverEndDate',
                    }}
                  />
                </div>
              </Row>
            </Column>
          </Row>
          <Row className="mb-7">
            <Column className="mr-6 mb-6">
              <Label withInput>Type of Admission</Label>
              <FormDropdown
                name="typeOfAdmission"
                placeholder="Type of Admission"
                options={[]}
                disabled
              />
            </Column>
            <Column className="mr-6 mb-6">
              <Label withInput>Source of Admission</Label>
              <FormDropdown
                name="sourceOfAdmission"
                placeholder="Source of Admission"
                options={[]}
                disabled
              />
            </Column>
            <Column className="mr-6 mb-6">
              <Label withInput>Patient Discharge Status</Label>
              <FormDropdown
                name="patientDisachargeStatus"
                placeholder="Patient Discharge Status"
                options={[]}
                disabled
              />
            </Column>
          </Row>
          <Column className="mb-7">
            <Label withInput>Condition Codes</Label>
            <div className="condition-code mt-4">
              <VerificationCodeInput fields={10} autoFocus={false} />
            </div>
          </Column>
          <Column className="mb-7">
            <div className="mb-6 d-flex align-items-center">
              <Heading className="mr-5" size="s">
                Occurance code & Date
              </Heading>
            </div>
            <Row className="mb-7">
              <div className="mr-6 mb-6">
                <Label withInput>Occurance code</Label>
                <FormInput
                  name="occurenceCode"
                  placeholder="Occurance code"
                  minWidth={0}
                />
              </div>
              <div className="mr-6 mb-6 claim-details-occurance-code">
                <Label withInput>Date </Label>
                <FormDatePicker
                  name="date"
                  withInput
                  inputOptions={{
                    placeholder: 'MM/DD/YYYY',
                    icon: 'event',
                    mask: DATE_MASK,
                    minWidth: '0px',
                    name: 'date',
                  }}
                />
              </div>
              <div className="mr-6 mb-7">
                <Label withInput>Occurance code</Label>
                <FormInput
                  name="occurenceCode"
                  placeholder="Occurance code"
                  minWidth={0}
                />
              </div>
              <div className="mr-6 mb-7 claim-details-date">
                <Label withInput>Date </Label>
                <FormDatePicker
                  name="date"
                  withInput
                  inputOptions={{
                    placeholder: 'MM/DD/YYYY',
                    icon: 'event',
                    mask: DATE_MASK,
                    minWidth: '0px',
                    name: 'date',
                  }}
                />
              </div>
            </Row>
            <Row className="mb-7">
              <div className="mr-6 mb-6">
                <Label withInput>Occurance code</Label>
                <FormInput
                  name="occurenceCode"
                  placeholder="Occurance code"
                  minWidth={0}
                />
              </div>
              <div className="mr-6 mb-7 claim-details-date">
                <Label withInput>Date </Label>
                <FormDatePicker
                  name="date"
                  withInput
                  inputOptions={{
                    placeholder: 'MM/DD/YYYY',
                    icon: 'event',
                    mask: DATE_MASK,
                    minWidth: '0px',
                    name: 'date',
                  }}
                />
              </div>
              <div className="mr-6 mb-6">
                <Label withInput>Occurance code</Label>
                <FormInput
                  name="occurenceCode"
                  placeholder="Occurance code"
                  minWidth={0}
                />
              </div>
              <div className="mr-6 mb-7 claim-details-date">
                <Label withInput>Date </Label>
                <FormDatePicker
                  name="date"
                  withInput
                  inputOptions={{
                    placeholder: 'MM/DD/YYYY',
                    icon: 'event',
                    mask: DATE_MASK,
                    minWidth: '0px',
                    name: 'date',
                  }}
                />
              </div>
            </Row>
          </Column>
          <div className="mb-6 d-flex align-items-center">
            <Heading className="mr-5" size="s">
              Value Codes & Amount
            </Heading>
          </div>
          <Column className="mb-7">
            <Row className="mb-7">
              <Column className="mr-6 mb-7">
                <Label withInput>Value Codes</Label>
                <FormInput
                  name="valueCode"
                  placeholder="Value Codes"
                  minWidth={0}
                />
              </Column>
              <Column className="mr-8 mb-7">
                <Label withInput>Amount</Label>
                <FormInput name="amount" placeholder="Amount" minWidth={0} />
              </Column>
              <Column className="mr-6 mb-7">
                <Label withInput>Value Codes</Label>
                <FormInput
                  name="valueCode"
                  placeholder="Value Codes"
                  minWidth={0}
                />
              </Column>
              <Column className="mr-8 mb-6">
                <Label withInput>Amount</Label>
                <FormInput name="amount" placeholder="Amount" minWidth={0} />
              </Column>
              <Column className="mr-6 mb-7">
                <Label withInput>Value Codes</Label>
                <FormInput
                  name="valueCode"
                  placeholder="Value Codes"
                  minWidth={0}
                />
              </Column>
              <Column className="mr-6 mb-7">
                <Label withInput>Amount</Label>
                <FormInput name="amount" placeholder="Amount" minWidth={0} />
              </Column>
            </Row>
            <Row className="mb-7">
              <div className="mr-6 mb-7 claim-details-treatment-authorization">
                <Label withInput>Treatment Authorization Code</Label>
                <FormInput
                  name="treatmentAuthoziationCode"
                  placeholder="Treatment Authorization Code"
                  minWidth={0}
                />
              </div>
              <div className="mr-6 mb-7 claim-details-treatment-authorization">
                <Label withInput>Document Control Number</Label>
                <FormInput
                  name="documentControlNumber"
                  placeholder="Document Control Number"
                  minWidth={0}
                />
              </div>
            </Row>
          </Column>
          <div className="mb-7 d-flex align-items-center">
            <Heading className="mr-5" size="s">
              Remarks
            </Heading>
          </div>
          <div className="mr-6 mb-7 claim-details-formInput">
            <Label withInput>*</Label>
            <FormInput name="*" placeholder="Something" minWidth={0} />
          </div>
        </Card>
        <NavigationButtons showPreviousBtn onNextClick={() => goToNextStep()} />
      </HTMLForm>
    </FormContext>
  );
};
