export const data = [
  {
    label: 'Duplicate record - E/M',
    value: 840,
  },
  {
    label: 'NCCI PTP Edit - Surgery',
    value: 546,
  },
  {
    label: 'Claim Lacks Info - Missing ICD',
    value: 445,
  },
  {
    label: 'Gender and Procedure mismatch',
    value: 255,
  },
  {
    label: 'Modifier Inconsistant with Procedure - DME',
    value: 184,
  },
  {
    label: 'Invalid HCPCS code - Consult codes',
    value: 81,
  },
  {
    label: 'Invalid ICD code - Truncated codes',
    value: 70,
  },
  {
    label: 'Invalid Modifier code',
    value: 16,
  },
].reverse();
