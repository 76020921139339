import { ResponsiveBar } from '@nivo/bar';
import { ChartContainer } from '../../chart-container';
import { ChartFilters } from '../../chart-filters';
import { VerticalAxisTick } from '../verticle-tick';
import { data } from './data';

const color = '#FFD462';

export const UnderpaymentReasonsChart = () => (
  <ChartContainer
    title="Top Underpayment Reasons"
    headerActions={<ChartFilters />}
    showTypeSelection
  >
    <ResponsiveBar
      data={data}
      indexBy="label"
      layout="horizontal"
      innerPadding={6}
      enableGridY={false}
      margin={{ top: 20, right: 500, bottom: 120, left: 100 }}
      padding={0.5}
      colors={color}
      axisBottom={{
        tickSize: 0,
      }}
      axisLeft={null}
      axisRight={{
        tickSize: 0,
        renderTick: VerticalAxisTick,
      }}
      enableLabel={false}
      reverse
    />
  </ChartContainer>
);
