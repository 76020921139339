import { useAuth0 } from '@auth0/auth0-react';
import { FiKey } from 'react-icons/fi';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CoverselfLogo } from '../icons';

export const SplashScreen = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  return isAuthenticated ? (
    children
  ) : (
    <div className={styles.splashScreen}>
      <div
        className={classNames(styles.logoIconContainer, {
          [styles.pulse]: !isAuthenticated && isLoading,
        })}
      >
        <CoverselfLogo className={styles.logoIcon} />
      </div>
      {!isAuthenticated && !isLoading && (
        <button
          title="Login to Creol"
          onClick={loginWithRedirect}
          type="button"
          className={classNames(styles.loginButton)}
        >
          <FiKey size="1.5em" />
        </button>
      )}
    </div>
  );
};
