/* eslint no-underscore-dangle: 0 */
import React from 'react';
import classNames from 'classnames';
import { Label, Input, Text } from '@innovaccer/design-system';
import { useDebouncedCallback } from 'use-debounce';
import { useUpdateClaimItemsMutation } from 'src/features/claims/store/api';
import { TableFilter } from './TableFilter';
import styles from '../styles.module.scss';
import { ClaimTable } from './ClaimTable';
import { ClaimItemsTable } from './NestedClaimLineTable';
import { TableSchema } from './schema';
import { ClaimItemsSchema } from './ClaimItemsSchema';

const affectedEdits = {};

export const Table = ({
  data,
  correction,
  disabled,
  setDisable,
  nestedData,
  setNestedData,
  handleIsDirtyState,
  setIsModifierOpen,
  setIsNDCOpen,
  handleOnSubmit,
  disableCharges,
  setDisableCharges,
  setCommentModal,
}) => {
  const [updateClaimItems] = useUpdateClaimItemsMutation();

  const claimItemsCharge = nestedData.map((value) => Number(value?.charge));

  const TotalCharge = claimItemsCharge.reduce((a, b) => a + b);

  const handleDeleteClaimItem = async (e) => {
    const filteredClaimItems = nestedData.filter(
      (value) => value.claimLineId !== e?.claimLineId
    );
    setNestedData(filteredClaimItems);
    await updateClaimItems({
      id: data?._id,
      items: filteredClaimItems,
    });
    setCommentModal(false);
    setDisable(true);
    setDisableCharges(true);
  };

  const handleOnChange = (e, claimLine) => {
    handleIsDirtyState(claimLine);
    const activeClaimLine = nestedData.map((item) => {
      const claimLineItem = { ...item };
      if (claimLineItem.claimLineId === claimLine.claimLineId) {
        claimLineItem[e.target.name] = e.target.value;
      }
      return claimLineItem;
    });
    setNestedData(activeClaimLine);
  };

  const debounce = useDebouncedCallback(
    (e, claimLine) => handleOnChange(e, claimLine),
    1500
  );
  const affectedFields = () => {
    data?.edits.forEach((value) => {
      affectedEdits[value.claimLineId] = affectedEdits[value.claimLineId]
        ? affectedEdits[value.claimLineId].concat(...value.affectedColumns)
        : [...value.affectedColumns];
    });
  };

  React.useEffect(() => {
    affectedFields();
  }, []);

  const columns = React.useMemo(() => TableSchema, []);

  const claimLineItems = React.useMemo(
    () =>
      ClaimItemsSchema({
        disabled,
        setIsModifierOpen,
        setIsNDCOpen,
        handleDelete: handleDeleteClaimItem,
        handleOnSubmit,
        setDisable,
        handleOnChange: debounce,
        affectedEdits,
        disableCharges,
      }),
    [nestedData, disabled, disableCharges]
  );

  const renderRowSubComponent = React.useCallback(
    () => <ClaimItemsTable columns={claimLineItems} data={nestedData} />,
    [nestedData, disabled, disableCharges]
  );
  return (
    <div className={classNames('my-4', styles.tableDiv)}>
      {!correction && <TableFilter />}
      <div className="py-4 px-6">
        <Text
          size="large"
          weight="medium"
        >{`Showing ${nestedData.length} bill items`}</Text>
      </div>
      <div className={styles.missedChargesTable}>
        <ClaimTable
          columns={columns}
          data={[data]}
          renderRowSubComponent={renderRowSubComponent}
        />
        <div className="d-flex flex-row align-items-center justify-content-end mb-4 w-100">
          <div
            className={styles.tableTotalCharge}
            style={{
              left: !correction ? 559 : 1265,
            }}
          >
            <Label withInput onClick={() => setIsModifierOpen(true)}>
              Total charges:
            </Label>
            <Input
              readOnly
              value={TotalCharge}
              name="totalCharges"
              minWidth={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
