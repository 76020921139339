import { ROUTES, PRE_SUBMISSION_ROUTE } from 'src/router/route-names';

const preSubmissionSubMenu = [
  {
    name: 'all.denialsPrediction',
    label: 'Denials Prediction',
    link: ROUTES.denialsPrediction,
    count: 12,
  },
  {
    name: 'all.missedCharges',
    label: 'Missed Charges',
    link: ROUTES.missedCharges,
    count: 87,
  },
];

const inPatientPreSubmissionMenu = [
  {
    name: 'all.prebillEdis',
    label: 'Prebill Edits',
    link: ROUTES.prebillEdits,
    count: 12,
  },
  {
    name: 'all.missedCharges',
    label: 'Missed Charges',
    link: ROUTES.missedChargesIN,
    count: 87,
  },
];

const postSubmissionSubMenu = [
  {
    name: 'all.denialsManagement',
    label: 'Denials Management',
    link: ROUTES.denialsManagement,
    count: 12,
  },
  {
    name: 'all.paymentVariance',
    label: 'Payment Variance',
    link: ROUTES.paymentVariance,
    count: 87,
  },
];
const inPatientPostSubmissionSubMenu = [
  {
    name: 'all.denialsManagement',
    label: 'Denials Management',
    link: ROUTES.denialsManagementIN,
    count: 12,
  },
  {
    name: 'all.paymentVariance',
    label: 'Payment Variance',
    link: ROUTES.paymentVarianceIN,
    count: 87,
  },
];

export const getSideLinks = (submissionSolution, inPatientModule) => {
  const presubmissionSubMenu = inPatientModule
    ? inPatientPreSubmissionMenu
    : preSubmissionSubMenu;

  const postsubmissionSubMenu = !inPatientModule
    ? postSubmissionSubMenu
    : inPatientPostSubmissionSubMenu;
  return [
    {
      name: 'all',
      label: 'All',
      icon: 'event',
      count: 99,
      subMenu:
        submissionSolution === PRE_SUBMISSION_ROUTE
          ? presubmissionSubMenu
          : postsubmissionSubMenu,
    },
    {
      name: 'todos',
      label: "To-do's",
      icon: 'event',
      count: 99,
      group: ' ',
      subMenu: [
        {
          name: 'todos.due',
          label: 'Due',
          link: ROUTES.due,
          count: 12,
        },
        {
          name: 'todos.completed',
          label: 'Completed',
          link: ROUTES.completed,
          count: 87,
        },
      ],
    },
    {
      name: 'received',
      label: 'Received',
      icon: 'south_west',
      link: ROUTES.received,
      count: 55,
    },
    {
      name: 'sent',
      label: 'Sent',
      icon: 'north_east',
      link: ROUTES.sent,
      count: 55,
    },
    {
      name: 'inpatient-claims',
      label: 'Inpatient Claims',
      group: 'User Groups',
      link: ROUTES.inpatientClaims,
      count: 3,
    },
    {
      name: 'interventional-radiology',
      label: 'Interventional Radiology',
      group: 'User Groups',
      link: ROUTES.interventionalRadiology,
      count: 10,
    },
    {
      name: 'cardiology',
      label: 'Cardiology',
      group: 'User Groups',
      link: ROUTES.cardiology,
      count: 8,
    },
  ];
};
