/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const activeNavbarStateSlice = createSlice({
  name: 'activeState',
  initialState: {
    active: null,
  },
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { setActive } = activeNavbarStateSlice.actions;

export default activeNavbarStateSlice.reducer;
