import React from 'react';
import {
  Button,
  Row,
  Input,
  Modal,
  Label,
  Textarea,
} from '@innovaccer/design-system';
import classNames from 'classnames';
import styles from '../styles.module.scss';

export const CommentModal = ({
  commentModal,
  setCommentModal,
  modalClassName,
}) => {
  const [textAreaText, setTextAreaText] = React.useState('');
  return (
    <Modal
      open={!commentModal}
      dimension="medium"
      backdropClose
      onClose={() => {
        setTextAreaText('');
        setCommentModal(!commentModal);
      }}
      className={modalClassName}
    >
      <div
        className={classNames(
          'py-4 px-4 ml-8 mb-8 w-75 mt-4',
          styles.tableCommentModel
        )}
      >
        <Label htmlFor="comments" withInput>
          Comments
        </Label>
        <Textarea
          name="comments"
          placeholder="Write your comments here"
          className="w-100 mr-6"
          value={textAreaText}
          onChange={(e) => setTextAreaText(e.target.value)}
        />
        <Row className="mt-6 w-100 mr-6">
          <Input placeholder="File.pdf" minWidth="0" type="text" />
          <Button
            appearance="primary"
            onClick={() => {
              setCommentModal(!commentModal);
              setTextAreaText('');
            }}
            className="ml-4"
            disabled={!textAreaText}
          >
            Save
          </Button>
        </Row>
      </div>
    </Modal>
  );
};
